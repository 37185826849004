/* eslint import/prefer-default-export: 0 */

import { css } from 'styled-components';

export const breakpoints = {
  charts: {
    default: 600,
    completedOrders: 800,
  },
  mobileMax: 767,
  mobileXsMax: 320,
  desktopMin: 768,
};

export const mobile = (...args) => css`
  @media (max-width: ${breakpoints.mobileMax}px) {
    ${css(...args)}
  }
`;

export const mobileXs = (...args) => css`
  @media (max-width: ${breakpoints.mobileXsMax}px) {
    ${css(...args)}
  }
`;

export const desktop = (...args) => css`
  @media (min-width: ${breakpoints.desktopMin}px) {
    ${css(...args)}
  }
`;
