import React from 'react';
import PropTypes from 'prop-types';

export const ForkAndKnifeComponent = ({ size, fill, ...props }) => (
  <svg
    width={size}
    height={size}
    fill={fill}
    viewBox="0 0 540.201 540.201"
    {...props}
  >
    <path d="M169.467 194.102v310.136c0 8.086 2.95 15.08 8.862 20.986 5.808 5.809 12.681 8.732 20.6 8.824 7.914-.092 14.792-3.016 20.606-8.824 5.906-5.906 8.855-12.9 8.855-20.986V194.102c8.862-3.103 42.081-32.711 42.081-42.204V27.161c0-4.033-1.481-7.534-4.431-10.49-2.956-2.95-6.457-4.431-10.49-4.431-4.045 0-7.54 1.481-10.49 4.431-2.956 2.962-4.431 6.457-4.431 10.49v96.99c0 4.045-1.481 7.54-4.431 10.49-2.956 2.956-6.451 4.425-10.49 4.425-4.045 0-7.54-1.469-10.49-4.425-2.95-2.95-4.431-6.451-4.431-10.49v-96.99c0-4.033-1.481-7.534-4.431-10.49-2.95-2.95-6.45-4.431-10.489-4.431-4.045 0-7.54 1.481-10.49 4.431-2.956 2.962-4.431 6.457-4.431 10.49v96.99c0 4.045-1.481 7.54-4.431 10.49-2.956 2.956-6.451 4.425-10.49 4.425-4.045 0-7.54-1.469-10.49-4.425-2.956-2.95-4.431-6.451-4.431-10.49v-96.99c0-4.033-1.475-7.534-4.431-10.49-2.95-2.95-6.45-4.431-10.49-4.431-4.045 0-7.54 1.481-10.489 4.431-2.95 2.962-4.431 6.457-4.431 10.49v124.732c.003 9.491 39.342 39.1 48.204 42.209z" />
    <path d="M199.308 534.08c-.129 0-.251-.037-.38-.037-.128 0-.245.037-.379.037h.759zM389.848 540.201c-.129 0-.25-.037-.379-.037s-.252.037-.379.037h.758z" />
    <path d="M326.139 268.588h33.869v241.771c0 8.084 2.949 15.08 8.855 20.984 5.812 5.809 12.686 8.734 20.605 8.826 7.912-.092 14.791-3.018 20.605-8.826 5.906-5.904 8.861-12.9 8.861-20.984V14.921c0-4.033-1.48-7.534-4.43-10.49C411.549 1.481 408.055 0 404.016 0h-10.723c-20.52 0-38.084 7.308-52.693 21.916-14.607 14.614-21.914 32.179-21.914 52.693v186.52c0 2.02.74 3.77 2.215 5.245 1.468 1.474 3.218 2.214 5.238 2.214z" />
  </svg>
);

ForkAndKnifeComponent.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

ForkAndKnifeComponent.defaultProps = {
  size: 40,
  fill: null,
};

export default ForkAndKnifeComponent;
