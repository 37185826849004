import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  transform: ${({ direction }) => {
    switch (direction) {
      case 'up':
        return 'rotate(-90deg)';
      case 'down':
        return 'rotate(90deg)';
      case 'left':
        return 'rotate(180deg)';
      case 'right':
      default:
        return '';
    }
  }};
`;

const Arrow = ({ direction, color, width, height }) => (
  <StyledSvg
    direction={direction}
    width={width}
    height={height}
    viewBox="0 0  19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.576063 8.27539H14.2622L7.98128 1.99039L9.57026
      0.400391L18.5645 9.40039L9.57026 18.4004L7.98128
      16.8104L14.2622 10.5254H0.576063V8.27539Z"
      fill={color}
    />
  </StyledSvg>
);

Arrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  direction: PropTypes.oneOf(['right', 'left', 'up', 'down']),
};

Arrow.defaultProps = {
  direction: 'right',
  color: 'white',
  width: 19,
  height: 19,
};

export default Arrow;
