import PropTypes from 'prop-types';
import { LAUNCHPAD_HOST_NAME } from 'common/constants';

export const isLocal = () =>
  process.env.REACT_APP_STAGE_ENV === 'development' ||
  process.env.REACT_APP_STAGE_ENV === 'test';
export const isDev = () =>
  process.env.REACT_APP_STAGE_ENV === 'development' ||
  process.env.REACT_APP_STAGE_ENV === 'sandbox';
export const isQa = () => process.env.REACT_APP_STAGE_ENV === 'qa';
export const isProd = () =>
  window.location.host !== LAUNCHPAD_HOST_NAME &&
  process.env.REACT_APP_STAGE_ENV === 'production';
export const isLaunchpad = () => window.location.host === LAUNCHPAD_HOST_NAME;

export const getEnvURLPrefix = () => {
  switch (process.env.REACT_APP_STAGE_ENV) {
    case 'development':
    case 'sandbox':
      return 'sandbox-';
    case 'qa':
      return 'qa-';
    default:
      return '';
  }
};

const Env = ({ env, children }) => {
  if (
    (env.includes('dev') && isDev()) ||
    (env.includes('qa') && isQa()) ||
    (env.includes('launchpad') && isLaunchpad()) ||
    (env.includes('prod') && isProd())
  ) {
    return children;
  }
  return null;
};

Env.propTypes = {
  env: PropTypes.arrayOf(PropTypes.oneOf(['dev', 'qa', 'launchpad', 'prod'])),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
};

Env.defaultProps = {
  env: [],
  children: null,
};

export default Env;
