import React from 'react';
import PropTypes from 'prop-types';

const GB = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="21" height="15" rx="2" fill="white" />
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="16"
    >
      <rect width="21" height="15" rx="2" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <rect width="21" height="15" fill="#0A17A7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.961852 -1.43734L8 4.60751V-1H13V4.60751L21.9619 -1.43734L23.0802 0.22074L15.9947
        5H21V10H15.9947L23.0802 14.7793L21.9619 16.4373L13 10.3925V16H8V10.3925L-0.961852
        16.4373L-2.08024 14.7793L5.0053 10H-7.15256e-07V5H5.00531L-2.08024 0.22074L-0.961852 -1.43734Z"
        fill="white"
      />
      <path
        d="M14.001 4.74915L23.5 -1.5"
        stroke="#DB1F35"
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
      <path
        d="M15.0096 10.2731L23.525 16.0128"
        stroke="#DB1F35"
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
      <path
        d="M6.00415 4.73285L-2.87811 -1.25323"
        stroke="#DB1F35"
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
      <path
        d="M6.96753 10.2037L-2.87811 16.7329"
        stroke="#DB1F35"
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9H9V15H12V9H21V6H12V0H9V6H0V9Z"
        fill="#E6273E"
      />
    </g>
  </svg>
);

GB.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

GB.defaultProps = {
  width: 21,
  height: 15,
};

export default GB;
