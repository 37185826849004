import React from 'react';
import PropTypes from 'prop-types';

const DE = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="15"
    >
      <rect width="21" height="15" rx="2" fill="#F01515" />
    </mask>
    <g mask="url(#mask0)">
      <rect y="10" width="21" height="5" fill="#FFD521" />
      <rect y="5" width="21" height="5" fill="#F01515" />
      <rect width="21" height="5" fill="#262626" />
    </g>
  </svg>
);

DE.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

DE.defaultProps = {
  width: 21,
  height: 15,
};

export default DE;
