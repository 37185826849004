export const EMPTY_PATH = '/';
export const FEEDBACKS_PATH = '/feedback';
export const SUPPORT_TICKETS_PATH = '/support-tickets';
export const SALES_PATH = '/sales';
export const SETTING_PATH = '/settings';
export const DELIVERY_PATH = `${SETTING_PATH}/delivery`;
export const ORDER_METHODS_PATH = `${SETTING_PATH}/order-methods`;
export const SCHEDULED_ORDERS_PATH = `${SETTING_PATH}/scheduled-orders`;
export const GROUP_ORDERS_PATH = `${SETTING_PATH}/group-orders`;
export const OFFERS_PATH = '/offers';
export const SHARE_PROGRAM_PATH = '/share-program';
export const SHARE_PROGRAM_TERMS_PATH = '/share-program/terms';
export const LOYALTY_PATH = '/loyalty';
export const EARNINGS_PATH = '/earnings';
export const ONBOARDING_PATH = '/onboarding';
export const SUPPORT_PATH = '/support';
export const LOGIN_PATH = '/login';
export const SET_PASSWORD_PATH = '/set-password';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const RESEND_EMAIL = '/resend-email';
export const SIGNUP_PATH = '/signup';
export const SIGNUP_WITH_MENU_PATH = '/signup/V2';
export const SIGNUP_WITH_MENU_V3_PATH = '/signup/V3';
export const SIGNUP_WITH_MENU_V4_PATH = '/signup/V4';
export const SIGNUP_WITH_MENU_V5_PATH = '/signup/V5';
export const SIGNUP_WITH_MENU_V6_PATH = '/signup/V6';
export const SIGNUP_WITH_MENU_V7_PATH = '/signup/V7';
export const SIGNUP_OOZ_PATH = '/signup/out-of-zone';
export const PROFILE_PATH = '/profile';
export const MENU_PATH = '/menu';
export const WEB_PATH = '/web';
export const LEGACY_WEB_BUTTONS_PATH = '/web-buttons';
export const BANK_DETAILS_PATH = `${SETTING_PATH}/bank-details`;
export const OVERVIEW_PATH = '/overview';
export const MULTISTORE_TRANSACTION_PATH = `${OVERVIEW_PATH}/transactions`;
export const MULTISTORE_SUMMARY_PATH = `${OVERVIEW_PATH}/sales-summary`;
export const MULTISTORE_BRAND_GROUP_PATH = `${OVERVIEW_PATH}/branding`;
export const REPORT_DOWNLOAD_PATH = '/report-subscription';
export const DISCOUNTS_PATH = '/discounts';

export const NON_MERCHANT_PATHS = [
  LOGIN_PATH,
  SET_PASSWORD_PATH,
  SIGNUP_PATH,
  SIGNUP_WITH_MENU_PATH,
  SIGNUP_OOZ_PATH,
  RESET_PASSWORD_PATH,
  FORGOT_PASSWORD_PATH,
  RESEND_EMAIL,
  PROFILE_PATH,
  OVERVIEW_PATH,
  MULTISTORE_TRANSACTION_PATH,
  MULTISTORE_SUMMARY_PATH,
  MULTISTORE_BRAND_GROUP_PATH,
];
export const NON_MERCHANT_AUTHENTICATED_PATHS = [PROFILE_PATH];

export const MERCHANT_PATHS = [
  EMPTY_PATH,
  FEEDBACKS_PATH,
  SUPPORT_TICKETS_PATH,
  SALES_PATH,
  SETTING_PATH,
  OFFERS_PATH,
  SHARE_PROGRAM_PATH,
  SHARE_PROGRAM_TERMS_PATH,
  LOYALTY_PATH,
  EARNINGS_PATH,
  ONBOARDING_PATH,
  SUPPORT_PATH,
  LEGACY_WEB_BUTTONS_PATH,
  WEB_PATH,
  MENU_PATH,
  REPORT_DOWNLOAD_PATH,
  DISCOUNTS_PATH,
];
