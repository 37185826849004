import apiClient from '../apiClient';

export const getNotifications = async ({ merchantId }) => {
  const endpoint = `merchants/${merchantId}/notifications`;
  const response = await apiClient({
    method: 'get',
    prefixWithVersion: false,
    url: endpoint,
  });
  return response.data;
};

export const postNotificationInteraction = ({
  merchantId,
  notificationType,
  mute,
}) => {
  const endpoint = `merchants/${merchantId}/notifications/${notificationType}/interaction`;
  return apiClient({
    method: 'post',
    prefixWithVersion: false,
    url: endpoint,
    data: {
      muteNotification: mute,
    },
  });
};
