const getDateRange = () => {
  const dateRange = {
    CURRENT_WEEK: 'current_week',
    CURRENT_4_WEEKS: 'current_4_weeks',
    CURRENT_8_WEEKS: 'current_8_weeks',
    CURRENT_YEAR: 'current_year',
  };
  const currentYear = new Date().getFullYear();
  // define constants for previous year to 2015
  for (let year = currentYear - 1; year >= 2015; year -= 1) {
    dateRange[`YEAR_${year}`] = `year_${year}`;
  }
  return dateRange;
};

export const DateRange = getDateRange();

export const Year = {
  CURRENT: 'current_year',
  PREVIOUS: 'previous_year',
};

export const TimeFrame = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};
