import React from 'react';
import PropTypes from 'prop-types';

const HamburgerMenu = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.37146" y="0.500854" width="23" height="1.98682" fill={color} />
    <rect x="0.37146" y="7.49475" width="23" height="1.98682" fill={color} />
    <rect x="0.37146" y="14.4886" width="23" height="1.98682" fill={color} />
  </svg>
);

HamburgerMenu.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

HamburgerMenu.defaultProps = {
  width: 24,
  height: 17,
  color: 'white',
};

export default HamburgerMenu;
