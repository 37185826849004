import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import api from 'common/api/reducers';
import merchantFilter from 'core/MerchantFilter/reducers';
import salesSummary from 'core/SalesDetail/sections/ComparisonChart/reducers';
import navigationTabs from 'core/NavigationBar/reducers';
import sideNav from 'core/SideNav/reducers';
import menu from 'core/Menu/reducers';
import multistore from 'core/Multistore/reducers';
import brandingGroupList from 'core/Multistore/Branding/reducers/brandGroupList/reducers';
import singleGroup from 'core/Multistore/Branding/reducers/singleGroup/reducer';
import orderMethods from 'core/OrderMethods/reducers';
import auth from '../common/auth/reducers';
import user from './users';
import settings from './settings/reducer';

import { reducer as errors } from '../common/errors';

export default history =>
  combineReducers({
    api,
    auth,
    singleGroup,
    brandingGroupList,
    merchantFilter,
    router: connectRouter(history),
    salesSummary,
    sideNav,
    errors,
    user,
    settings,
    menu,
    navigationTabs,
    multistore,
    orderMethods,
  });
