import { setItem, getItem } from 'common/storage';

// eslint-disable-next-line import/no-cycle
import apiClient from 'common/api/apiClient';
import {
  getNotifications,
  postNotificationInteraction,
} from 'common/api/notifications';
import { getMerchantList } from './selectors';

const getExperiments = async ({ merchantId }) => {
  const endpoint = `/merchants/${merchantId}/experiments`;
  try {
    const response = await apiClient({
      method: 'get',
      url: endpoint,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateExperiments = attributeMap => ({
  type: 'merchantFilter/EXPERIMENTS',
  payload: {
    attributeMap,
  },
});

/* eslint-disable import/prefer-default-export */

export const addToMerchantList = merchantsList => ({
  type: 'merchantFilter/ADD_TO_MERCHANT_LIST',
  payload: { merchantsList },
});

const setMerchantAction = merchantId => ({
  type: 'merchantFilter/SET_MERCHANT',
  payload: { merchantId },
});

const clearMerchantAction = () => ({
  type: 'merchantFilter/CLEAR_MERCHANT',
});

const setMerchantListStorage = merchantListStorage => ({
  type: 'merchantFilter/SET_MERCHANT_LIST_STORAGE',
  payload: { merchantListStorage },
});

export const setNewMerchantLoading = bool => ({
  type: 'merchantFilter/SET_LOADING',
  payload: bool,
});

export const setNewMerchantLoadingError = bool => ({
  type: 'merchantFilter/SET_LOADING_ERROR',
  payload: bool,
});

export const setMerchantNotifications = notifications => ({
  type: 'merchantFilter/NOTIFICATIONS_LOADED',
  payload: { notifications },
});

export const getMerchantNotifications = merchantId => async dispatch => {
  try {
    const response = await getNotifications({ merchantId });
    const notifications = {
      [merchantId]: response.notifications || [],
    };
    dispatch(setMerchantNotifications(notifications));
  } catch (e) {
    // intentionally empty to fail silently
  }
};

export const saveNotificationInteraction = ({
  merchantId,
  notificationType,
  mute,
}) => async dispatch => {
  try {
    await postNotificationInteraction({ merchantId, notificationType, mute });
    dispatch(getMerchantNotifications(merchantId));
  } catch (e) {
    // intentionally empty to fail silently
  }
};

const getNewMerchantListForStorage = (merchantList, accessibleMerchantList) => {
  const currentMerchantListInStorage = getItem('merchantList') || [];
  return currentMerchantListInStorage
    .concat(merchantList)
    .filter((elem, pos, arr) => arr.indexOf(elem) === pos)
    .filter(merchantId =>
      accessibleMerchantList.find(merchant => merchant.id === merchantId),
    );
};

export const updateMerchantListStorage = merchantList => (
  dispatch,
  getState,
) => {
  const newMerchantList = getNewMerchantListForStorage(
    merchantList,
    getMerchantList(getState()),
  );
  setItem('merchantList', newMerchantList);
  dispatch(setMerchantListStorage(newMerchantList));
};

export const setNewMerchantToStorage = merchantId => (dispatch, getState) => {
  const newMerchantList = getNewMerchantListForStorage(
    [merchantId],
    getMerchantList(getState()),
  );
  setItem('merchantList', newMerchantList);
  dispatch(dispatch(setMerchantListStorage(newMerchantList)));
};

export const removeMerchantFromStorage = merchantId => dispatch => {
  const newMerchantList = getItem('merchantList').filter(
    id => id !== merchantId,
  );
  setItem('merchantList', newMerchantList);
  dispatch(dispatch(setMerchantListStorage(newMerchantList)));
};

export const setMerchant = merchantId => async dispatch => {
  dispatch(setMerchantAction(merchantId));
  dispatch(setNewMerchantToStorage(merchantId));
  dispatch(getMerchantNotifications(merchantId));
  // Do this after any dispatches as we do not want to stop
  // the page from loading.
  try {
    const { attributeMap } = await getExperiments({ merchantId });
    const merchantAttributes = {};
    merchantAttributes[merchantId] = attributeMap;
    dispatch(updateExperiments(merchantAttributes));
  } catch (e) {
    // fail silently if you cant get experiments
  }
};

export const clearMerchant = () => async dispatch => {
  dispatch(clearMerchantAction());
};
