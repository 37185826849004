import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';

import sendAnalytic from 'common/analytics';
import { useRemoteConfig } from 'common/remoteConfig';
import { mobile } from 'common/style/media';
import {
  getCurrentMerchantIsLoyaltyEligible,
  isCurrentMerchantTrialExpired,
  getMerchantLoadingError,
} from 'core/MerchantFilter/selectors';

import { hasR1 as hasR1Selector } from 'common/auth/selectors';
import { isSideNavOpen } from './selectors';
import { closeSideNav as closeSideNavAction } from './actions';
import {
  loyaltyMerchantNavList,
  mainMerchantNavList,
  loyaltyMerchantNavListShareProgram,
  mainMerchantNavListShareProgram,
  profileNavList,
  multistoreNavList,
  r1MultiStoreNavList,
} from './paths';
import NavList from './NavList';

export const Panel = styled.div`
  background: ${props => props.theme.themeVariable.sideNavBackground};
  box-shadow: 1px 0px 0px ${props => props.theme.colours.grayLightish};
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  overflow: hidden;
  position: fixed;
  top: ${props => props.theme.layout.topNav.height}px;
  bottom: 0;
  width: ${props => props.theme.layout.sideNav.expandedWidth}px;
  ${mobile`
    height: calc(100% - ${props => props.theme.layout.topNav.mobileHeight}px);
    left: 0
    position: fixed;
    top: ${props => props.theme.layout.topNav.mobileHeight}px;
    transition: width 0.25s ease;
    ${props => !props.isOpen && 'width: 0;'}
    z-index: ${props => props.theme.zIndex.sideNav};
  `}
`;

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: width 0.25s ease;
  width: 100vw;
  z-index: ${props => props.theme.zIndex.sideNav - 1};
  ${mobile`
    ${props => props.isOpen && 'display: block;'}
  `}
`;

const SideNav = ({
  isOpen,
  closeSideNav,
  className,
  location,
  isLoyaltyEligible,
  isMultistore,
  hasR1,
  isTrialExpired,
  merchantLoadingError,
}) => {
  const { getBoolean } = useRemoteConfig();
  const isShareProgramEnrollingEnabled = getBoolean(
    'portal_share_program_enrolling_enabled',
  );

  const renderNav = () => {
    if (isMultistore) {
      return hasR1 ? (
        <NavList navList={r1MultiStoreNavList} />
      ) : (
        <NavList navList={multistoreNavList} />
      );
    }
    if (
      profileNavList.find(
        page => page.label !== 'back' && page.to === location.pathname,
      )
    ) {
      return <NavList navList={profileNavList} showBackButton />;
    }
    if (isLoyaltyEligible) {
      return (
        <NavList
          isTrialExpired={isTrialExpired}
          navList={
            isShareProgramEnrollingEnabled
              ? loyaltyMerchantNavListShareProgram
              : loyaltyMerchantNavList
          }
        />
      );
    }
    return (
      <NavList
        isTrialExpired={isTrialExpired}
        navList={
          isShareProgramEnrollingEnabled
            ? mainMerchantNavListShareProgram
            : mainMerchantNavList
        }
      />
    );
  };

  if (merchantLoadingError) {
    return null;
  }

  return (
    <div className={className}>
      <Backdrop
        isOpen={isOpen}
        onClick={() => {
          sendAnalytic(
            'pp_navigation',
            'Navigation (Mobile)',
            'RIT_click_mobile_back_drop',
            `|${window.location.href}`,
          );
          closeSideNav();
        }}
      />
      <Panel isOpen={isOpen}>{renderNav()}</Panel>
    </div>
  );
};

SideNav.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeSideNav: PropTypes.func.isRequired,
  className: PropTypes.string,
  isLoyaltyEligible: PropTypes.bool.isRequired,
  isMultistore: PropTypes.bool,
  hasR1: PropTypes.bool,
  isTrialExpired: PropTypes.bool,
};

SideNav.defaultProps = {
  className: '',
  isMultistore: false,
  hasR1: false,
};

export default compose([
  withRouter,
  connect(
    createStructuredSelector({
      isOpen: isSideNavOpen,
      isLoyaltyEligible: getCurrentMerchantIsLoyaltyEligible,
      hasR1: hasR1Selector,
      isTrialExpired: isCurrentMerchantTrialExpired,
      merchantLoadingError: getMerchantLoadingError,
    }),
    {
      closeSideNav: closeSideNavAction,
    },
  ),
])(SideNav);
