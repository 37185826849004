import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hasAllPermissions, hasAnyPermission } from 'common/auth/selectors';
import { Permission } from 'common/auth/constants';
import Unauthorized from 'common/components/Unauthorized';

export const RequirementType = {
  ALL: 'all', // require all passed in permissions
  ANY: 'any', // require at least one of the passed in permissions
};

const RequiresPermission = ({
  isAuthorized,
  render,
  showUnauthorizedMessage,
  UnauthorizedComponent,
}) => {
  if (!isAuthorized) {
    if (UnauthorizedComponent) return <UnauthorizedComponent />;
    if (showUnauthorizedMessage) return <Unauthorized />;
    return null;
  }
  return render();
};

RequiresPermission.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  render: PropTypes.func.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  showUnauthorizedMessage: PropTypes.bool.isRequired,
  requirementType: PropTypes.oneOf([RequirementType.ALL, RequirementType.ANY])
    .isRequired,
  UnauthorizedComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

const ConnectedRequiresPermission = connect(
  (state, { permissions, requirementType }) => ({
    isAuthorized:
      requirementType === RequirementType.ANY
        ? hasAnyPermission(state, permissions)
        : hasAllPermissions(state, permissions),
  }),
)(RequiresPermission);

export const InternalFeature = ({ children }) => (
  <ConnectedRequiresPermission
    permissions={[Permission.INTERNAL_TEST]}
    render={() => children}
  />
);

InternalFeature.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
};

ConnectedRequiresPermission.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  render: PropTypes.func.isRequired,
  showUnauthorizedMessage: PropTypes.bool,
  requirementType: PropTypes.oneOf([RequirementType.ALL, RequirementType.ANY]),
  UnauthorizedComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

ConnectedRequiresPermission.defaultProps = {
  showUnauthorizedMessage: false,
  requirementType: RequirementType.ALL,
  UnauthorizedComponent: null,
};

export default ConnectedRequiresPermission;
