/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const MenuGrid = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="10" height="10" fill={color} />
    <rect y="14" width="10" height="10" fill={color} />
    <rect x="14" width="10" height="10" fill={color} />
    <rect x="14" y="14" width="10" height="10" fill={color} />
  </svg>
);

MenuGrid.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

MenuGrid.defaultProps = {
  color: 'white',
  size: '12',
};

export default MenuGrid;
