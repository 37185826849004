/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = ({ width, height }) => (
  <svg
    style={{ minWidth: width, minHeight: height }}
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 1.66665H10V0.333313H8.66667V1.66665H3.33333V0.333313H2V1.66665H1.33333C0.593333 1.66665 0.00666667 2.26665 0.00666667 2.99998L0 12.3333C0 13.0666 0.593333 13.6666 1.33333 13.6666H10.6667C11.4 13.6666 12 13.0666 12 12.3333V2.99998C12 2.26665 11.4 1.66665 10.6667 1.66665ZM2.66667 6.33331V7.66665H4V6.33331H2.66667ZM6.66667 6.33331H5.33333V7.66665H6.66667V6.33331ZM8 6.33331H9.33333V7.66665H8V6.33331ZM1.33333 12.3333H10.6667V4.99998H1.33333V12.3333Z"
      fill="black"
    />
  </svg>
);

CalendarIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

CalendarIcon.defaultProps = {
  height: 18,
  width: 20,
};

export default CalendarIcon;
