import React from 'react';
import PropTypes from 'prop-types';

const GraphIcon = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09021 0.215942H16.0902C17.1902 0.215942 18.0902 1.11594 18.0902
      2.21594V16.2159C18.0902 17.3159 17.1902 18.2159 16.0902 18.2159H2.09021C0.99021
      18.2159 0.09021 17.3159 0.09021 16.2159V2.21594C0.09021 1.11594 0.99021 0.215942
      2.09021 0.215942ZM6.50867 10.016L3.22342 13.3079L1.9401 11.9536L6.50867
      7.2899L9.87735 10.7048L14.8607 5.04727L16.2403 6.31639L9.87735 13.3846L6.50867
      10.016Z"
      fill={color}
    />
  </svg>
);

GraphIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

GraphIcon.defaultProps = {
  color: 'black',
  size: 12,
};

export default GraphIcon;
