import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { setMerchant as setMerchantAction } from 'core/MerchantFilter/actions';
import CloseIcon from 'common/components/Icons/CloseIcon';
import { isSuperUser as isSuperUserSelector } from 'common/auth/selectors';
import MenuGrid from 'common/components/Icons/MenuGrid';
import {
  getMerchantList,
  getPrimaryMerchantId,
} from 'core/MerchantFilter/selectors';
import { getTabState } from './selectors';
import { removeTabAction, updateTabURLAction } from './actions';
import { setMerchantForSuperUser as setMerchantForSuperUserAction } from '../Portal/actions';

const TabContainer = styled.div`
  width: 100%;
  display: flex;
  font-weight: 500;
`;

const StyledText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CloseButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  opacity: 0.5;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: ${props =>
      props.active
        ? props.theme.colours.grayLighter
        : props.theme.colours.grayDarkish};
    border-radius: 50%;
  }
`;

const TabCloseButton = css`
  ${CloseButton}:hover {
    background-color: ${props => props.theme.colours.grayDarkish};
  }

  &.active-link {
    ${CloseButton}:hover {
      background-color: ${props => props.theme.colours.grayLighter};
    }
  }
`;

const svgFill = css`
  path {
    fill: ${props => props.theme.colours.white};
  }

  &.active-link {
    path,
    rect {
      fill: ${props => props.theme.colours.blackDarker};
    }
  }
`;

const TabWrapper = styled.div`
  border: none;
  min-width: 0;
  cursor: pointer;
  font-size: 14px;
  background-color: ${props => props.theme.colours.blackDarker};
  color: ${props => props.theme.colours.white};
  padding: 0 10px;
  max-width: 294px;
  height: 45px;
  flex: 1 1 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.colours.grayDarker};
    color: ${props => props.theme.colours.white};
    text-decoration: none;
  }

  &.active-link {
    background-color: ${props => props.theme.colours.white};
    color: ${props => props.theme.colours.blackDarker};
    &:hover {
      background-color: ${props => props.theme.colours.white};
    }
  }

  ${TabCloseButton}
  ${svgFill}
`;

const AllStoreSpan = styled.span`
  margin-left: 7px;
`;

export const Tabs = ({
  history,
  tabState,
  updateTabURL,
  removeTab,
  isSuperUser = false,
  currentMerchantId,
  merchantList = [],
  setMerchant,
  setMerchantForSuperUser,
}) => {
  const { tabs } = tabState;
  useEffect(() => {
    if (history.location.pathname === '/' && tabs.length) {
      try {
        const match = Math.max(
          tabs.findIndex(tab => tab.merchantId === currentMerchantId),
          0,
        );
        history.push(tabs[match].url);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`error forwarding page to current tabstate: ${e}`);
      }
    }
    // If you touch this file you should fix this ignore statement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const multistoreEnabled = isSuperUser || merchantList.length > 1;
  return (
    <TabContainer>
      {multistoreEnabled && (
        <TabWrapper
          data-testid="allstore_tab"
          title="All Stores Overview"
          className={
            history.location.pathname.includes('/overview') && 'active-link'
          }
          onClick={() => {
            updateTabURL(history.location.pathname);
            history.push('/overview');
          }}
        >
          <StyledText>
            <MenuGrid size="20" />
            <AllStoreSpan>All Stores Overview</AllStoreSpan>
          </StyledText>
        </TabWrapper>
      )}
      {tabs.map(({ name, merchantId, url }) => (
        <TabWrapper
          data-testid={merchantId}
          key={merchantId}
          title={name}
          className={
            history.location.pathname.split('/')[2] === url.split('/')[2] &&
            'active-link'
          }
          onClick={() => {
            // only navigate if user clicked on a different tab than what is currently active
            if (
              history.location.pathname.split('/').length <= 2 ||
              history.location.pathname.split('/')[2] !== url.split('/')[2]
            ) {
              if (isSuperUser) {
                setMerchantForSuperUser(merchantId);
              } else {
                setMerchant(merchantId);
              }
              updateTabURL(history.location.pathname);
              history.push(url);
            }
          }}
        >
          <StyledText>{name}</StyledText>
          {tabs.length > 1 && (
            <CloseButton
              data-testid={`close-${merchantId}`}
              onClick={e => {
                removeTab(merchantId);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <CloseIcon />
            </CloseButton>
          )}
        </TabWrapper>
      ))}
    </TabContainer>
  );
};

Tabs.propTypes = {
  history: propTypes.shape({
    push: propTypes.func,
  }).isRequired,
  tabState: propTypes.shape({
    tabs: propTypes.arrayOf(
      propTypes.shape({
        merchantId: propTypes.string,
        url: propTypes.string,
        name: propTypes.string,
      }),
    ),
  }).isRequired,
  updateTabURL: propTypes.func.isRequired,
  removeTab: propTypes.func.isRequired,
  isSuperUser: propTypes.bool,
  merchantList: propTypes.arrayOf(
    propTypes.shape({
      address: propTypes.shape({
        addressLine1: propTypes.string,
        addressLine2: propTypes.string,
        city: propTypes.string,
        province: propTypes.string,
        country: propTypes.string,
        postalCode: propTypes.string,
      }),
      id: propTypes.string,
      url: propTypes.shape({
        id: propTypes.string,
        name: propTypes.string,
      }),
    }),
  ),
};

export default compose([
  connect(
    createStructuredSelector({
      tabState: getTabState,
      isSuperUser: isSuperUserSelector,
      currentMerchantId: getPrimaryMerchantId,
      merchantList: getMerchantList,
    }),
    {
      removeTab: removeTabAction,
      updateTabURL: updateTabURLAction,
      setMerchant: setMerchantAction,
      setMerchantForSuperUser: setMerchantForSuperUserAction,
    },
  ),
  withRouter,
])(Tabs);
