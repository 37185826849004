export const MenuActionTypes = {
  LOADING_LIVE_MENU: 'menu/LOADING_LIVE_MENU',
  LOAD_LIVE_MENU_ERROR: 'menu/LOAD_LIVE_MENU_ERROR',
  LOAD_LIVE_MENU_SUCCESS: 'menu/LOAD_LIVE_MENU_SUCCESS',
  PATCH_LOCAL_MENU_IMAGE: 'menu/PATCH_LOCAL_MENU_IMAGE',
  UPDATING_LIVE_MENU: 'menu/UPDATING_LIVE_MENU',
  LOAD_SHARED_MERCHANT_LIST_SUCCESS: 'menu/LOAD_SHARED_MERCHANT_LIST_SUCCESS',
  LOADING_AVALARA_TAX_RATES: 'menu/LOADING_AVALARA_TAX_RATES',
  LOAD_AVALARA_TAX_RATES_ERROR: 'menu/LOAD_AVALARA_TAX_RATES_ERROR',
  LOAD_AVALARA_TAX_RATES_SUCCESS: 'menu/LOAD_AVALARA_TAX_RATES_SUCCESS',
  LOADING_IS_AVALARA_ENABLED: 'menu/LOADING_IS_AVALARA_ENABLED',
  LOAD_IS_AVALARA_ENABLED_ERROR: 'menu/LOAD_IS_AVALARA_ENABLED_ERROR',
  LOAD_IS_AVALARA_ENABLED_SUCCESS: 'menu/LOAD_IS_AVALARA_ENABLED_SUCCESS',
};

export const SPECIAL_SCHEDULE = {
  NO_SCHEDULE: 'NO_SCHEDULE',
  DATE: 'DATE',
};

// Keeping this here for now so nothing breaks
export const MENU_PATHS = {
  ROOT: 'menu',
  CATEGORIES: 'category',
  ITEMS: 'item',
  MODIFIER_GROUPS: 'modifier-group',
  OVERVIEW: 'view',
  NEW: 'new',
};

const MENU_QUERY = {
  COPY: 'copy',
};

export const MENU_PARAM = {
  MENU: 'menu',
  CATEGORY: 'category',
  ITEM: 'item',
  MODIFIER_GROUP: 'modifier-group',
  OVERVIEW: 'view',
  NEW: 'new',
  SHARED_LOCATIONS: 'shared-locations',
};

const MENU_PATH_ROOT = `/:merchantName/:merchantUrl/${MENU_PARAM.MENU}`;

export const MENU_PATH = {
  ROOT: MENU_PATH_ROOT,
  MENU: `${MENU_PATH_ROOT}/:menuPath`,
  CATEGORIES: `${MENU_PATH_ROOT}/${MENU_PARAM.CATEGORY}`,
  ITEMS: `${MENU_PATH_ROOT}/${MENU_PARAM.ITEM}`,
  ITEM: `${MENU_PATH_ROOT}/${MENU_PARAM.ITEM}/:itemId`,
  NEW_ITEM: `${MENU_PATH_ROOT}/${MENU_PARAM.ITEM}/${MENU_PARAM.NEW}`,
  COPY_ITEM: `${MENU_PATH_ROOT}/${MENU_PARAM.ITEM}/${MENU_PARAM.NEW}?${MENU_QUERY.COPY}=:itemId`,
  MODIFIER_GROUPS: `${MENU_PATH_ROOT}/${MENU_PARAM.MODIFIER_GROUP}`,
  MODIFIER_GROUP: `${MENU_PATH_ROOT}/${MENU_PARAM.MODIFIER_GROUP}/:modifierGroupId`,
  OVERVIEW: `${MENU_PATH_ROOT}/${MENU_PARAM.OVERVIEW}`,
  SHARED_LOCATIONS: `${MENU_PATH_ROOT}/${MENU_PARAM.SHARED_LOCATIONS}`,
};

export const MAX_ITEM_USING_COUNT = 2;

export const MAX_ITEM_USING_TOOLTIP_COUNT = 10;

export const PREPARATION_TYPE = {
  UNKNOWN_PREP_TIME_CATEGORY: 'UNKNOWN_PREP_TIME_CATEGORY',
  READY_MADE_DRINK: 'READY_MADE_DRINK',
  PREP_REQUIRED_DRINK: 'PREP_REQUIRED_DRINK',
  READY_MADE_FOOD: 'READY_MADE_FOOD',
  PREP_REQUIRED_FOOD: 'PREP_REQUIRED_FOOD',
  READY_MADE_ALCOHOL: 'READY_MADE_ALCOHOL',
  PREP_REQUIRED_ALCOHOL: 'PREP_REQUIRED_ALCOHOL',
};
