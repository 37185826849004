import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Intercom from 'core/Intercom';
import { getExternalId } from 'common/utils/session';
import { updateUserLocale as updateUserLocaleAction } from './actions';
import { getUserLocale } from './selectors';
import sendAnalytic from './common/analytics';

class AppInit extends Component {
  componentDidMount() {
    sendAnalytic('pp', 'partner_portal', 'RIT_impressions', getExternalId());
    const { updateLocale, userLocale } = this.props;
    updateLocale(userLocale);
  }

  render() {
    const { children } = this.props;
    return (
      <>
        <Intercom />
        {children}
      </>
    );
  }
}

AppInit.propTypes = {
  updateLocale: PropTypes.func.isRequired,
  userLocale: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
};

export default connect(
  state => ({
    userLocale: getUserLocale(state),
  }),
  {
    updateLocale: updateUserLocaleAction,
  },
)(AppInit);
