/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  SearchBarWithDropdown,
  LoadingSpinner,
} from '@ritualco/ritual-frontend-components';
import { withRouter } from 'react-router-dom';
import { compose } from 'lodash/fp';
import _ from 'lodash';

import { withErrorHandler } from 'common/errors';
import statusCodes from 'common/api/statusCodes';
import theme from 'common/style/theme';
import { isSuperUser as isSuperUserSelector } from 'common/auth/selectors';
import { withTranslation } from 'react-i18next';
import { searchMerchant } from '../../common/api/gateway';
import MerchantList from './MerchantList';

const LoadingWrapper = styled.div`
  display: flex;
  padding-bottom: 15px;
  padding-top: 15px;
  justify-content: center;
`;

const SearchBarWrapper = styled(SearchBarWithDropdown)`
  box-sizing: content-box;
  overflow-y: auto;
`;

const SEARCH_LIMIT = 75;

export class UnWrappedMerchantSearch extends React.Component {
  fetchMerchantSearch = _.debounce(async searchQuery => {
    try {
      const { searchQuery: currSearchQuery } = this.state;
      const searchResults = await searchMerchant(searchQuery, SEARCH_LIMIT);
      if (searchQuery === currSearchQuery) {
        this.setState({
          isFetching: false,
          fetchedMerchantList: searchResults.merchants,
        });
      }
    } catch (err) {
      if (err.response.status === statusCodes.BAD_REQUEST) {
        this.setState({ fetchedMerchantList: [], isFetching: false });
        return;
      }
      const { handleError } = this.props;
      this.setState({ isFetching: false });
      handleError(err);
    }
  }, 500).bind(this);

  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      shouldShow: false,
      searchQuery: '',
      fetchedMerchantList: [],
    };
  }

  searchQueryValidator(input, store) {
    const {
      address: { addressLine1, city, province },
      name,
    } = store;
    const { isSuperUser } = this.props;

    const addressStr = `${name} | ${addressLine1}, ${city}, ${province}`;
    return (
      isSuperUser || addressStr.toLowerCase().includes(input.toLowerCase())
    );
  }

  handleOnChange(value) {
    const { isSuperUser } = this.props;

    if (value.length === 0) {
      this.setState({ shouldShow: false });
    } else if (isSuperUser && /\S/.test(value)) {
      this.setState({ isFetching: true, shouldShow: true, searchQuery: value });
      this.fetchMerchantSearch(value);
    } else {
      this.setState({ shouldShow: true });
    }
  }

  handleRenderDropDown(input, closeDropdown, updateValue) {
    let filtered = [];
    const { isSuperUser, merchantList } = this.props;
    const { isFetching, fetchedMerchantList } = this.state;

    if (isSuperUser) {
      if (isFetching) {
        return (
          <LoadingWrapper>
            <LoadingSpinner size={35} colour={theme.colours.black} />
          </LoadingWrapper>
        );
      }
      filtered = fetchedMerchantList.filter(store =>
        this.searchQueryValidator(input, store),
      );
    } else if (input.length === 0) {
      filtered = merchantList;
    } else {
      filtered = merchantList.filter(store =>
        this.searchQueryValidator(input, store),
      );
    }

    return (
      <MerchantList
        filtered={filtered}
        input={input}
        closeDropdown={closeDropdown}
        updateValue={updateValue}
      />
    );
  }

  render() {
    const { t, isSuperUser } = this.props;
    const { shouldShow, searchQuery } = this.state;

    return (
      <SearchBarWrapper
        data-testid="searchBar"
        focusWidth="800px"
        placeholder={t('searchStore')}
        shouldShow={shouldShow}
        onClick={() => {
          if (!isSuperUser && searchQuery.length === 0) {
            this.setState({ shouldShow: true });
          }
        }}
        onChange={inputValue => this.handleOnChange(inputValue)}
        renderDropDown={({ input, closeDropdown, updateValue }) =>
          this.handleRenderDropDown(input, closeDropdown, updateValue)
        }
      />
    );
  }
}

UnWrappedMerchantSearch.propTypes = {
  t: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  merchantList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSuperUser: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose([
  withTranslation('global'),
  withRouter,
  withErrorHandler,
  connect(state => ({ isSuperUser: isSuperUserSelector(state) })),
])(UnWrappedMerchantSearch);
