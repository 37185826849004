import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { mobile } from 'common/style/media';
import { withTranslation } from 'react-i18next';
import ResponsiveImage from 'common/components/ResponsiveImage';

const FoodDecorationLeft = styled(ResponsiveImage)`
  width: 364px;
  height: 149px;

  ${mobile`
    display: none;
  `}
`;

const FoodDecorationRight = styled(ResponsiveImage)`
  width: 371px;
  height: 149px;

  ${mobile`
    display: none;
  `}
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.showImage ? 'space-between' : 'center')};
  height: ${props =>
    props.showImage ? '' : props.theme.layout.footer.height}px;

  ${mobile`
    background-color: ${props => props.theme.colours.background};
    height: ${props => props.theme.layout.footer.mobileHeight}px;
    justify-content: center;
    padding-top: 0;
  `}
`;

const CopyRightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = ({ t, className, showImage }) => (
  <RowWrapper showImage={showImage} className={className}>
    {showImage && <FoodDecorationLeft id="partners.login.foodDecorLeft.img" />}
    <CopyRightWrapper>
      &copy;
      {t('copyright')}
    </CopyRightWrapper>
    {showImage && (
      <FoodDecorationRight id="partners.login.foodDecorRight.img" />
    )}
  </RowWrapper>
);

Footer.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  showImage: PropTypes.bool,
};

Footer.defaultProps = {
  className: '',
  showImage: true,
};

export default withTranslation('global')(Footer);
