import React, { createContext, useState, useEffect } from 'react';
import theme from 'common/style/theme';
import _ from 'lodash';

const {
  breakpoints: { mobileMax },
} = theme;

export const viewportContext = createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const resizeWindow = _.debounce(() => {
    setWidth(window.innerWidth);
  }, 250);

  useEffect(() => {
    window.addEventListener('resize', resizeWindow);
    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  return (
    <viewportContext.Provider
      value={{ isMobile: width <= mobileMax, isDesktop: width > mobileMax }}
    >
      {children}
    </viewportContext.Provider>
  );
};

export default ViewportProvider;
