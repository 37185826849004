import React from 'react';

export default ({ color = '#5C5F6C' }) => (
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8622 0L16.0547 2.22612L5.96111 12L0.0546875 6.28065L2.24719 4.05453L5.96111 7.65082L13.8622 0Z"
      fill={color}
    />
  </svg>
);
