import React from 'react';
import PropTypes from 'prop-types';

const RevertIcon = ({ width, height, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 21 9" fill="none" {...props}>
    <path
      d="M10.5 1c-2.65 0-5.05.99-6.9 2.6L0 0v9h9L5.38 5.38C6.77 4.22 8.54 3.5 10.5 3.5c3.54 0 6.55 2.31 7.6 5.5l2.37-.78C19.08 4.03 15.15 1 10.5 1z"
      fill="#A5A8B8"
    />
  </svg>
);

RevertIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

RevertIcon.defaultProps = {
  width: 21,
  height: 9,
};

export default RevertIcon;
