import createReducer from '../utils/createReducer';

export const INITIAL_STATE = {
  authHydrated: false,
  isAuthenticated: false,
  isFetching: false,
  isShowingPrivateDataMask: false,
  user: undefined,
  error: null,
  loginFailed: false,
  updateUserProfileStatus: {
    isSubmitting: false,
    error: null,
  },
};

const loginRequest = state => ({
  ...state,
  loginFailed: false,
});

const loginSuccess = (state, payload) => ({
  ...state,
  isAuthenticated: true,
  user: payload.user,
  permissions: payload.permissions,
  loginFailed: false,
});

const loginFailure = (state, error) => ({
  ...state,
  isAuthenticated: false,
  loginFailed: true,
  error,
});

const authHydrated = state => ({
  ...state,
  authHydrated: true,
});

const updateUserProfileSubmitting = state => ({
  ...state,
  updateUserProfileStatus: {
    isSubmitting: true,
    error: null,
  },
});

const updateUserProfileFailure = (state, payload) => ({
  ...state,
  updateUserProfileStatus: {
    isSubmitting: false,
    error: payload.error,
  },
});

const updateUserProfileSuccess = (state, payload) => ({
  ...state,
  updateUserProfileFetching: false,
  updateUserProfileFailed: null,
  updateUserProfileStatus: {
    isSubmitting: false,
    error: null,
  },
  user: {
    ...state.user,
    user: {
      ...state.user.user,
      userProfile: {
        ...state.user.user.userProfile,
        ...payload.userProfile,
        preferredLocale: payload.userProfile.locale,
      },
    },
  },
});

const showPrivateDataMask = state => ({
  ...state,
  isShowingPrivateDataMask: true,
});

const hidePrivateDataMask = state => ({
  ...state,
  isShowingPrivateDataMask: false,
});

const reducers = {
  'auth/LOGIN_REQUEST': loginRequest,
  'auth/LOGIN_SUCCESS': loginSuccess,
  'auth/LOGIN_FAILURE': loginFailure,
  'auth/AUTH_HYDRATED': authHydrated,
  'auth/UPDATE_USER_PROFILE_SUBMITTING': updateUserProfileSubmitting,
  'auth/UPDATE_USER_PROFILE_SUCCESS': updateUserProfileSuccess,
  'auth/UPDATE_USER_PROFILE_FAILURE': updateUserProfileFailure,
  'auth/SHOW_PRIVATE_DATA_MASK': showPrivateDataMask,
  'auth/HIDE_PRIVATE_DATA_MASK': hidePrivateDataMask,
};

export default createReducer(INITIAL_STATE, reducers);
