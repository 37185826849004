import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  transform: ${({ direction }) => {
    switch (direction) {
      case 'up':
        return 'rotate(180deg)';
      case 'down':
        return 'rotate(0deg)';
      case 'left':
        return 'rotate(90deg)';
      case 'right':
      default:
        return 'rotate(-90deg)';
    }
  }};
`;

const Triangle = ({ width, height, direction, color }) => (
  <StyledSvg
    width={width}
    height={height}
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    direction={direction}
  >
    <path
      d="M12.6141 0.93287H0.444092L6.52911 7.01785L12.6141 0.93287Z"
      fill={color}
    />
  </StyledSvg>
);

Triangle.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  direction: PropTypes.oneOf(['right', 'left', 'up', 'down']),
};

Triangle.defaultProps = {
  direction: 'right',
  color: 'white',
  width: 13,
  height: 8,
};

export default Triangle;
