import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import IEBanner from './core/IEPage';
import Env from './common/components/Env';
import Routes from './Routes';
import './sentry';
import createStore from './reducers';
import { ErrorList } from './common/errors';
import FullStory from './core/FullStory';
import ErrorBoundary from './core/ErrorBoundary';
import AppInit from './AppInit';
import { RemoteConfigProvider } from './common/remoteConfig';
import ViewportProvider from './common/providers/ViewportProvider';

const history = createBrowserHistory();
const store = createStore(history);

const onInternetExplorer = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');
  return msie > 0 || trident > 0;
};

const App = () => (
  <ErrorBoundary>
    <RemoteConfigProvider>
      <ViewportProvider>
        <Provider store={store}>
          {onInternetExplorer() ? (
            <IEBanner />
          ) : (
            <AppInit>
              <ErrorList />
              <Routes history={history} />
              <Env env={['prod']}>
                <FullStory />
              </Env>
            </AppInit>
          )}
        </Provider>
      </ViewportProvider>
    </RemoteConfigProvider>
  </ErrorBoundary>
);

export default App;
