import React from 'react';
import PropTypes from 'prop-types';

const UpArrow = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.98576 15.4968L12.1558 6.32683L21.3258 15.4968L24.1558 12.6668L12.1558
      0.666826L0.155762 12.6668L2.98576 15.4968Z"
      fill={color}
    />
  </svg>
);

UpArrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

UpArrow.defaultProps = {
  color: 'black',
  width: 13,
  height: 9,
};

export default UpArrow;
