import React from 'react';
import PropTypes from 'prop-types';

const DateRangeIcon = ({ height, width, color }) => (
  <svg
    width={height}
    height={width}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.926 7.80909H12.146L14.886 4.98909C12.156 2.28909
      7.73603 2.18909 5.00603 4.88909C2.27603 7.59909 2.27603 11.9691
      5.00603 14.6791C7.73603 17.3891 12.156 17.3891 14.886
      14.6791C16.246 13.3391 16.926 11.7691 16.926 9.78909H18.926C18.926
      11.7691 18.046 14.3391 16.286 16.0791C12.776 19.5591 7.07603
      19.5591 3.56603 16.0791C0.0660295 12.6091 0.0360296 6.96909
      3.54603 3.49909C7.05603 0.0290868 12.686 0.0290868 16.196
      3.49909L18.926 0.689087V7.80909ZM10.426 5.68909V9.93909L13.926
      12.0191L13.206 13.2291L8.92603 10.6891V5.68909H10.426Z"
      fill={color}
    />
  </svg>
);

DateRangeIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
};

DateRangeIcon.defaultProps = {
  height: 19,
  width: 19,
  color: '#9B9EAE',
};

export default DateRangeIcon;
