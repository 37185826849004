import React from 'react';
import NavItem from 'common/components/NavItem';

import { HomeIcon, SalesTabIcon, GearIcon } from './icons';
import { Backdrop, Panel } from '.';

const FakeSideNav = () => (
  <div>
    <Backdrop />
    <Panel>
      <div>
        <NavItem
          to="/"
          label="Home"
          iconComponent={HomeIcon}
          exact
          onClick={() => {}}
          isPlaceholder
        />
        <NavItem
          to="/sales"
          label="Sales"
          iconComponent={SalesTabIcon}
          onClick={() => {}}
          isPlaceholder
        />
        <NavItem
          to="/settings"
          label="Settings"
          iconComponent={GearIcon}
          onClick={() => {}}
          isPlaceholder
        />
      </div>
    </Panel>
  </div>
);

export default FakeSideNav;
