/* eslint-disable import/prefer-default-export */
import api from 'common/api';
import apiClient from 'common/api/apiClient';
import {
  setMerchant,
  addToMerchantList,
  setNewMerchantLoading,
  setNewMerchantLoadingError,
} from '../MerchantFilter/actions';

export const setMerchantForSuperUser = merchantId => async dispatch => {
  if (merchantId === undefined) return;
  dispatch(setNewMerchantLoading(true));
  dispatch(setNewMerchantLoadingError(null));
  let merchant;
  try {
    const { data } = await api.get(`/merchants/v1/${merchantId}/info`);
    merchant = data;

    const { data: loyaltyDetails } = await apiClient({
      method: 'get',
      url: `merchants/${merchant.merchantId}/loyalty`,
    });
    const { merchantEligibleForProgram } = loyaltyDetails;
    merchant.isLoyaltyEligible = merchantEligibleForProgram;
  } catch (error) {
    if (merchant) {
      merchant.isLoyaltyEligible = false;
    } else {
      dispatch(setNewMerchantLoadingError(error));
    }
  }

  if (merchant) {
    dispatch(addToMerchantList([merchant]));
    dispatch(setMerchant(merchant.merchantId));
  }

  dispatch(setNewMerchantLoading(false));
};
