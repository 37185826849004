import { push } from 'connected-react-router';

import { setMerchant } from 'core/MerchantFilter/actions';
import { getPrimaryMerchantId } from 'core/MerchantFilter/selectors';
import { removeItem } from 'common/storage';
import { getTabState } from './selectors';

const setTabs = ({ tabs }) => ({
  type: 'navigationTabs/SET_TABS',
  payload: { tabs },
});

export const updateTabURLAction = sourcePath => (dispatch, getState) => {
  const tabState = getTabState(getState());
  const tabs = JSON.parse(JSON.stringify(tabState.tabs));
  const pathMatch = tabs.findIndex(
    tab => tab.url.split('/')[1] === sourcePath.split('/')[1],
  );
  if (pathMatch >= 0) {
    tabs[pathMatch].url = sourcePath;
    dispatch(setTabs({ tabs }));
  }
};

export const addTabAndSetActiveAction = ({
  merchantId = '',
  url = '',
  name = '',
}) => (dispatch, getState) => {
  const tabState = getTabState(getState());
  const tabs = JSON.parse(JSON.stringify(tabState.tabs));
  const index = tabs.findIndex(val => val.merchantId === merchantId);
  if (merchantId) setMerchant(merchantId);

  if (index !== -1) {
    tabs[index].name = name;
    tabs[index].url = url;
    dispatch(setTabs({ tabs }));
    return;
  }

  tabs.push({ merchantId, url, name });
  dispatch(setTabs({ tabs }));
};

export const removeTabAction = merchantId => (dispatch, getState) => {
  const tabState = getTabState(getState());
  const tabs = [
    ...JSON.parse(JSON.stringify(tabState.tabs)).filter(
      val => val.merchantId !== merchantId,
    ),
  ];
  removeItem('merchantId');
  dispatch(setTabs({ tabs }));
  if (tabs.length === 0) {
    return;
  }
  const currentMerchantId = getPrimaryMerchantId(getState());
  const indexToRemove = tabState.tabs.findIndex(
    val => val.merchantId === merchantId,
  );
  const currentIndex = tabState.tabs.findIndex(
    val => val.merchantId === currentMerchantId,
  );
  const newIndex = Math.min(
    tabs.length - 1,
    indexToRemove < currentIndex ? currentIndex - 1 : currentIndex,
  );
  if (
    tabs[newIndex] &&
    tabs[newIndex].merchantId &&
    tabs[newIndex].merchantId !== currentMerchantId
  ) {
    setMerchant(tabs[newIndex].merchantId);
    dispatch(push(tabs[newIndex].url));
  }
};
