import React from 'react';
import PropTypes from 'prop-types';

const HK = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="21" height="15" rx="2" fill="white" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="15"
    >
      <rect width="21" height="15" rx="2" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15H21V0H0V15Z"
        fill="#F0371D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.488 6.1093C11.2102 5.50489 11.2541 5.36972 11.4271 4.83722C11.4848 4.65949
        11.4441 4.5135 11.396 4.3411C11.3266 4.09251 11.242 3.78899 11.4151 3.25613C10.6272
        3.00013 9.71181 3.64421 9.37048 4.69472C9.02914 5.74523 9.39115 6.80436 10.179
        7.06036C10.3201 7.10618 10.3043 7.07305 10.2614 6.98333C10.1863 6.82591 10.0281
        6.49428 10.488 6.1093ZM8.9926 7.21321C8.64092 6.33967 8.52594 6.25613 8.07297
        5.92703C7.92177 5.81718 7.77035 5.81081 7.59152 5.80328C7.33367 5.79243 7.01883 5.77919
        6.56555 5.44986C6.07861 6.12007 6.40829 7.1897 7.30191 7.83895C8.19552 8.4882 9.31468 8.47121
        9.80162 7.801C9.88879 7.68102 9.85239 7.68582 9.75383 7.69881C9.5809 7.72161 9.21661 7.76963
        8.9926 7.21321ZM13.5001 7.69038C12.9402 7.69038 12.798 7.69038 12.0001 7.19038C11.4918 6.8719
        11.2253 7.12488 11.0988 7.24496C11.0267 7.31341 11.0001 7.33868 11.0001 7.19038C11.0001 6.36196
        11.8955 5.69038 13.0001 5.69038C14.1046 5.69038 15.0001 6.36196 15.0001 7.19038C14.4398 7.19038
        14.1773 7.36472 13.9623 7.50751C13.8132 7.60653 13.6869 7.69038 13.5001 7.69038ZM13.5001 6.74998C13.5001
        6.88805 13.3881 6.99998 13.2501 6.99998C13.112 6.99998 13.0001 6.88805 13.0001 6.74998C13.0001 6.61191
        13.112 6.49998 13.2501 6.49998C13.3881 6.49998 13.5001 6.61191 13.5001 6.74998ZM11.439 8.96248C11.2101
        9.8759 11.254 10.0111 11.427 10.5436C11.4848 10.7213 11.6035 10.8155 11.7438 10.9267C11.946 11.087 12.1929
        11.2828 12.3661 11.8156C13.1539 11.5597 13.5159 10.5005 13.1746 9.45001C12.8333 8.3995 11.9179
        7.75542 11.13 8.01142C10.9889 8.05725 11.0212 8.07476 11.1086 8.12218C11.2619 8.20539 11.5848
        8.38066 11.439 8.96248ZM12.3215 10.2529C12.1902 10.2956 12.0491 10.2237 12.0064 10.0924C11.9638
        9.96112 12.0356 9.82008 12.167 9.77742C12.2983 9.73475 12.4393 9.80661 12.482 9.93793C12.5246
        10.0692 12.4528 10.2103 12.3215 10.2529ZM9.58039 8.97655C8.64092 9.04108 8.52594 9.12462 8.07297
        9.45372C7.92178 9.56357 7.86893 9.70561 7.80651 9.87336C7.71651 10.1152 7.60662 10.4106 7.15334
        10.7399C7.64028 11.4101 8.75943 11.4271 9.65305 10.7779C10.5467 10.1286 10.8763 9.05898 10.3894
        8.38877C10.3022 8.26879 10.2956 8.30489 10.2775 8.40264C10.2457 8.57415 10.1788 8.93545 9.58039
        8.97655ZM8.62569 10.2145C8.54453 10.1028 8.56929 9.94646 8.681 9.8653C8.7927 9.78414 8.94904
        9.80891 9.0302 9.92061C9.11135 10.0323 9.08659 10.1887 8.97489 10.2698C8.86319 10.351 8.70684
        10.3262 8.62569 10.2145ZM7.52027 6.68784C7.60143 6.57614 7.75777 6.55138 7.86947 6.63253C7.98117
        6.71369 8.00593 6.87003 7.92478 6.98173C7.84362 7.09344 7.68728 7.1182 7.57558 7.03704C7.46388
        6.95589 7.43911 6.79954 7.52027 6.68784ZM10.5328 4.54663C10.6641 4.58929 10.736 4.73033 10.6933
        4.86164C10.6506 4.99296 10.5096 5.06482 10.3783 5.02215C10.247 4.97949 10.1751 4.83845 10.2178
        4.70714C10.2604 4.57582 10.4015 4.50396 10.5328 4.54663Z"
        fill="white"
      />
    </g>
  </svg>
);

HK.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

HK.defaultProps = {
  width: 21,
  height: 15,
};

export default HK;
