import { fetchOrderMethods } from 'common/api/settings/orderMethods';

export const ACTION_TYPES = {
  LOAD: 'orderMethods/LOAD',
  LOAD_SUCCESS: 'orderMethods/LOAD_SUCCESS',
  LOAD_ERROR: 'orderMethods/LOAD_ERROR',
};

const loadOrderMethodsAction = () => ({
  type: ACTION_TYPES.LOAD,
});

const loadOrderMethodsSuccess = data => ({
  type: ACTION_TYPES.LOAD_SUCCESS,
  payload: { data },
});

const loadOrderMethodsError = error => ({
  type: ACTION_TYPES.LOAD_ERROR,
  payload: { error },
});

export const loadOrderMethods = merchantId => async dispatch => {
  dispatch(loadOrderMethodsAction());
  try {
    const response = await fetchOrderMethods(merchantId);
    dispatch(loadOrderMethodsSuccess(response));
  } catch (error) {
    dispatch(loadOrderMethodsError(error));
  }
};
