import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'lodash/fp';
import { connect } from 'react-redux';
import TriangleIcon from 'common/components/Icons/Triangle';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import sendAnalytic from 'common/analytics';
import { isSuperUser as isSuperUserSelector } from 'common/auth/selectors';
import {
  getTabsList,
  getMerchantList,
  getPrimaryMerchantId,
} from './selectors';
import {
  setMerchant as setMerchantAction,
  clearMerchant as clearMerchantAction,
} from './actions';
import { updateTabURLAction } from '../NavigationBar/actions';
import { MULTISTORE_SUMMARY_PATH } from '../Portal/paths';
import { setMerchantForSuperUser as setMerchantForSuperUserAction } from '../Portal/actions';

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const StyledSelectInput = styled.select`
  padding: 10px 16px;
  line-height: 16px;
  font-size: ${props => props.theme.text.fontSize.body};
  background: ${props => props.theme.colours.grayDarker};
  position: relative;
  border-radius: 10px;
  border: none;
  color: ${props => props.theme.colours.white};
  height: 100%;
  width: 100%;
  appearance: none;
  &:focus {
    outline: none;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const allStoresKey = 'all_stores';

const MerchantFilter = ({
  tabs,
  merchants,
  primaryMerchantId,
  setMerchant,
  clearMerchant,
  history,
  isSuperUser,
  merchantList,
  setMerchantForSuperUser,
  updateTabURL,
}) => {
  const multistoreEnabled = isSuperUser || merchantList.length > 1;
  const { t } = useTranslation('multistore');
  const defaultValue =
    multistoreEnabled && history.location.pathname.includes('/overview')
      ? allStoresKey
      : primaryMerchantId;
  // super users should see the tabs on mobile picker,
  // while the regular user sees all the merchants they have access to
  const options = isSuperUser ? tabs : merchants;
  return (
    <Container>
      <StyledSelectInput
        name="merchants"
        id="merchants"
        defaultValue={defaultValue}
        onChange={e => {
          let url;
          const { value } = e.target;
          const newMerchantid = value;
          sendAnalytic(
            'pp_header',
            'Header',
            'RIT_select_store_switcher',
            `|${newMerchantid}`,
          );
          if (value === allStoresKey) {
            clearMerchant();
            history.push(
              `${MULTISTORE_SUMMARY_PATH}${history.location.search}`,
            );
            return;
          }

          if (isSuperUser) {
            setMerchantForSuperUser(newMerchantid);
            ({ url } = tabs.find(({ id }) => id === value));
          } else {
            setMerchant(newMerchantid);
            const {
              url: { name = 'merchant', id: urlId },
            } = merchants.find(({ id }) => id === value);
            const newPathArray = history.location.pathname.split('/');
            newPathArray[1] = name;
            newPathArray[2] = urlId;
            url = newPathArray.join('/');
          }
          updateTabURL(history.location.pathname);
          history.push(url);
        }}
        onClick={() => {
          sendAnalytic('pp_header', 'Header', 'RIT_click_store_switcher');
        }}
      >
        {multistoreEnabled && (
          <option value={allStoresKey}>{t('tabName')}</option>
        )}
        {options.map(({ id, name }) => (
          <option value={id} key={id}>
            {name}
          </option>
        ))}
      </StyledSelectInput>
      <ArrowWrapper>
        <TriangleIcon color="white" direction="down" />
      </ArrowWrapper>
    </Container>
  );
};

MerchantFilter.propTypes = {
  tabs: PropTypes.shape({
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        merchantId: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  merchants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isPrimary: PropTypes.bool,
    }),
  ).isRequired,
  setMerchant: PropTypes.func.isRequired,
  primaryMerchantId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isSuperUser: PropTypes.bool.isRequired,
  merchantList: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      id: PropTypes.string,
      url: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ),
};

MerchantFilter.defaultProps = {
  merchantList: [],
};

export default compose([
  connect(
    state => ({
      tabs: getTabsList(state),
      merchants: getMerchantList(state),
      primaryMerchantId: getPrimaryMerchantId(state),
      isSuperUser: isSuperUserSelector(state),
      merchantList: getMerchantList(state),
    }),
    {
      setMerchant: setMerchantAction,
      setMerchantForSuperUser: setMerchantForSuperUserAction,
      clearMerchant: clearMerchantAction,
      updateTabURL: updateTabURLAction,
    },
  ),
  withRouter,
])(MerchantFilter);
