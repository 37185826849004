import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import sendAnalytic from 'common/analytics';
import { Flag, Rating, QuestionMark, Alert } from '../Icons';

const transitionName = 'feedback';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${props => props.theme.colours.gray20};
  width: 23px;
  height: 23px;
  .${transitionName}-enter {
    opacity: 0.01;
  }
  .${transitionName}-enter.${transitionName}-enter-active {
    opacity: 1;
    transition: all 500ms ease-in;
  }
  .${transitionName}-exit {
    opacity: 1;
  }
  .${transitionName}-exit.${transitionName}-exit-active {
    opacity: 0.01;
    transition: all 500ms ease-out;
  }
`;

export const FeedbackWrapper = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colours.white};
  top: 30px;
  right: 0;
  z-index: 1000;
  box-shadow: 0px 7px 20px ${props => props.theme.colours.black20};
  width: 230px;
  padding: 18px;
  font-size: 16px;
  & > :not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Feedback = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 5px;
  }
`;

export const FeedbackAnalyticComponent = ({ feedbackAnalytic, t }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const {
    screen,
    category,
    usefulAction,
    notUsefulAction,
    questionAction,
    alertAction,
  } = feedbackAnalytic;
  const handleFeedbackAnalytic = () => {
    setShowFeedback(show => !show);
  };
  const handleSendFeedback = ({
    screen: analyticScreen,
    category: analyticCategory,
    action,
  }) => {
    sendAnalytic(analyticScreen, analyticCategory, action);
    // eslint-disable-next-line no-alert
    alert(t('feedback.recevied'));
  };
  return (
    <Wrapper onClick={handleFeedbackAnalytic}>
      <Flag />
      <CSSTransition
        in={showFeedback}
        timeout={{ enter: 500, exit: 300 }}
        classNames={transitionName}
        unmountOnExit
      >
        <FeedbackWrapper>
          <Feedback
            onClick={() =>
              handleSendFeedback({ screen, category, action: usefulAction })
            }
          >
            <Rating />
            <div>{t('feedback.useful')}</div>
          </Feedback>
          <Feedback
            onClick={() =>
              handleSendFeedback({
                screen,
                category,
                action: notUsefulAction,
              })
            }
          >
            <Rating direction="down" />
            <div>{t('feedback.notUseful')}</div>
          </Feedback>
          <Feedback
            onClick={() =>
              handleSendFeedback({ screen, category, action: questionAction })
            }
          >
            <QuestionMark />
            <div>{t('feedback.question')}</div>
          </Feedback>
          <Feedback
            onClick={() =>
              handleSendFeedback({ screen, category, action: alertAction })
            }
          >
            <Alert />
            <div>{t('feedback.alert')}</div>
          </Feedback>
        </FeedbackWrapper>
      </CSSTransition>
    </Wrapper>
  );
};

export const feedbackAnalyticPropTypes = PropTypes.shape({
  screen: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  usefulAction: PropTypes.string.isRequired,
  notUsefulAction: PropTypes.string.isRequired,
  questionAction: PropTypes.string.isRequired,
  alertAction: PropTypes.string.isRequired,
});

export const feedbackAnalyticDefaultProps = {
  screen: '',
  category: '',
  usefulAction: '',
  notUsefulAction: '',
  questionAction: '',
  alertAction: '',
};

FeedbackAnalyticComponent.propTypes = {
  t: PropTypes.func.isRequired,
  feedbackAnalytic: feedbackAnalyticPropTypes,
};

FeedbackAnalyticComponent.defaultProps = {
  feedbackAnalytic: feedbackAnalyticDefaultProps,
};

export default withTranslation('global')(FeedbackAnalyticComponent);
