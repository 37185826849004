import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { withRouter, NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from 'reactstrap';
import { compose } from 'lodash/fp';

import sendAnalytic from 'common/analytics';
import theme from 'common/style/theme';
import { mobile } from 'common/style/media';
import { logout } from 'common/auth/actions';
import { isSuperUser as isSuperUserSelector } from 'common/auth/selectors';
import profileIcon from 'common/assets/profileIcon.svg';
import DownArrow from 'common/components/Icons/DownArrow';
import Logo from 'common/components/Icons/Logo';
import HamburgerMenu from 'common/components/Icons/HamburgerMenu';
import { isLaunchpad } from 'common/components/Env';
import { getMerchantList } from '../MerchantFilter/selectors';
import {
  openSideNav as openSideNavAction,
  closeSideNav as closeSideNavAction,
} from '../SideNav/actions';
import { isSideNavOpen } from '../SideNav/selectors';
import MerchantFilter from '../MerchantFilter';
import MerchantSearch from '../MerchantFilter/MerchantSearch';
import NavigationTabs from './navigationTabs';
import { NON_MERCHANT_AUTHENTICATED_PATHS } from '../Portal/paths';

const {
  breakpoints: { mobileMax },
} = theme;

const StyledNavbar = styled(Navbar)`
  ${props =>
    props.$redBorderTop &&
    `border-top: 2px solid ${props.theme.colours.lighterRed}`};
  border-bottom: ${props =>
    !props.$overlay && `solid 1px ${props.theme.colours.grayLightish}`};
  height: ${props => props.theme.layout.topNav.height}px;
  background: ${props => props.theme.colours.blackDarker};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${props => props.theme.zIndex.navigationBar};

  ${mobile`
    height: ${props => props.theme.layout.topNav.mobileHeight}px;
    background: ${props => props.theme.colours.white};
    z-index: ${props => props.theme.zIndex.mobile.navigationBar};
  `}
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
`;

const NavbarContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 12px 0 0 12px;
`;

// DropdownMenu has `end` prop for this but isn't applying styles
const StyledDropdownMenu = styled(DropdownMenu)`
  right: 0;
  left: auto;
`;

const StyledDropdownItem = styled(DropdownItem)`
  cursor: pointer;
`;

const StyledDropdownToggle = styled(DropdownToggle)``;

const StyledNavBarCollapse = styled(Collapse)`
  flex-grow: 0;
`;

const NavbarLeft = styled.div`
  display: flex;
  flex: 1;
  margin-right: auto;
  > div:not(:first-child) {
    margin-left: 10px;
  }
`;

const NavbarRight = styled.div`
  flex: 1;
  margin-left: auto;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileIcon = styled.div`
  background: url(${profileIcon});
  width: 18px;
  height: 18px;
`;

const DownArrowWrapper = styled.div`
  margin-left: 9px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MobileNavContent = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  grid-column-gap: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 16px;
  background-color: ${props => props.theme.colours.blackDarker};
`;

const MenuWrapper = styled.div`
  cursor: pointer;
`;

class NavigationBar extends Component {
  shouldRenderSearchBar() {
    if (this.isNonMerchantPage()) {
      return null;
    }
    const { merchantList, isSuperUser } = this.props;
    if (isSuperUser || merchantList.length > 1) {
      return (
        <MerchantSearch isSuperUser={isSuperUser} merchantList={merchantList} />
      );
    }
    return null;
  }

  isNonMerchantPage() {
    const { location } = this.props;
    if (NON_MERCHANT_AUTHENTICATED_PATHS.includes(location.pathname)) {
      return true;
    }
    return false;
  }

  renderMobile = () => {
    const { openSideNav, closeSideNav, navState } = this.props;
    return (
      <MobileNavContent>
        <MenuWrapper
          data-testid="mobileNavBarBtn"
          onClick={() => {
            sendAnalytic(
              'pp_navigation',
              'Navigation (Mobile)',
              'RIT_click_mobile_hamburger',
              `|${window.location.href}`,
            );
            if (navState) {
              closeSideNav();
            } else {
              openSideNav();
            }
          }}
        >
          <HamburgerMenu />
        </MenuWrapper>
        {this.isNonMerchantPage() ? (
          <LogoWrapper>
            <StyledNavLink
              to="/"
              onClick={() => {
                sendAnalytic(
                  'pp_header',
                  'Profile Header (Mobile)',
                  'RIT_click_logo',
                  `|${window.location.href}`,
                );
              }}
            >
              <Logo color={theme.colours.white} width={120} />
            </StyledNavLink>
          </LogoWrapper>
        ) : (
          <MerchantFilter />
        )}
        {this.renderProfile()}
      </MobileNavContent>
    );
  };

  renderProfile = () => {
    const { t, history } = this.props;
    return (
      <StyledNavBarCollapse isOpen navbar>
        <Nav className="ml-auto">
          <UncontrolledDropdown nav inNavbar>
            <StyledDropdownToggle
              nav
              onClick={() => {
                sendAnalytic('pp_header', 'Header', 'RIT_click_profile');
              }}
            >
              <ProfileWrapper>
                <ProfileIcon data-testid="profileIcon" />
                <DownArrowWrapper>
                  <DownArrow color="white" />
                </DownArrowWrapper>
              </ProfileWrapper>
            </StyledDropdownToggle>
            <StyledDropdownMenu>
              <StyledDropdownItem
                data-testid="profileOption"
                onClick={() => {
                  history.push('/profile');
                }}
              >
                {t('profile')}
              </StyledDropdownItem>
              <StyledDropdownItem
                data-testid="logoutOption"
                onClick={() => {
                  sendAnalytic('pp_header', 'Header', 'RIT_click_logout');
                  logout();
                }}
              >
                {t('logOut')}
              </StyledDropdownItem>
            </StyledDropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </StyledNavBarCollapse>
    );
  };

  renderDesktop = () => (
    <Fragment>
      <NavbarContent>
        <NavbarLeft>
          <LogoWrapper>
            <StyledNavLink
              to="/"
              onClick={() => {
                sendAnalytic(
                  'pp_header',
                  'Header',
                  'RIT_click_logo',
                  `|${window.location.href}`,
                );
              }}
            >
              <Logo
                color={theme.colours.white}
                chevronColor={theme.colours.white}
                width={90}
              />
            </StyledNavLink>
          </LogoWrapper>
          {this.shouldRenderSearchBar()}
        </NavbarLeft>
        <NavbarRight>{this.renderProfile()}</NavbarRight>
      </NavbarContent>
      {!this.isNonMerchantPage() && <NavigationTabs />}
    </Fragment>
  );

  render() {
    const { overlay, className, isSuperUser } = this.props;
    return (
      <StyledNavbar
        $overlay={overlay}
        expand="md"
        className={className}
        $redBorderTop={isLaunchpad() && isSuperUser}
        container={null}
      >
        <MediaQuery maxWidth={mobileMax}>
          {isMobile => (isMobile ? this.renderMobile() : this.renderDesktop())}
        </MediaQuery>
      </StyledNavbar>
    );
  }
}

NavigationBar.propTypes = {
  t: PropTypes.func.isRequired,
  merchantList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openSideNav: PropTypes.func.isRequired,
  closeSideNav: PropTypes.func.isRequired,
  overlay: PropTypes.bool,
  navState: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isSuperUser: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

NavigationBar.defaultProps = {
  overlay: false,
  className: '',
};

export default compose([
  withTranslation('global'),
  withRouter,
  connect(
    state => ({
      merchantList: getMerchantList(state),
      navState: isSideNavOpen(state),
      isSuperUser: isSuperUserSelector(state),
    }),
    {
      openSideNav: openSideNavAction,
      closeSideNav: closeSideNavAction,
    },
  ),
])(NavigationBar);
