import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  transform: ${({ direction }) => {
    switch (direction) {
      case 'right':
        return 'rotate(-90deg)';
      case 'left':
        return 'rotate(90deg)';
      case 'up':
        return 'rotate(180deg)';
      case 'down':
      default:
        return '';
    }
  }};
`;

const Chevron = ({ color, width, height, direction, ...rest }) => (
  <StyledSvg
    {...rest}
    direction={direction}
    width={width}
    height={height}
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.42367 0.840088L6.01367 5.42009L10.6037 0.840088L12.0137
      2.25009L6.01367 8.25009L0.0136719 2.25009L1.42367 0.840088Z"
      fill={color}
    />
  </StyledSvg>
);

Chevron.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  direction: PropTypes.oneOf(['right', 'left', 'up', 'down']),
};

Chevron.defaultProps = {
  color: 'black',
  width: 13,
  height: 9,
  direction: 'right',
};

export default Chevron;
