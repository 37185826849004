/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const MagnifyingGlass = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.739 11.4155H11.949L11.669 11.1455C12.649 10.0055 13.239 8.52553 13.239 6.91553C13.239 3.32553 10.329 0.415527 6.73901 0.415527C3.14901 0.415527 0.239014 3.32553 0.239014 6.91553C0.239014 10.5055 3.14901 13.4155 6.73901 13.4155C8.34901 13.4155 9.82901 12.8255 10.969 11.8455L11.239 12.1255V12.9155L16.239 17.9055L17.729 16.4155L12.739 11.4155ZM6.73901 11.4155C4.24901 11.4155 2.23901 9.40553 2.23901 6.91553C2.23901 4.42553 4.24901 2.41553 6.73901 2.41553C9.22901 2.41553 11.239 4.42553 11.239 6.91553C11.239 9.40553 9.22901 11.4155 6.73901 11.4155Z"
      fill={color}
    />
  </svg>
);

MagnifyingGlass.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

MagnifyingGlass.defaultProps = {
  color: 'black',
  size: '18',
};

export default MagnifyingGlass;
