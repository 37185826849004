import axios from 'axios';
import * as Sentry from '@sentry/browser';

// eslint-disable-next-line import/no-cycle
import { logout } from '../auth/actions';
import statusCodes from './statusCodes';
import { getToken } from '../storage';

export const apiVersionInterceptor = config => {
  const { baseURL, prefixWithVersion = true } = config;
  const newConfig = config;
  if (prefixWithVersion) {
    newConfig.baseURL = `${baseURL}/v1`;
  }
  return newConfig;
};

export const reqInterceptor = config => {
  const token = getToken();
  const newConfig = config;
  if (config.isAuthReq && token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
};

export const getResponse = response => response;

export const getError = error => {
  Sentry.captureException(error);
  if (error.response && error.response.status === statusCodes.UNAUTHORIZED) {
    if (
      !error.config.url.includes('auth/v1/login') &&
      !error.config.url.includes('auth/v1/oneTimeToken')
    ) {
      logout({ token: 'expired' });
    }
  }
  return Promise.reject(error);
};

let backendURL = process.env.REACT_APP_BASE_API_URL;
if (
  ['development', 'sandbox', 'qa'].includes(process.env.REACT_APP_STAGE_ENV)
) {
  const searchParams = new URLSearchParams(window.location.search);
  backendURL = searchParams.get('server_backend_url') || backendURL;
}

// API client for new Spring endpoints
const apiClient = axios.create({
  baseURL: `${backendURL}/api`,
  isAuthReq: true,
});

export const loggerClient = axios.create({
  baseURL: process.env.REACT_APP_RA_URL_V3,
  isAuthReq: false,
});

apiClient.interceptors.request.use(apiVersionInterceptor);
apiClient.interceptors.request.use(reqInterceptor);
loggerClient.interceptors.request.use(reqInterceptor);

apiClient.interceptors.response.use(getResponse, getError);
loggerClient.interceptors.response.use(getResponse, getError);

export default apiClient;
