import { includes, merge, take } from 'lodash';

import createReducer from 'common/utils/createReducer';
import { DateRange } from './constants';

export const INITIAL_STATE = {
  isFetching: false,
  error: null,
  compareWithPriorYear: false,
  compareWithOtherLocations: false,
  dateRange: DateRange.CURRENT_WEEK,
  merchants: {},
  chartedLocationIds: [],
  currencyCode: 'USD',
};

const getSalesSummaryRequest = state => ({
  ...state,
  isFetching: true,
  error: null,
});

const getSalesSummarySuccess = (state, { merchants, currencyCode }) => ({
  ...state,
  isFetching: false,
  merchants: merge({}, state.merchants, merchants),
  currencyCode,
});

const getSalesSummaryFailure = (state, error) => ({
  ...state,
  isFetching: false,
  error,
});

const toggleCompareWithPriorYear = state => ({
  ...state,
  compareWithPriorYear: !state.compareWithPriorYear,
  compareWithOtherLocations: false,
});

const toggleCompareWithOtherLocations = (state, { otherMerchantIds }) => ({
  ...state,
  compareWithOtherLocations: !state.compareWithOtherLocations,
  compareWithPriorYear: false,
  chartedLocationIds: !state.compareWithOtherLocations
    ? take(otherMerchantIds)
    : [],
});

const setDateRange = (state, { dateRange }) => ({
  ...state,
  dateRange,
});

const toggleLocationCharted = (state, { merchantId }) => ({
  ...state,
  chartedLocationIds: includes(state.chartedLocationIds, merchantId)
    ? state.chartedLocationIds.filter(id => id !== merchantId)
    : [...state.chartedLocationIds, merchantId],
});

const resizeToMobile = state => ({
  ...state,
  compareWithOtherLocations: false,
  chartedLocationIds: [],
  dateRange: DateRange.CURRENT_WEEK,
});

const resetChartedLocations = state => ({
  ...state,
  chartedLocationIds: [],
});

const reducers = {
  'salesSummary/GET_SALES_SUMMARY_REQUEST': getSalesSummaryRequest,
  'salesSummary/GET_SALES_SUMMARY_SUCCESS': getSalesSummarySuccess,
  'salesSummary/GET_SALES_SUMMARY_FAILURE': getSalesSummaryFailure,
  'salesSummary/TOGGLE_COMPARE_WITH_PRIOR_YEAR': toggleCompareWithPriorYear,
  'salesSummary/TOGGLE_COMPARE_WITH_OTHER_LOCATIONS': toggleCompareWithOtherLocations,
  'salesSummary/SET_DATE_RANGE': setDateRange,
  'salesSummary/TOGGLE_LOCATION_CHARTED': toggleLocationCharted,
  'salesSummary/RESIZE_TO_MOBILE': resizeToMobile,
  'merchantFilter/SET_MERCHANT_SUCCESS': resetChartedLocations,
};

export default createReducer(INITIAL_STATE, reducers);
