/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserLocale } from '../../../../selectors';

export const LogoComponent = ({
  width,
  height = Math.ceil(width / 4),
  color,
  chevronColor,
  userLocale,
  className,
}) => {
  if (userLocale === 'zh-HK') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 1157 295"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <rect
          x="0.23999"
          y="0.619995"
          width="293.92"
          height="293.92"
          rx="71.02"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1147.83 115.37H1104.5V22.72H1081.09V136.09H1156.02L1147.83 115.37Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M961.66 21.5H988.59L1040.11 136.09H1015.52L1006.15 116.59H944.1L934.73 136.09H910.14L961.66 21.5ZM975.71 44.66L952.29 98.3H999.13L975.71 44.66Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M457.02 20.28C479.27 21.5 495.66 41.01 495.66 64.17C495.66 83.67 485.12 99.52 468.73 104.4L489.84 134.87H462.91L443 106.87H412.53V134.87H389.11V20.28H457.02ZM448.83 89.77C461.71 89.77 471.07 78.8 471.07 65.39V64.17C469.9 50.76 461.71 42.23 448.83 42.23H411.36V89.77H448.83Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M852.77 84.89C852.77 101.96 839.89 115.37 823.5 115.37C807.11 115.37 794.23 102 794.23 84.89V23.94H772V84.89C772 114.15 795.42 137.31 823.52 137.31C851.62 137.31 875 114.15 875 84.89V23.94H852.77V84.89Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M625.63 45.88H663.09V134.87H686.51V45.88H723.98V23.94H625.63V45.88Z"
          fill={color}
        />
        <rect x="550.69" y="22.72" width="23.42" height="112.15" fill={color} />
        <path
          opacity="0.8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.7 122.61L190.7 99.42C189.675 98.3388 188.25 97.7264 186.76 97.7264C185.27 97.7264 183.845 98.3388 182.82 99.42L147.23 135.22L111.65 99.42C110.623 98.3382 109.197 97.7257 107.705 97.7257C106.213 97.7257 104.787 98.3382 103.76 99.42L80.76 122.61C79.6808 123.649 79.0709 125.082 79.0709 126.58C79.0709 128.078 79.6808 129.511 80.76 130.55L147.25 197.43L213.75 130.55C214.823 129.504 215.424 128.067 215.414 126.569C215.405 125.071 214.786 123.642 213.7 122.61Z"
          fill={chevronColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.2 135.22L116.28 166.33L147.2 197.44L178.12 166.33L147.2 135.22Z"
          fill={chevronColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M483.05 161.21H404.38C393.45 161.21 388.38 165.56 388.38 174.91V264C388.38 271.94 393.38 276.88 401.38 276.88H480C489.54 276.88 494.39 274.88 494.39 263.72V174.23C494.43 165.59 490.6 161.21 483.05 161.21ZM482.24 229.76H400.68V177.38C400.68 174.12 401.87 172.85 404.93 172.85H478C481.06 172.85 482.26 174 482.26 176.97L482.24 229.76ZM482.24 241.54H400.68V260.85C400.68 263.96 402.55 265.23 407.12 265.23H478.94C481.24 265.23 482.24 264.18 482.24 261.8V241.54Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M557.57 261.8H616.12C617.85 261.8 619.26 260.8 619.26 259.5V256.5C619.26 255.23 617.85 254.2 616.12 254.2H557.57C555.83 254.2 554.43 255.2 554.43 256.5V259.5C554.43 260.77 555.83 261.8 557.57 261.8Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M639.92 242.59V246.41V266.49C639.92 273.35 635.8 277.13 628.31 277.13H545.98C537.87 277.13 533.76 273.92 533.76 267.59V242.59C533.781 241.783 534.125 241.019 534.716 240.47C535.307 239.92 536.094 239.632 536.9 239.67H636.81C638.345 239.625 639.675 240.724 639.92 242.24C639.93 242.356 639.93 242.474 639.92 242.59ZM625.62 267.31C627.19 267.31 627.72 266.68 627.72 264.82V249.32H545.96V265.09C545.96 266.19 546.41 267.31 549.41 267.31H625.62Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M552.09 181.14H620.25C627.77 181.14 631.12 184.25 631.12 191.23V194.7C631.12 201.18 628.05 204.18 621.44 204.18H552.54C546.31 204.18 543.16 201.24 543.16 195.45V188.96C543.16 183.55 545.91 181.14 552.09 181.14ZM619.97 193.8V192.14L620 192.13C620 190.88 619.48 190.15 617 190.14H556.7C554.99 190.14 554.29 190.74 554.29 192.14V193.67C554.282 193.739 554.274 193.803 554.267 193.864C554.215 194.284 554.184 194.536 554.42 194.78C554.69 195.06 555.28 195.18 556.42 195.18H616.67C619.97 195.18 619.97 194.48 619.97 193.8Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M631 223.45H555.15C552.15 223.45 551.7 222.33 551.7 221.23V218.38H621.54C623.131 218.38 624.42 217.091 624.42 215.5V213.6C624.42 212.009 623.131 210.72 621.54 210.72H551.7C551.679 209.913 551.335 209.149 550.744 208.6C550.153 208.05 549.366 207.762 548.56 207.8H542.65C541.844 207.762 541.057 208.05 540.466 208.6C539.875 209.149 539.531 209.913 539.51 210.72V223.72C539.51 230.05 543.62 233.26 551.72 233.26H631C631.806 233.298 632.593 233.01 633.184 232.46C633.775 231.911 634.119 231.147 634.14 230.34V226.34C634.112 225.539 633.763 224.782 633.174 224.239C632.584 223.696 631.801 223.412 631 223.45Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M588.13 152.63H587.68C584.438 152.63 581.81 155.258 581.81 158.5V169.86C581.81 171.517 583.153 172.86 584.81 172.86H591C592.657 172.86 594 171.517 594 169.86V158.5C594 155.258 591.372 152.63 588.13 152.63Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M568.54 158.08C566.719 155.344 563.115 154.443 560.22 156C558.809 156.733 557.766 158.018 557.34 159.55C556.961 160.978 557.193 162.5 557.98 163.75L564.28 173.86C564.867 174.767 565.88 175.308 566.96 175.29C567.497 175.291 568.025 175.161 568.5 174.91L573.71 172.12C574.426 171.749 574.955 171.097 575.17 170.32C575.359 169.595 575.24 168.824 574.84 168.19L568.54 158.08Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M618.31 159.55C617.884 158.018 616.841 156.733 615.43 156C612.535 154.443 608.931 155.344 607.11 158.08L600.81 168.19C600.409 168.824 600.293 169.597 600.49 170.32C600.699 171.097 601.225 171.751 601.94 172.12L607.16 174.91C607.63 175.162 608.156 175.293 608.69 175.29C609.77 175.308 610.783 174.767 611.37 173.86L617.67 163.75C618.457 162.5 618.689 160.978 618.31 159.55Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M645.41 167.62H528.88C525.606 167.502 522.855 170.056 522.73 173.33V191.39C522.751 192.197 523.095 192.961 523.686 193.51C524.277 194.06 525.064 194.348 525.87 194.31H531.49C532.296 194.348 533.083 194.06 533.674 193.51C534.265 192.961 534.609 192.197 534.63 191.39V177.29H639.63V191.39C639.651 192.197 639.995 192.961 640.586 193.51C641.177 194.06 641.964 194.348 642.77 194.31H648.39C649.196 194.348 649.983 194.06 650.574 193.51C651.165 192.961 651.509 192.197 651.53 191.39V173.33C651.374 170.083 648.66 167.551 645.41 167.62Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 114 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M89.1418 11.4896H84.096V1.06519H81.3703V13.8304H90.0989L89.1418 11.4896Z"
          fill={color}
        />
        <path
          d="M66.3787 9.5753L69.1045 3.54123L71.8302 9.5753H66.3787ZM70.6026 0.929932H67.4711L61.4683 13.82H64.3292L65.4216 11.6248H72.6521L73.7445 13.82H76.6055L70.6026 0.929932Z"
          fill={color}
        />
        <path
          d="M10.3348 5.87162C10.3348 7.38014 9.24238 8.61816 7.74426 8.61816H3.38517V3.94696V3.81171V3.26032H7.74426C9.24238 3.26032 10.1995 4.21745 10.3348 5.72597C10.3348 5.73637 10.3348 5.73637 10.3348 5.87162C10.3348 5.73637 10.3348 5.73637 10.3348 5.87162ZM13.2061 5.73637C13.2061 3.12508 11.2919 0.929924 8.70139 0.794678H8.56615H0.794678V13.6847H3.52041V10.5324H7.06803L9.38803 13.6847H12.5195L10.0643 10.2619C11.9785 9.71054 13.2061 7.93153 13.2061 5.73637Z"
          fill={color}
        />
        <path
          d="M54.7894 8.06683C54.7894 9.99149 53.2913 11.5 51.377 11.5C49.4628 11.5 47.9646 9.99149 47.9646 8.06683V1.21088H45.3741V8.06683C45.3741 11.3544 48.0999 13.9657 51.377 13.9657C54.6541 13.9657 57.3799 11.3544 57.3799 8.06683V1.21088H54.7894V8.06683Z"
          fill={color}
        />
        <path
          d="M28.3329 3.67652H32.7024V13.6848H35.4281V3.67652H39.7872V1.21088H28.3329V3.67652Z"
          fill={color}
        />
        <path
          d="M22.3405 1.06519H19.6148V13.6847H22.3405V1.06519Z"
          fill={color}
        />
        <path
          opacity="0.8"
          d="M113.008 4.76885L110.22 1.95989C109.949 1.6894 109.533 1.6894 109.262 1.95989L104.955 6.28777L100.648 1.95989C100.378 1.6894 99.9616 1.6894 99.6911 1.95989L96.9029 4.76885C96.6325 5.03935 96.6325 5.45549 96.9029 5.72598L104.945 13.82L112.987 5.72598C113.278 5.45549 113.278 5.04975 113.008 4.76885Z"
          fill={chevronColor}
        />
        <path
          d="M104.955 6.29822L101.22 10.0643L104.955 13.8304L108.7 10.0643L104.955 6.29822Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="112.411"
            height="13.1709"
            fill={color}
            transform="translate(0.794678 0.794678)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

LogoComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  chevronColor: PropTypes.string,
  userLocale: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LogoComponent.defaultProps = {
  width: 170,
  color: '#fff',
  chevronColor: '#232222',
};

LogoComponent.displayName = 'Logo';

export default connect(state => ({
  userLocale: getUserLocale(state),
}))(LogoComponent);
