import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingSpinner } from '@ritualco/ritual-frontend-components';
import theme from 'common/style/theme';

const LoadingScreenContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 10%;
`;

const LoadingCardContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingCard = ({ size, ...rest }) => (
  <LoadingCardContainer data-testid="loading-card" {...rest}>
    <LoadingSpinner size={size} colour={theme.colours.black} />
  </LoadingCardContainer>
);

LoadingCard.propTypes = {
  size: PropTypes.number,
};

LoadingCard.defaultProps = {
  size: 36,
};

const LoadingScreen = ({ className, size, ...props }) => (
  <LoadingScreenContainer
    className={className}
    datatest-id="loading-screen"
    {...props}
  >
    <LoadingSpinner size={size} colour={theme.colours.black} />
  </LoadingScreenContainer>
);

LoadingScreen.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

LoadingScreen.defaultProps = {
  className: null,
  size: 48,
};

export default LoadingScreen;
