import api from 'common/api/apiClient';

export const METRO_LIST_REQUEST = 'settings/METRO_LIST_REQUEST';
export const METRO_LIST_SUCCESS = 'settings/METRO_LIST_SUCCESS';
export const METRO_LIST_FAILURE = 'settings/METRO_LIST_FAILURE';

export const ZONE_LIST_REQUEST = 'settings/ZONE_LIST_REQUEST';
export const ZONE_LIST_SUCCESS = 'settings/ZONE_LIST_SUCCESS';
export const ZONE_LIST_FAILURE = 'settings/ZONE_LIST_FAILURE';

export const MERCHANT_STATE_REQUEST = 'settings/MERCHANT_STATE_REQUEST';
export const MERCHANT_STATE_SUCCESS = 'settings/MERCHANT_STATE_SUCCESS';
export const MERCHANT_STATE_FAILURE = 'settings/MERCHANT_STATE_FAILURE';

export const MERCHANT_STATE_FOR_LAUNCH_REQUEST =
  'settings/MERCHANT_STATE_FOR_LAUNCH_REQUEST';
export const MERCHANT_STATE_FOR_LAUNCH_SUCCESS =
  'settings/MERCHANT_STATE_FOR_LAUNCH_SUCCESS';
export const MERCHANT_STATE_FOR_LAUNCH_FAILURE =
  'settings/MERCHANT_STATE_FOR_LAUNCH_FAILURE';

export const SET_SUCCESS_MESSAGE = 'settings/SET_SUCCESS_MESSAGE';
export const CLEAR_SUCCESS_MESSAGE = 'settings/CLEAR_SUCCESS_MESSAGE';
export const MERCHANT_ONBOARDING_STEP = 'settings/MERCHANT_ONBOARDING_STEP';

const SUCCESS_MESSAGE_TIMEOUT = 5000;

const metroListRequest = () => ({
  type: METRO_LIST_REQUEST,
});

const metroListSuccess = data => ({
  type: METRO_LIST_SUCCESS,
  payload: data,
});

const metroListFailure = error => ({
  type: METRO_LIST_FAILURE,
  payload: error,
});

const zoneListRequest = () => ({
  type: ZONE_LIST_REQUEST,
});

const zoneListSuccess = data => ({
  type: ZONE_LIST_SUCCESS,
  payload: data,
});

const zoneListFailure = error => ({
  type: ZONE_LIST_FAILURE,
  payload: error,
});

const setSuccessMessage = message => ({
  type: SET_SUCCESS_MESSAGE,
  payload: message,
});

const clearSuccessMessage = () => ({
  type: CLEAR_SUCCESS_MESSAGE,
});

const merchantStateRequest = () => ({
  type: MERCHANT_STATE_REQUEST,
});

const merchantStateSuccess = data => ({
  type: MERCHANT_STATE_SUCCESS,
  payload: data,
});

const merchantStateFailure = error => ({
  type: MERCHANT_STATE_FAILURE,
  payload: error,
});

const merchantStateForLaunchRequest = () => ({
  type: MERCHANT_STATE_FOR_LAUNCH_REQUEST,
});

const merchantStateForLaunchSuccess = data => ({
  type: MERCHANT_STATE_FOR_LAUNCH_SUCCESS,
  payload: data,
});

const merchantStateForLaunchFailure = error => ({
  type: MERCHANT_STATE_FOR_LAUNCH_FAILURE,
  payload: error,
});

function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export const setOnboardingStep = step => ({
  type: MERCHANT_ONBOARDING_STEP,
  payload: step,
});

export const displaySuccessMessage = message => async dispatch => {
  dispatch(setSuccessMessage(message));
  await sleep(SUCCESS_MESSAGE_TIMEOUT);
  dispatch(clearSuccessMessage());
};

const getErrorStatus = err =>
  err.response && err.response.status ? err.response.status : 500;

export const fetchMetroList = () => async dispatch => {
  dispatch(metroListRequest());
  try {
    const response = await api.get('/metro');
    return dispatch(metroListSuccess(response.data.metros));
  } catch (err) {
    return dispatch(metroListFailure(getErrorStatus(err)));
  }
};

export const fetchZoneList = () => async dispatch => {
  dispatch(zoneListRequest());
  try {
    const response = await api.get('/zone');
    return dispatch(zoneListSuccess(response.data.zones));
  } catch (err) {
    return dispatch(zoneListFailure(getErrorStatus(err)));
  }
};

export const fetchMerchantState = ({
  merchantId,
  handleError,
}) => async dispatch => {
  dispatch(merchantStateRequest());
  try {
    const { data } = await api.get(`/merchants/${merchantId}/state`);
    return dispatch(merchantStateSuccess(data));
  } catch (error) {
    if (handleError) handleError(error);
    return dispatch(merchantStateFailure(error));
  }
};

export const fetchMerchantStateForLaunch = ({
  merchantId,
  handleError,
}) => async dispatch => {
  dispatch(merchantStateForLaunchRequest());
  try {
    const { data } = await api.get(
      `/merchants/${merchantId}/state?previewChangeTo=ON`,
    );
    return dispatch(merchantStateForLaunchSuccess(data));
  } catch (error) {
    if (handleError) handleError(error);
    return dispatch(merchantStateForLaunchFailure(error));
  }
};
