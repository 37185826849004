import {
  EDIT_THEME,
  RESET_TO_DEFAULT_FAILURE,
  RESET_TO_DEFAULT_SUCCESS,
  RESET_TO_DEFAULT_REQUEST,
  REVERT_THEME_CHANGES,
  PUBLISH_REQUEST,
  PUBLISH_SUCCESS,
  PUBLISH_FAILURE,
  NAVIGATE_AWAY,
} from './actions';

export const INITIAL_STATE = {
  selectedGroup: null,
  selectedGroupUpdate: null,
  isPublishing: false,
  isResettingThemeToDefault: false,
  isLoadingBrandGroup: false,
  isShowingSuccessBanner: false,
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EDIT_THEME:
      return {
        ...state,
        themeUpdate: {
          ...state.themeUpdate,
          ...action.payload,
        },
        isShowingSuccessBanner: false,
      };
    case REVERT_THEME_CHANGES:
      return {
        ...state,
        themeUpdate: null,
        isShowingSuccessBanner: false,
      };
    case PUBLISH_REQUEST:
      return {
        ...state,
        isPublishing: true,
        isShowingSuccessBanner: false,
      };
    case PUBLISH_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        isShowingSuccessBanner: true,
        themeUpdate: null,
      };
    case PUBLISH_FAILURE:
      return {
        ...state,
        isPublishing: false,
        isShowingSuccessBanner: false,
        error: action.payload,
      };
    case RESET_TO_DEFAULT_REQUEST:
      return {
        ...state,
        isShowingSuccessBanner: false,
        isResettingThemeToDefault: true,
      };
    case RESET_TO_DEFAULT_SUCCESS:
      return {
        ...state,
        isShowingSuccessBanner: true,
        isResettingThemeToDefault: false,
        themeUpdate: null,
      };
    case RESET_TO_DEFAULT_FAILURE:
      return {
        ...state,
        isShowingSuccessBanner: false,
        isResettingThemeToDefault: false,
      };
    case NAVIGATE_AWAY:
      return {
        ...state,
        themeUpdate: null,
      };

    default:
      return state;
  }
};

export default settingsReducer;
