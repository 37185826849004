import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import sendAnalytic from 'common/analytics';
import {
  track,
  logRitualCoreEvent,
  eventParam,
} from 'common/analytics/analytics';
import {
  EVENT_ACTION_PORTAL_SIDE_NAV_CLICK,
  EVENT_V3_PARTNERPORTAL_SIDEBAR_CLICK,
} from 'common/analytics/eventTypes';
import { isSuperUser as isSuperUserSelector } from 'common/auth/selectors';
import NavItem from 'common/components/NavItem';
import { Subtitle2 } from 'common/style/theme';
import { getCurrentMerchant } from 'core/MerchantFilter/selectors';
import { closeSideNav } from './actions';
import { BackIcon } from './icons';
import { useNavList } from './utils';

const NavListWrapper = styled.div`
  position: relative;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const ActiveIndicator = styled.div`
  background-color: ${props => props.theme.rtColors.teal500};
  border-radius: 0 6px 6px 0;
  height: 56px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: ${props => `${props.$position * 56}px`};
  width: 5px;
`;

const BackButtonWrapper = styled.div`
  padding: 4px 24px 16px;
`;

const BackButton = styled(NavLink)`
  align-items: center;
  background-color: ${props => props.theme.colours.blackDarker};
  border-radius: 40px;
  color: ${props => props.theme.colours.white};
  display: inline-flex;
  padding-right: 12px;
  &:hover {
    color: ${props => props.theme.colours.white};
    text-decoration: none;
  }
`;

const GroupLabel = styled.div`
  font-size: ${props => props.theme.text.fontSize.small};
  font-weight: bold;
  color: ${props => props.theme.colours.ui3};
  padding: ${props => props.children && '16px 26px'};
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 1.5px;
  line-height: 24px;
`;

const NavList = ({
  navList: inputNavList = [],
  showBackButton = false,
  isTrialExpired = false,
}) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const currentMerchant = useSelector(getCurrentMerchant);
  const isSuperUser = useSelector(isSuperUserSelector);
  const { t } = useTranslation('sideNav');
  const { navItemsByGroup, groups, active } = useNavList(inputNavList);

  const getBasePath = () => {
    if (match.params.merchantName && match.params.merchantUrl) {
      return `/${match.params.merchantName}/${match.params.merchantUrl}`;
    }
    if (currentMerchant) {
      const {
        merchantUrl: { normalizedName, urlId },
      } = currentMerchant;
      return `/${normalizedName}/${urlId}`;
    }
    return '';
  };

  const merchantId = currentMerchant ? currentMerchant.merchantId : '';
  const trialExpiredLabels = ['support', 'settings'];

  return (
    <>
      {showBackButton && (
        <BackButtonWrapper>
          <BackButton
            to={getBasePath()}
            onClick={() =>
              sendAnalytic(
                'pp_navigation',
                'pp_navigation',
                'RIT_click_back',
                `|${window.location.href}`,
              )
            }
          >
            <BackIcon />
            <Subtitle2>{t('back')}</Subtitle2>
          </BackButton>
        </BackButtonWrapper>
      )}
      <NavListWrapper>
        {active && <ActiveIndicator $position={active.position} />}
        {groups.map(group => (
          <Fragment key={`navList${group}`}>
            {group && <GroupLabel>{t(`groups.${group}`)}</GroupLabel>}
            {navItemsByGroup[group].map(
              ({
                label: navLabel,
                to,
                icon,
                exact,
                merchantPath,
                analytics: { category, screen, action, label },
                dataTestId,
                showNewTag,
              }) => (
                <NavItem
                  key={navLabel}
                  data-testid={dataTestId}
                  to={merchantPath ? `${getBasePath()}${to}` : to}
                  label={t(navLabel)}
                  iconComponent={icon}
                  isActive={active && active.label === navLabel}
                  showNewTag={showNewTag}
                  exact={exact}
                  disabled={
                    isTrialExpired && !trialExpiredLabels.includes(navLabel)
                  }
                  onClick={() => {
                    track(EVENT_ACTION_PORTAL_SIDE_NAV_CLICK, {
                      NAV_LABEL: navLabel,
                    });
                    sendAnalytic(category, screen, action, label);
                    logRitualCoreEvent({
                      eventAction: EVENT_V3_PARTNERPORTAL_SIDEBAR_CLICK,
                      eventParams: [
                        eventParam('MERCHANT_ID', merchantId),
                        eventParam('CATEGORY', navLabel),
                      ],
                      isSuperUser,
                    });
                    dispatch(closeSideNav());
                  }}
                />
              ),
            )}
          </Fragment>
        ))}
      </NavListWrapper>
    </>
  );
};

NavList.propTypes = {
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
    }),
  ),
  showBackButton: PropTypes.bool,
  isTrialExpired: PropTypes.bool,
};

export default NavList;
