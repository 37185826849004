import createReducer from 'common/utils/createReducer';
import { setItem, getItem } from 'common/storage';

const INITIAL_STATE = {
  tabs: [],
};

try {
  const { tabs } = getItem('tabs');
  INITIAL_STATE.tabs = tabs;
} catch (e) {
  // do not worry about errors.
}

const setTabs = (state, { tabs }) => {
  const newState = {
    ...state,
    tabs,
  };
  setItem('tabs', newState);
  return newState;
};

const reducers = {
  'navigationTabs/SET_TABS': setTabs,
};

export default createReducer(INITIAL_STATE, reducers);
