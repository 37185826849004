import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Container, Row, Col } from 'reactstrap';
import Footer from 'core/Footer';
import theme from 'common/style/theme';
import { desktop, mobile } from 'common/style/media';
import NavigationBar from '../NavigationBar';
import FakeSideNav from '../SideNav/FakeSideNav';
import { RepeatComponent } from '../../common/utils';
import {
  EmptyWidget,
  EmptyGraphWidget,
  EmptyPayroll,
} from '../../common/components/Widget';

const {
  layout: { sideNav },
  breakpoints: { desktopMin },
} = theme;

const LayoutWrapper = styled.div`
  min-height: 100vh;
  position: relative;
`;

const RowWrapper = styled.div`
  padding: 10px 32px;
`;

const StyledContainer = styled(Container)`
  &&& {
    margin-top: ${props => props.theme.layout.topNav.height}px;
    padding: 0;
    min-height: 100vh;
    background-color: ${props => props.theme.colours.background};
    ${mobile`
      margin-top: ${props => props.theme.layout.topNav.mobileHeight}px;
    `}
    ${desktop`padding-left: ${sideNav.expandedWidth}px;`}
  }
`;

const FooterWrapper = styled.div`
  background-color: ${props => props.theme.colours.background};
  ${desktop`padding-left: ${sideNav.expandedWidth}px;`}
`;

const LayoutForModal = ({ children }) => (
  <MediaQuery minWidth={desktopMin}>
    {matches => {
      if (matches) {
        return (
          <LayoutWrapper>
            <NavigationBar overlay showPlaceholderTab />
            <FakeSideNav />
            <StyledContainer fluid>
              <RowWrapper>
                <Row>
                  <Col md="12">{children}</Col>
                </Row>
                <Row>
                  <Col md="12">
                    <EmptyPayroll />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <EmptyGraphWidget />
                  </Col>
                </Row>
                <RepeatComponent
                  times={5}
                  render={idx => (
                    <Row key={idx}>
                      <Col md="6">
                        <EmptyWidget />
                      </Col>
                      <Col md="6">
                        <EmptyWidget />
                      </Col>
                    </Row>
                  )}
                />
              </RowWrapper>
            </StyledContainer>
            <FooterWrapper>
              <Footer showImage={false} />
            </FooterWrapper>
          </LayoutWrapper>
        );
      }
      return children;
    }}
  </MediaQuery>
);

LayoutForModal.propTypes = {
  children: PropTypes.node,
};

LayoutForModal.defaultProps = {
  children: <div />,
};

export default LayoutForModal;
