import apiClient from '../apiClient';

export const ORDER_METHODS = {
  EMAIL: 'EMAIL',
  DEVICE: 'DEVICE',
  POS: 'POS',
  UNSUPPORTED_TYPE: 'UNSUPPORTED_TYPE',
};

export const fetchOrderMethods = async merchantId => {
  const endpoint = `/merchants/${merchantId}/order-methods`;

  const { data } = await apiClient.get(endpoint);
  return data;
};

export const switchOrderMethodType = async ({ merchantId, type }) => {
  const endpoint = `/merchants/${merchantId}/order-methods/type`;

  const { data } = await apiClient.put(endpoint, {
    selectedOrderMethodType: type,
  });
  return data;
};

export const linkEmailOrderMethod = async ({ merchantId, email }) => {
  const endpoint = `/merchants/${merchantId}/order-methods/email`;

  const { data } = await apiClient.post(endpoint, { email });
  return data;
};

export const unlinkEmailOrderMethod = async ({ merchantId, emailId }) => {
  const endpoint = `/merchants/${merchantId}/order-methods/email/${emailId}`;

  const { data } = await apiClient.delete(endpoint);
  return data;
};
