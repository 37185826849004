import createReducer from 'common/utils/createReducer';
import { MenuActionTypes } from '../constants';

export const INITIAL_STATE = {
  liveMenu: {
    loading: false,
    updating: false,
    data: null,
    error: null,
  },
  avalaraTaxRates: {
    loading: false,
    data: null,
    error: null,
  },
  isAvalaraEnabled: {
    loading: false,
    data: null,
    error: null,
  },
  sharedMerchants: [],
  form: {
    isDirty: false,
  },
};

const loadingLiveMenu = state => ({
  ...state,
  liveMenu: {
    ...state.liveMenu,
    loading: true,
  },
});

const updatingLiveMenu = state => ({
  ...state,
  liveMenu: {
    ...state.liveMenu,
    updating: true,
  },
});

const loadLiveMenuError = (state, payload) => ({
  ...state,
  liveMenu: {
    ...state.liveMenu,
    loading: false,
    updating: false,
    error: payload.error,
  },
});

const loadLiveMenuSuccess = (state, payload) => ({
  ...state,
  liveMenu: {
    data: payload.data,
    loading: false,
    updating: false,
    error: null,
  },
});

const loadingAvalaraTaxRates = state => ({
  ...state,
  avalaraTaxRates: {
    ...state.avalaraTaxRates,
    loading: true,
  },
});

const loadAvalaraTaxRatesError = (state, payload) => ({
  ...state,
  avalaraTaxRates: {
    ...state.avalaraTaxRates,
    loading: false,
    error: payload.error,
  },
});

const loadAvalaraTaxRatesSuccess = (state, payload) => ({
  ...state,
  avalaraTaxRates: {
    data: payload.data,
    loading: false,
    error: null,
  },
});

const loadingIsAvalaraTaxEnabled = state => ({
  ...state,
  isAvalaraEnabled: {
    ...state.isAvalaraEnabled,
    loading: true,
  },
});

const loadIsAvalaraTaxEnabledError = (state, payload) => ({
  ...state,
  isAvalaraEnabled: {
    ...state.isAvalaraEnabled,
    loading: false,
    error: payload.error,
  },
});

const loadIsAvalaraTaxEnabledSuccess = (state, payload) => ({
  ...state,
  isAvalaraEnabled: {
    data: payload.data,
    loading: false,
    error: null,
  },
});

const patchLocalMenuItemImage = (state, { itemId, imageUrl }) => {
  const newItemList = state.liveMenu.data.items.map(item => {
    if (item.id === itemId) {
      return {
        ...item,
        imageUrl,
      };
    }
    return item;
  });
  return {
    ...state,
    liveMenu: {
      data: {
        ...state.liveMenu.data,
        items: newItemList,
      },
      loading: false,
      error: null,
    },
  };
};

const loadSharedMenuListSuccess = (state, payload) => ({
  ...state,
  sharedMerchants: payload.data,
});

const reducers = {
  [MenuActionTypes.LOADING_LIVE_MENU]: loadingLiveMenu,
  [MenuActionTypes.UPDATING_LIVE_MENU]: updatingLiveMenu,
  [MenuActionTypes.LOAD_LIVE_MENU_ERROR]: loadLiveMenuError,
  [MenuActionTypes.LOAD_LIVE_MENU_SUCCESS]: loadLiveMenuSuccess,
  [MenuActionTypes.PATCH_LOCAL_MENU_IMAGE]: patchLocalMenuItemImage,
  [MenuActionTypes.LOAD_SHARED_MERCHANT_LIST_SUCCESS]: loadSharedMenuListSuccess,
  [MenuActionTypes.LOADING_AVALARA_TAX_RATES]: loadingAvalaraTaxRates,
  [MenuActionTypes.LOAD_AVALARA_TAX_RATES_ERROR]: loadAvalaraTaxRatesError,
  [MenuActionTypes.LOAD_AVALARA_TAX_RATES_SUCCESS]: loadAvalaraTaxRatesSuccess,
  [MenuActionTypes.LOADING_IS_AVALARA_ENABLED]: loadingIsAvalaraTaxEnabled,
  [MenuActionTypes.LOAD_IS_AVALARA_ENABLED_ERROR]: loadIsAvalaraTaxEnabledError,
  [MenuActionTypes.LOAD_IS_AVALARA_ENABLED_SUCCESS]: loadIsAvalaraTaxEnabledSuccess,
};

export default createReducer(INITIAL_STATE, reducers);
