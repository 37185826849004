import createReducer from 'common/utils/createReducer';
import { setItem, removeItem } from 'common/storage';

export const INITIAL_STATE = {
  merchants: {
    merchantsList: [],
  },
  currentMerchantId: undefined,
};

const updateExperiments = (state, payload) => ({
  ...state,
  experiments: payload.attributeMap || {},
});

const setMerchant = (state, payload) => {
  if (payload.merchantId) {
    setItem('merchantId', payload.merchantId);
  }
  return {
    ...state,
    currentMerchantId: payload.merchantId,
  };
};

const clearMerchant = state => {
  removeItem('merchantId');
  return {
    ...state,
    currentMerchantId: undefined,
  };
};

const setLoading = (state, payload) => ({
  ...state,
  newMerchantLoading: payload,
});

const setLoadingError = (state, payload) => ({
  ...state,
  newMerchantLoadingError: payload,
});

const addToMerchantList = (state, payload) => {
  const newMerchantsList = [...state.merchants.merchantsList];
  payload.merchantsList.forEach(merchant => {
    if (
      !newMerchantsList.find(
        newMerchant => newMerchant.merchantId === merchant.merchantId,
      )
    ) {
      newMerchantsList.push(merchant);
    }
  });
  return {
    ...state,
    merchants: {
      ...state.merchants,
      merchantsList: newMerchantsList,
    },
  };
};

const setOnboardingComplete = (state, payload) => ({
  ...state,
  merchants: {
    ...state.merchants,
    merchantsList: state.merchants.merchantsList.map(merchant => {
      if (merchant.merchantId === payload.merchantId) {
        return {
          ...merchant,
          completedOnboarding: true,
        };
      }

      return merchant;
    }),
  },
});

const setMerchantListStorage = (state, payload) => ({
  ...state,
  merchantListStorage: payload.merchantListStorage,
});

const setNotifications = (state, payload) => ({
  ...state,
  notifications: payload.notifications,
});

const reducers = {
  'merchantFilter/SET_MERCHANT': setMerchant,
  'merchantFilter/ADD_TO_MERCHANT_LIST': addToMerchantList,
  'onboarding/COMPLETE': setOnboardingComplete,
  'merchantFilter/SET_MERCHANT_LIST_STORAGE': setMerchantListStorage,
  'merchantFilter/EXPERIMENTS': updateExperiments,
  'merchantFilter/CLEAR_MERCHANT': clearMerchant,
  'merchantFilter/SET_LOADING': setLoading,
  'merchantFilter/SET_LOADING_ERROR': setLoadingError,
  'merchantFilter/NOTIFICATIONS_LOADED': setNotifications,
};

export default createReducer(INITIAL_STATE, reducers);
