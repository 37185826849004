import * as Sentry from '@sentry/browser';
import uuidv4 from 'uuid/v4';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  ignoreErrors: [
    'Request failed with status code 403',
    'Request failed with status code 412',
    'Failed to load Stripe.js',
  ],
});
Sentry.setTag('userSessionId', uuidv4());
