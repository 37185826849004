import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import parser from 'ua-parser-js';
import { getCookie, setCookie } from 'common/utils/cookie';
import { getExternalId } from 'common/utils/session';
import { isDev, isLocal } from '../components/Env';

export const EVENT_ACTION_INBOUND_MERCHANT_FORM_MENU_PREVIEW_IMPRESSION =
  'INBOUND_MERCHANT_FORM_MENU_PREVIEW_IMPRESSION';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_MENU_PREVIEW_CLICK =
  'INBOUND_MERCHANT_FORM_MENU_PREVIEW_CLICK';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_RECEIVED_ID_IMPRESSION =
  'INBOUND_MERCHANT_FORM_RECEIVED_ID_IMPRESSION';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_STORE_INFO_IMPRESSION =
  'INBOUND_MERCHANT_FORM_STORE_INFO_IMPRESSION';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_STORE_INFO_CLICK =
  'INBOUND_MERCHANT_FORM_STORE_INFO_CLICK';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_STORE_INFO_CLICK_PRIVATE =
  'INBOUND_MERCHANT_FORM_STORE_INFO_CLICK_PRIVATE';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_MENU_SUBMIT_CLICK =
  'INBOUND_MERCHANT_FORM_MENU_SUBMIT_CLICK';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_SELECT_PLAN_CLICK =
  'INBOUND_MERCHANT_FORM_SELECT_PLAN_CLICK';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_SELECT_DEVICE_IMPRESSION =
  'INBOUND_MERCHANT_FORM_SELECT_DEVICE_IMPRESSION';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_SELECT_DEVICE_CLICK =
  'INBOUND_MERCHANT_FORM_SELECT_DEVICE_CLICK';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_SEND_ORDERS_IMPRESSION =
  'INBOUND_MERCHANT_FORM_SEND_ORDERS_IMPRESSION';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_SEND_ORDERS_CLICK =
  'INBOUND_MERCHANT_FORM_SEND_ORDERS_CLICK';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_SHIPPING_DETAILS_IMPRESSION =
  'INBOUND_MERCHANT_FORM_SHIPPING_DETAILS_IMPRESSION';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_SHIPPING_DETAILS_CLICK =
  'INBOUND_MERCHANT_FORM_SHIPPING_DETAILS_CLICK';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_PLAN_SUMMARY_IMPRESSION =
  'INBOUND_MERCHANT_FORM_PLAN_SUMMARY_IMPRESSION';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_PLAN_SUMMARY_CLICK =
  'INBOUND_MERCHANT_FORM_PLAN_SUMMARY_CLICK';
export const INBOUND_MERCHANT_PAYMENT_VALIDATION_ERROR =
  'INBOUND_MERCHANT_PAYMENT_VALIDATION_ERROR';
export const INBOUND_MERCHANT_PAYMENT_FIRST_FOCUS =
  'INBOUND_MERCHANT_PAYMENT_FIRST_FOCUS';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_SUCCESSFUL_ENROLLMENT_IMPRESSION =
  'INBOUND_MERCHANT_FORM_SUCCESSFUL_ENROLLMENT_IMPRESSION';
export const EVENT_ACTION_INBOUND_MERCHANT_FORM_SIGN_IN_CLICK =
  'INBOUND_MERCHANT_FORM_SIGN_IN_CLICK';

const EVENT_ACTION_PAGE = 'CLICK_THROUGH_FUNNEL_PAGE';
const EVENT_ACTION_TRACK = 'CLICK_THROUGH_FUNNEL_TRACK';

export const EVENT_PARAM_TYPE_STRING = 'STRING';
export const EVENT_PARAM_TYPE_INTEGER = 'INT64';
export const EVENT_PARAM_TYPE_BOOLEAN = 'BOOL';

const logEvent = event => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

export const page = eventProperties => {
  logEvent({
    event: EVENT_ACTION_PAGE,
    eventProperties,
  });
};

export const track = (eventName, eventProperties = {}) => {
  logEvent({
    event: EVENT_ACTION_TRACK,
    eventProperties,
    eventName,
  });
};

export const eventParam = (key, value, type = EVENT_PARAM_TYPE_STRING) => ({
  key,
  value,
  type,
});

const parseUrls = () => {
  const url = document.location.href;
  const referral = document.referrer;
  const externalReferral = getCookie('external_referral');
  return [
    {
      key: 'URL',
      value: url,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'REFERRAL_URL',
      value: referral,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'EXTERNAL_REFERRAL',
      value: externalReferral,
      type: EVENT_PARAM_TYPE_STRING,
    },
  ];
};

const parseSessionId = () => {
  const cookieName = 'ritual_partnersessionid';
  let sessionId = getCookie(cookieName);
  if (sessionId === null) {
    sessionId = uuidv4().replace(/-/g, '');
    setCookie(cookieName, sessionId);
  }
  return sessionId;
};

const generateTimestamp = () => new Date().getTime().toString();

const postEvent = event => {
  if (isLocal()) {
    return;
  }
  axios.post(process.env.REACT_APP_RA_URL_V3_BATCH, event);
};

// Logs event according to V3 event schema:
// https://ritualco.atlassian.net/wiki/spaces/GROW/pages/286490790/Eventing
export const logV3Event = ({
  eventAction,
  eventParams,
  entityType,
  analyticsDomainCategory,
  analyticsProjectCategory,
  analyticsFeatureCategory,
}) => {
  const eventId = uuidv4().replace(/-/g, '');
  const eventTime = generateTimestamp();
  const ua = parser(navigator.userAgent);
  const sessionId = parseSessionId();
  const entityId = getExternalId() || sessionId;

  const event = {
    batchDeliveryUuid: eventId,
    clientSubmittedTimestamp: eventTime,
    sourceType: 'WEB',
    sourceVersion: '1',
    sourceName: ua.browser.name,
    events: [
      {
        eventId,
        eventDatetime: eventTime,

        entityType,
        entityId,
        entitySessionId: sessionId,

        eventType: 'INTERACTION',
        eventAction,

        analyticsDomainCategory,
        analyticsProjectCategory,
        analyticsFeatureCategory,

        metadata: {
          eventVersion: 1,
          eventSequenceNumber: 1,
          platform: 'PARTNERS',
          deviceId: sessionId,
          clientVersion: '1',
          isBackground: false,
        },

        eventParams,
      },
    ],
  };

  postEvent(event);
};

export const logRitualCoreEvent = ({
  eventAction,
  eventParams,
  isSuperUser,
}) => {
  logV3Event({
    eventAction,
    eventParams,
    entityType: isSuperUser ? 'INTERNAL_USER' : 'MERCHANT_USER',
    analyticsDomainCategory: 'DOMAIN_CATEGORY_PARTNER_PORTAL',
    analyticsProjectCategory: 'PROJECT_CATEGORY_RITUAL_CORE',
    analyticsFeatureCategory: 'FEATURE_CATEGORY_UNKNOWN',
  });
};

// Logs event params according to Loyalty+ Event Document:
// https://docs.google.com/spreadsheets/d/1vYLErDYsfhq-2EOsoyUyA25UnVTm9-2X_xrkLEhGFoA/edit#gid=1079684397&range=B366:G421
export const logLoyaltyPlusEvent = ({
  eventAction,
  eventParams,
  isSuperUser,
}) => {
  logV3Event({
    eventAction,
    eventParams,
    entityType: isSuperUser ? 'INTERNAL_USER' : 'MERCHANT_USER',
    analyticsDomainCategory: 'DOMAIN_CATEGORY_LOYALTY',
    analyticsProjectCategory: 'PROJECT_CATEGORY_LOYALTY_PLUS',
    analyticsFeatureCategory: 'FEATURE_CATEGORY_UNKNOWN',
  });
};

// Logs event params according to PP RSP Event Document:
// https://docs.google.com/spreadsheets/d/1IIUWM6Cby3SBPWJLu-HqU3Lt8WBYdNqaXhVqW8tQ9PE/edit#gid=649946673
export const logRSPEvent = ({ eventAction, eventParams, isSuperUser }) => {
  logV3Event({
    eventAction,
    eventParams,
    entityType: isSuperUser ? 'INTERNAL_USER' : 'MERCHANT_USER',
    analyticsDomainCategory: 'DOMAIN_CATEGORY_PARTNER_PORTAL',
    analyticsProjectCategory: 'PROJECT_CATEGORY_RSP',
    analyticsFeatureCategory: 'FEATURE_CATEGORY_UNKNOWN',
  });
};

export const logLeadEvent = (eventAction, isPrivate, eventParams) => {
  const eventId = uuidv4().replace(/-/g, '');
  const eventTime = generateTimestamp();
  const ua = parser(navigator.userAgent);
  const urls = parseUrls();
  const sessionId = parseSessionId();

  const event = {
    batchDeliveryUuid: eventId,
    clientSubmittedTimestamp: eventTime,
    sourceType: 'WEB',
    sourceVersion: '1',
    sourceName: ua.browser.name,
    events: [
      {
        eventId,
        eventDatetime: eventTime,

        entityType: 'EXTERNAL_PARTNER',
        entityId: sessionId,
        entitySessionId: sessionId,

        eventType: isPrivate ? 'ENTITY_PRIVATE' : 'INTERACTION',
        eventAction,

        analyticsDomainCategory: 'DOMAIN_CATEGORY_SELF_SERVE',
        analyticsProjectCategory: 'PROJECT_CATEGORY_SELF_SERVE_ONBOARDING',
        analyticsFeatureCategory: 'FEATURE_CATEGORY_UNKNOWN',

        metadata: {
          eventVersion: 1,
          eventSequenceNumber: 1,
          platform: 'PARTNERS',
          deviceId: sessionId,
          clientVersion: '1',
          isBackground: true,
        },

        eventParams: [...eventParams, ...urls],
      },
    ],
  };

  postEvent(event);
};

export const logGTMEvent = event => {
  if (isDev()) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};
