export const STORAGE = window.localStorage;

export const setItem = (key, value, storage = STORAGE) => {
  storage.setItem(key, JSON.stringify(value));
};

export const getItem = (key, storage = STORAGE) => {
  const item = storage.getItem(key);
  if (!item) {
    return undefined;
  }
  return JSON.parse(item);
};

export const removeItem = (key, storage = STORAGE) => {
  storage.removeItem(key);
};

export const setToken = token => setItem('token', token, STORAGE);
export const getToken = () => getItem('token', STORAGE);
export const removeToken = () => removeItem('token', STORAGE);
