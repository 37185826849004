import {
  CA, HK, US, AU, GB
} from 'common/components/Flags';

import en from './en';
import ca from './en-CA';
import au from './en-AU';
import gb from './en-GB';
import fr from './fr-CA';
import zh from './zh-HK';

export const LOCALE = {
  'fr-CA': 'fr-CA',
  'en-AU': 'en-AU',
  'en-GB': 'en-GB',
  'en-CA': 'en-CA',
  'en-US': 'en-US',
  'de-DE': 'de-DE',
  'zh-HK': 'zh-HK',
  'nl-NL': 'nl-NL',
};

const LOCALE_LIST = [{
  label: 'English (Canada)',
  emojiLabel: '🇨🇦 English (Canada)',
  value: LOCALE['en-CA'],
  Flag: CA
}, {
  label: 'English (US)',
  emojiLabel: '🇺🇸 English (US)',
  value: LOCALE['en-US'],
  Flag: US
}, {
  label: 'English (UK)',
  emojiLabel: '🇬🇧 English (UK)',
  value: LOCALE['en-GB'],
  Flag: GB
}, {
  label: 'English (Australia)',
  emojiLabel: '🇦🇺 English (Australia)',
  value: LOCALE['en-AU'],
  Flag: AU
}, {
  label: 'French (Canada)',
  emojiLabel: '🇨🇦 French (Canada)',
  value: LOCALE['fr-CA'],
  Flag: CA
}, {
  label: '繁體中文',
  emojiLabel: '🇭🇰 繁體中文',
  value: LOCALE['zh-HK'],
  Flag: HK
}];

const MERCHANT_LOCALE = {
  'CA': [{
    default: true,
    label: 'Français',
    value: LOCALE['fr-CA'],
  }, {
    label: 'English',
    value: LOCALE['en-CA'],
  }]
};

export { LOCALE_LIST, MERCHANT_LOCALE };

export const getLocale = () => window.localStorage.i18nextLng || LOCALE['en-US'];

// https://datahub.io/core/language-codes
export default {
  'fr-CA': fr,
  'en-AU': au,
  'en-GB': gb,
  'en-CA': ca,
  'en-US': en,
  'zh-HK': zh,
};
