import React from 'react';
import PropTypes from 'prop-types';

const BellIcon = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.89441 20.408C9.99441 20.408 10.8944 19.508 10.8944 18.408H6.89441C6.89441
        19.508 7.78441 20.408 8.89441 20.408ZM14.8944 14.408V9.40796C14.8944 6.33796
        13.2544 3.76796 10.3944 3.08796V2.40796C10.3944 1.57796 9.72441 0.907959 8.89441
        0.907959C8.06441 0.907959 7.39441 1.57796 7.39441 2.40796V3.08796C4.52441 3.76796
        2.89441 6.32796 2.89441 9.40796V14.408L0.894409 16.408V17.408H16.8944V16.408L14.8944 14.408Z"
      fill={color}
    />
  </svg>
);

BellIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

BellIcon.defaultProps = {
  width: 17,
  height: 21,
  color: 'white',
};

export default BellIcon;
