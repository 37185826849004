import React from 'react';
import PropTypes from 'prop-types';

const View = ({ color, height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0098 4.5C13.7698 4.5 16.0098 6.74 16.0098 9.5C16.0098 10.15 15.8798 10.76 15.6498 11.33L18.5698 14.25C20.0798 12.99 21.2698 11.36 21.9998 9.5C20.2698 5.11 15.9998 2 10.9998 2C9.59977 2 8.25977 2.25 7.01977 2.7L9.17977 4.86C9.74977 4.63 10.3598 4.5 11.0098 4.5ZM1.00977 1.77L3.28977 4.05L3.74977 4.51C2.08977 5.8 0.789766 7.52 0.00976562 9.5C1.73977 13.89 6.00977 17 11.0098 17C12.5598 17 14.0398 16.7 15.3898 16.16L15.8098 16.58L18.7398 19.5L20.0098 18.23L2.27977 0.5L1.00977 1.77ZM6.53977 7.3L8.08977 8.85C8.03977 9.06 8.00977 9.28 8.00977 9.5C8.00977 11.16 9.34977 12.5 11.0098 12.5C11.2298 12.5 11.4498 12.47 11.6598 12.42L13.2098 13.97C12.5398 14.3 11.7998 14.5 11.0098 14.5C8.24977 14.5 6.00977 12.26 6.00977 9.5C6.00977 8.71 6.20977 7.97 6.53977 7.3ZM10.8498 6.52L13.9998 9.67L14.0198 9.51C14.0198 7.85 12.6798 6.51 11.0198 6.51L10.8498 6.52Z"
      fill={color}
    />
  </svg>
);

View.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

View.defaultProps = {
  color: 'white',
  height: 12,
  width: 18,
};

export default View;
