import { fetchAllBrandGroups } from 'common/api/gateway';

export const BRANDING_TYPE = {
  LOAD: 'branding/LOAD',
  LOAD_SUCCESS: 'branding/LOAD_SUCCESS',
  LOAD_ERROR: 'branding/LOAD_ERROR',
};

const loadUserBrandGroupListAction = () => ({
  type: BRANDING_TYPE.LOAD,
});

const loadUserBrandGroupListSuccess = data => ({
  type: BRANDING_TYPE.LOAD_SUCCESS,
  payload: { data },
});

const loadUserBrandGroupListError = error => ({
  type: BRANDING_TYPE.LOAD_ERROR,
  payload: { error },
});

export const loadUserBrandGroupList = () => async dispatch => {
  dispatch(loadUserBrandGroupListAction());
  try {
    const { data } = await fetchAllBrandGroups();
    dispatch(loadUserBrandGroupListSuccess(data));
  } catch (error) {
    dispatch(loadUserBrandGroupListError(error));
  }
};
