import React from 'react';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import sendAnalytic from 'common/analytics';

const TooltipWrapper = styled.div`
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  p:last-of-type {
    margin: 0;
  }
`;

const CustomTooltipStyling = styled.div`
  padding: 10px 0;
  line-height: 1.4;
`;

export const TooltipIcon = styled.div``;

class GenericTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
    };
  }

  toggle() {
    this.setState(prevState => ({ tooltipOpen: !prevState.tooltipOpen }));
  }

  render() {
    const {
      children,
      className,
      placement,
      analytic,
      tooltipId,
      toolTipSection,
      ...rest
    } = this.props;
    const { tooltipOpen } = this.state;
    return (
      <TooltipWrapper className={className}>
        <TooltipIcon
          id={tooltipId}
          onMouseEnter={() => {
            const { screen, category, action, label } = analytic;
            sendAnalytic(category, screen, action, label);
          }}
        >
          {toolTipSection}
        </TooltipIcon>
        <StyledTooltip
          placement={placement}
          isOpen={tooltipOpen}
          target={tooltipId}
          toggle={this.toggle}
          {...rest}
        >
          <CustomTooltipStyling>{children}</CustomTooltipStyling>
        </StyledTooltip>
      </TooltipWrapper>
    );
  }
}

GenericTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  placement: PropTypes.oneOf(['auto', 'top', 'left', 'right', 'bottom']),
  toolTipSection: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  tooltipId: PropTypes.string.isRequired,
  className: PropTypes.string,
  analytic: PropTypes.shape({
    screen: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string,
  }),
};

GenericTooltip.defaultProps = {
  placement: 'left',
  className: undefined,
  analytic: {
    screen: '',
    category: '',
    action: '',
    label: '',
  },
};

export default GenericTooltip;
