import { getItem } from 'common/storage';
import { MERCHANT_PATHS, NON_MERCHANT_PATHS } from 'core/Portal/paths';
import { isDev } from '../components/Env';

const getMerchantId = () => getItem('merchantId') || '';

const sendAnalytic = (
  category,
  screen,
  action,
  label = '',
  value = '',
  doNotLogToGa = false,
) => {
  if (isDev()) {
    return;
  }
  const detailLabel =
    action === 'RIT_page_load'
      ? `${label}${getMerchantId()}`
      : `${getMerchantId()}${label}`;
  if (window.RA) {
    window.RA.log(category, screen, action, detailLabel, value, doNotLogToGa);
  }
};

const getTrailingPath = path => path.slice(path.lastIndexOf('/'));

export const getLoadPageAnalyticLabel = ({
  merchantUrl,
  pathname,
  isMerchant,
}) => {
  if (!isMerchant) {
    const label = pathname;
    if (NON_MERCHANT_PATHS.find(url => pathname.includes(url))) {
      return label;
    }
  } else {
    const { normalizedName, urlId } = merchantUrl;
    const homePathname = `/${normalizedName}/${urlId}`;
    const label = pathname === homePathname ? '/' : getTrailingPath(pathname);
    if (MERCHANT_PATHS.includes(label)) {
      return `${label}|`;
    }
  }
};

export default sendAnalytic;
