import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

// Manually setting all pieces of state we wish to log to Sentry.
// Currently the only thing excluded is auth.user.userProfile to protect PII.
export const stateTransformer = state => {
  const {
    api,
    auth,
    merchantFilter,
    router,
    salesSummary,
    sideNav,
    errors,
    settings,
    user,
    navigationTabs,
    multistore,
  } = state || {};

  const { user: authUser, ...authProperties } =
    auth && auth.user ? auth.user : {};

  const {
    merchantList,
    intercom,
    merchantId,
    i18nextLng,
  } = window.localStorage;

  return {
    api,
    auth: {
      ...authProperties,
      user: {
        ...authUser,
        userProfile: null,
      },
    },
    merchantFilter,
    multistore,
    router,
    navigationTabs,
    salesSummary,
    sideNav,
    errors,
    settings: {
      ...settings,
      metroList: null,
      zoneList: null,
    },
    user,
    localStorage: {
      merchantList,
      intercom,
      merchantId,
      i18nextLng,
    },
  };
};

// Add new reducers from rootReducer.js for Sentry logging. Do not include PII.
export const sentryBreadcrumbMiddleware = createSentryMiddleware(Sentry, {
  stateTransformer,
  filterBreadcrumbActions: () => true,
});

export const sentryExceptionLogger = () => next => action => {
  try {
    return next(action);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('An exception occured', err);
    Sentry.captureException(err);
    throw err;
  }
};
