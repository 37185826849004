import createReducer from 'common/utils/createReducer';

const INITIAL_STATE = {
  selectedMerchantIds: [],
  dateRange: {},
};

const setDateRange = (state, { startDate, endDate }) => ({
  ...state,
  dateRange: {
    startDate,
    endDate,
  },
});

const selectMerchants = (state, { merchantIds }) => ({
  ...state,
  selectedMerchantIds: merchantIds,
});

const reducers = {
  'multistore/SELECT_MERCHANTS': selectMerchants,
  'multistore/SET_DATE_RANGE': setDateRange,
};

export default createReducer(INITIAL_STATE, reducers);
