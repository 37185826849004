import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

const AlertWrapper = styled.div`
  width: 100%;
  padding: 20px;
`;

const Unauthorized = ({ t }) => (
  <AlertWrapper>
    <Alert color="danger">{t('unauthorized')}</Alert>
  </AlertWrapper>
);

Unauthorized.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('global')(Unauthorized);
