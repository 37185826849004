import createReducer from 'common/utils/createReducer';
import { BRANDING_TYPE } from './actions';

export const INITIAL_STATE = {
  groupList: {
    loading: true,
    data: null,
    error: null,
  },
};

const loadingBrandGroupList = state => ({
  ...state,
  groupList: {
    ...state.groupList,
    loading: true,
  },
});

const loadBrandGroupListError = (state, payload) => ({
  ...state,
  groupList: {
    ...state.groupList,
    loading: false,
    error: payload.error,
  },
});

const loadBrandGroupListSuccess = (state, payload) => ({
  ...state,
  groupList: {
    data: payload.data,
    loading: false,
    error: null,
  },
});

const reducers = {
  [BRANDING_TYPE.LOAD]: loadingBrandGroupList,
  [BRANDING_TYPE.LOAD_SUCCESS]: loadBrandGroupListSuccess,
  [BRANDING_TYPE.LOAD_ERROR]: loadBrandGroupListError,
};

export default createReducer(INITIAL_STATE, reducers);
