import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {
  sentryBreadcrumbMiddleware,
  sentryExceptionLogger,
} from './sentryMiddleware';

import { isDev } from '../common/components/Env';
import createRootReducer from './rootReducer';

const composeDevTools = isDev() ? composeWithDevTools : f => f;
const logger = createLogger({
  diff: true,
  collapsed: true,
});

export default history => {
  const middleware = [
    sentryExceptionLogger,
    sentryBreadcrumbMiddleware,
    routerMiddleware(history),
    thunk,
  ];
  if (isDev()) {
    middleware.push(logger);
  }
  const store = createStore(
    createRootReducer(history),
    undefined,
    composeDevTools(applyMiddleware(...middleware)),
  );

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      /* eslint-disable global-require */
      store.replaceReducer(require('./rootReducer').default);
    });
  }

  return store;
};
