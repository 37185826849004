/* eslint-disable */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getCurrentUserEmail,
  getCurrentUserFirstName,
} from 'common/auth/selectors';

const Intercom = () => {
  const userEmail = useSelector(getCurrentUserEmail);
  const userName = useSelector(getCurrentUserFirstName);

  useEffect(() => {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    });
    return () => {
      window.Intercom('shutdown');
    };
  }, []);

  useEffect(() => {
    window.intercomSettings = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    };
    if (userEmail) {
      window.intercomSettings.email = userEmail;
    }
    if (userName) {
      window.intercomSettings.name = userName;
    }
    window.Intercom('update');
  }, [userEmail, userName]);

  return null;
};

export default Intercom;
