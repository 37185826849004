import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isSuperUser as isSuperUserSelector } from 'common/auth/selectors';
import { setMerchantForSuperUser as setMerchantForSuperUserAction } from 'core/Portal/actions';
import { setMerchant as setMerchantAction } from './actions';
import { getMerchantList } from './selectors';
import SearchDropdownRow, { NoResultRow } from './SearchDropdownRow';

export const Container = styled.div`
  div:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  ${props => props.maxHeight && `max-height: ${props.maxHeight}px`};
  overflow-y: scroll;
`;

const MAX_NUM_RESULTS = 9.5;

export class UnwrappedMerchantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxHeight: 0,
    };
    this.dropdown = React.createRef();
  }

  componentDidMount() {
    const { current } = this.dropdown;
    if (current && current.children.length > 0) {
      this.setState(() => ({
        maxHeight: current.children[0].clientHeight * MAX_NUM_RESULTS,
      }));
    }
  }

  render() {
    const {
      filtered,
      input,
      history,
      isSuperUser,
      closeDropdown,
      setMerchantForSuperUser,
    } = this.props;
    const { maxHeight } = this.state;
    if (filtered.length === 0) {
      return (
        <Container>
          <NoResultRow input={input} />
        </Container>
      );
    }

    return (
      <Container ref={this.dropdown} maxHeight={maxHeight}>
        {filtered.map(store => {
          const {
            address: { addressLine1, city, province },
            name,
            id: merchantId,
            url,
          } = store;
          const addressStr = ` | ${addressLine1}, ${city}, ${province}`;
          return (
            <SearchDropdownRow
              key={merchantId}
              data-testid={merchantId}
              input={input}
              merchantName={isSuperUser ? name : `${name}${addressStr}`}
              merchantAddress={isSuperUser ? addressStr : ''}
              onClick={() => {
                const {
                  location: { search },
                } = history;
                if (isSuperUser) {
                  setMerchantForSuperUser(merchantId);
                }
                history.push(`/${url.name}/${url.id}${search}`);
                closeDropdown();
              }}
            />
          );
        })}
      </Container>
    );
  }
}

UnwrappedMerchantList.propTypes = {
  isSuperUser: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  filtered: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        addressLine1: PropTypes.string,
        city: PropTypes.string,
        province: PropTypes.string,
      }),
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  input: PropTypes.string.isRequired,
  closeDropdown: PropTypes.func.isRequired,
};

export default compose([
  withRouter,
  connect(
    state => ({
      merchantList: getMerchantList(state),
      isSuperUser: isSuperUserSelector(state),
    }),
    {
      setMerchantForSuperUser: setMerchantForSuperUserAction,
      setMerchant: setMerchantAction,
    },
  ),
])(UnwrappedMerchantList);
