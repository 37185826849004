export const getCookie = name => {
  function escape(s) {
    return s.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1');
  }
  const match = document.cookie.match(
    RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`),
  );
  return match ? match[1] : null;
};

/**
 * @param {string} name The cookie name.
 * @param {string} value The cookie value.
 * @param {string=} optExpires Optional expire time
 */
export const setCookie = (name, value, optExpires) => {
  let expires = optExpires;
  if (!expires) {
    const expireDate = new Date();
    expireDate.setHours(expireDate.getHours() + 24 * 30);
    expires = expireDate.toGMTString();
  }
  document.cookie = `${name}=${value};path=/;expires=${expires};`;
};

export const removeCookie = name => {
  document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};
