import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ width, height, color, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.0380859 19.4008H22.0381L11.0381 0.400833L0.0380859 19.4008ZM12.0381
      16.4008H10.0381V14.4008H12.0381V16.4008ZM12.0381 12.4008H10.0381V8.40083H12.0381V12.4008Z"
      fill={color}
    />
  </svg>
);

Alert.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

Alert.defaultProps = {
  width: 23,
  height: 20,
  color: 'black',
  className: '',
};

export default Alert;
