import bankDetails from './bankDetails.json';
import branding from './branding.json';
import charts from './charts.json';
import createMerchant from './createMerchant.json';
import dashboard from './dashboard.json';
import errors from './errors.json';
import global from './global.json';
import login from './login.json';
import loyalty from './loyalty.json';
import onboarding from './onboarding.json';
import marketing from './marketing.json';
import salesDetail from './salesDetail.json';
import settings from './settings.json';
import offers from './offers.json';
import payout from './payout.json';
import privateDataMask from './privateDataMask.json';
import ritualNow from './ritualNow.json';
import generics from './generics.json';
import sideNav from './sideNav.json';
import support from './support.json';
import profile from './profile.json';
import feedback from './feedback.json';
import webButton from './webButton.json';
import report from './report.json';
import multistore from './multistore.json';
import web from './web.json';
import editBar from './editBar.json';
import salesSummary from './salesSummary.json';
import transactions from './transactions.json';

export default {
  bankDetails,
  branding,
  charts,
  createMerchant,
  dashboard,
  errors,
  global,
  login,
  loyalty,
  onboarding,
  marketing,
  salesDetail,
  settings,
  offers,
  payout,
  privateDataMask,
  ritualNow,
  generics,
  sideNav,
  support,
  profile,
  feedback,
  webButton,
  report,
  multistore,
  web,
  editBar,
  salesSummary,
  transactions,
};
