import React from 'react';
import PropTypes from 'prop-types';

const NL = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="20.5"
      height="14.5"
      rx="1.75"
      fill="white"
      stroke="#F5F5F5"
      strokeWidth="0.5"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="15"
    >
      <rect
        x="0.25"
        y="0.25"
        width="20.5"
        height="14.5"
        rx="1.75"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5H21V0H0V5Z"
        fill="#CA2B39"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15H21V10H0V15Z"
        fill="#2C56A2"
      />
    </g>
  </svg>
);

NL.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

NL.defaultProps = {
  width: 21,
  height: 15,
};

export default NL;
