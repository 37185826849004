import React from 'react';
import PropTypes from 'prop-types';

const US = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="21" height="15" rx="2" fill="white" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="15"
    >
      <rect width="21" height="15" rx="2" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 0H0V1H21V0ZM21 2H0V3H21V2ZM0 4H21V5H0V4ZM21 6H0V7H21V6ZM0 8H21V9H0V8ZM21
        10H0V11H21V10ZM0 12H21V13H0V12ZM21 14H0V15H21V14Z"
        fill="#D02F44"
      />
      <rect width="9" height="7" fill="#46467F" />
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 1.5C2 1.77614 1.77614 2 1.5 2C1.22386 2 1 1.77614 1 1.5C1 1.22386
          1.22386 1 1.5 1C1.77614 1 2 1.22386 2 1.5ZM4 1.5C4 1.77614 3.77614 2 3.5
          2C3.22386 2 3 1.77614 3 1.5C3 1.22386 3.22386 1 3.5 1C3.77614 1 4 1.22386
          4 1.5ZM5.5 2C5.77614 2 6 1.77614 6 1.5C6 1.22386 5.77614 1 5.5 1C5.22386 1 5 1.22386
          5 1.5C5 1.77614 5.22386 2 5.5 2ZM8 1.5C8 1.77614 7.77614 2 7.5 2C7.22386 2 7 1.77614
          7 1.5C7 1.22386 7.22386 1 7.5 1C7.77614 1 8 1.22386 8 1.5ZM2.5 3C2.77614 3 3 2.77614
          3 2.5C3 2.22386 2.77614 2 2.5 2C2.22386 2 2 2.22386 2 2.5C2 2.77614 2.22386 3 2.5 3ZM5
          2.5C5 2.77614 4.77614 3 4.5 3C4.22386 3 4 2.77614 4 2.5C4 2.22386 4.22386 2 4.5 2C4.77614
          2 5 2.22386 5 2.5ZM6.5 3C6.77614 3 7 2.77614 7 2.5C7 2.22386 6.77614 2 6.5 2C6.22386 2 6
          2.22386 6 2.5C6 2.77614 6.22386 3 6.5 3ZM8 3.5C8 3.77614 7.77614 4 7.5 4C7.22386 4 7 3.77614
          7 3.5C7 3.22386 7.22386 3 7.5 3C7.77614 3 8 3.22386 8 3.5ZM5.5 4C5.77614 4 6 3.77614 6
          3.5C6 3.22386 5.77614 3 5.5 3C5.22386 3 5 3.22386 5 3.5C5 3.77614 5.22386 4 5.5 4ZM4
          3.5C4 3.77614 3.77614 4 3.5 4C3.22386 4 3 3.77614 3 3.5C3 3.22386 3.22386 3 3.5 3C3.77614
          3 4 3.22386 4 3.5ZM1.5 4C1.77614 4 2 3.77614 2 3.5C2 3.22386 1.77614 3 1.5 3C1.22386 3 1
          3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4ZM3 4.5C3 4.77614 2.77614 5 2.5 5C2.22386 5 2 4.77614
          2 4.5C2 4.22386 2.22386 4 2.5 4C2.77614 4 3 4.22386 3 4.5ZM4.5 5C4.77614 5 5 4.77614 5 4.5C5
          4.22386 4.77614 4 4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5ZM7 4.5C7 4.77614
          6.77614 5 6.5 5C6.22386 5 6 4.77614 6 4.5C6 4.22386 6.22386 4 6.5 4C6.77614 4 7 4.22386 7 4.5ZM7.5
          6C7.77614 6 8 5.77614 8 5.5C8 5.22386 7.77614 5 7.5 5C7.22386 5 7 5.22386 7 5.5C7 5.77614 7.22386
          6 7.5 6ZM6 5.5C6 5.77614 5.77614 6 5.5 6C5.22386 6 5 5.77614 5 5.5C5 5.22386 5.22386 5 5.5 5C5.77614
          5 6 5.22386 6 5.5ZM3.5 6C3.77614 6 4 5.77614 4 5.5C4 5.22386 3.77614 5 3.5 5C3.22386 5 3 5.22386
          3 5.5C3 5.77614 3.22386 6 3.5 6ZM2 5.5C2 5.77614 1.77614 6 1.5 6C1.22386 6 1 5.77614 1 5.5C1
          5.22386 1.22386 5 1.5 5C1.77614 5 2 5.22386 2 5.5Z"
          fill="url(#paint0_linear)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="1"
        y="1"
        width="7"
        height="6"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="1"
        y1="1"
        x2="1"
        y2="6"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0F0F0" />
      </linearGradient>
    </defs>
  </svg>
);

US.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

US.defaultProps = {
  width: 21,
  height: 15,
};

export default US;
