import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
  getUserPermissions,
  isSuperUser as isSuperUserSelector,
} from 'common/auth/selectors';
import { RequirementType } from 'common/components/RequiresPermission';
import { useRemoteConfig } from 'common/remoteConfig';
import {
  getCurrentMerchantExperiments,
  isCurrentMerchantEnrolledInR1,
} from 'core/MerchantFilter/selectors';
import { ONBOARDING_PATH } from 'core/Portal/paths';

const isPathMatch = ({ page, match, location }) => {
  if (page.label === 'back') {
    return false;
  }
  if (location.pathname === page.to) {
    return true;
  }
  if (page.exact) {
    return (
      match.path === '/:merchantName/:merchantUrl/' || match.path === page.to
    );
  }
  return match.url.includes(page.to);
};

const hasRequiredExperiments = (navItemExperiments, merchantExperiments) => {
  if (!navItemExperiments) return true;
  for (let i = 0; i < navItemExperiments.length; i += 1) {
    const exp = navItemExperiments[i];
    if (
      !Object.values(merchantExperiments).find(
        ({ attributeName, attributeValue }) =>
          attributeName === exp && attributeValue === '1',
      )
    ) {
      return false;
    }
  }
  return true;
};

const hasRequiredPermissions = (
  requirementType,
  userPermissions,
  permissions,
) => {
  if (requirementType === RequirementType.ANY) {
    return _.some(permissions, permission =>
      _.includes(userPermissions, permission),
    );
  }
  return _.every(permissions, permission =>
    _.includes(userPermissions, permission),
  );
};

export const useNavList = (inputNavList = []) => {
  const match = useRouteMatch();
  const location = useLocation();
  const { getString } = useRemoteConfig();
  const disabledNavItems = getString('menu_disabled_items')?.split(',');
  const userPermissions = useSelector(getUserPermissions);
  const isSuperUser = useSelector(isSuperUserSelector);
  const hasR1Merchant = useSelector(isCurrentMerchantEnrolledInR1);
  const merchantExperiments = useSelector(getCurrentMerchantExperiments);
  const navList = inputNavList.filter(
    ({
      experiments,
      isInternal,
      label,
      permissions,
      permissionRequirementType,
      r1Required,
    }) => {
      // Remove if marked as disabled via Firebase remote config
      if (disabledNavItems.includes(label)) return false;
      // Remove if merchant experiments are missing
      if (!hasRequiredExperiments(experiments, merchantExperiments))
        return false;
      // Remove if internal-only
      if (isInternal && !isSuperUser) return false;
      // Remove if R1 enrollment is required
      if (r1Required && !isSuperUser && !hasR1Merchant) return false;
      // Remove if user permissions are missing
      if (permissions && permissions.length > 0) {
        if (
          !hasRequiredPermissions(
            permissionRequirementType,
            userPermissions,
            permissions,
          )
        )
          return false;
      }
      return true;
    },
  );

  const navItemsByGroup = _.groupBy(navList, ({ group = '' }) => group);
  const groups = Object.keys(navItemsByGroup);

  const activeNav = match.path.includes(ONBOARDING_PATH)
    ? navList.find(page => page.label === 'home')
    : navList.find(page => isPathMatch({ page, match, location }));

  const active = activeNav
    ? {
        label: activeNav.label,
        position:
          navList.findIndex(({ label }) => label === activeNav.label) +
          groups.findIndex(key => key === (activeNav.group || '')),
      }
    : undefined;

  return {
    navItemsByGroup,
    groups,
    active,
  };
};
