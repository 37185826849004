import moment from 'moment';
import momentTz from 'moment-timezone';
import 'moment/locale/zh-hk';
import 'moment/locale/de-ch';
import 'moment/locale/fr-ca';
import 'moment/locale/es-us';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/nl';

import i18n from '../i18n';

const updateUserLocaleAction = locale => ({
  type: 'user/UPDATE_LOCALE',
  payload: {
    locale,
  },
});

const getMomentLocale = locale => {
  switch (locale) {
    case 'de-DE':
    case 'de':
      return 'de-ch';
    case 'nl-NL':
      return 'nl';
    default:
      return locale;
  }
};

export const updateUserLocale = locale => dispatch => {
  i18n.changeLanguage(locale);
  const momentLocale = getMomentLocale(locale);
  moment.locale(momentLocale);
  // eslint-disable-next-line no-underscore-dangle
  momentTz.updateLocale(momentLocale, moment.localeData()._config);
  momentTz.locale(momentLocale);
  return dispatch(updateUserLocaleAction(locale));
};
