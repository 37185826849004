import { useEffect } from 'react';
import _ from 'lodash';

import { page } from 'common/analytics/analytics';

// Do not send events for routes that send their own page events
const PAGE_EVENT_PATH_BLACKLIST = [
  /^[/].*[/].*[/]menu.*$/, // e.g. '/big-smoke-burger-king-west-portland-toronto/tc1u/menu/modifier-group'
  /^[/]signup.*$/, // e.g. '/signup', '/signup/V4'
];

const SEND_PAGE_EVENT_DEBOUNCE_MS = 500;

// This is debounced to avoid sending multiple events during a client-side redirect
const sendPageEvent = _.debounce(location => {
  if (!PAGE_EVENT_PATH_BLACKLIST.some(regex => regex.test(location.pathname))) {
    page();
  }
}, SEND_PAGE_EVENT_DEBOUNCE_MS);

/**
 * Sends a page event on initial page load, and when the pathname changes.
 */
const usePageEventOnRouteChange = history => {
  useEffect(() => {
    let lastPathName = history.location.pathname;
    history.listen(location => {
      if (location.pathname !== lastPathName) {
        sendPageEvent(location);
      }
      lastPathName = location.pathname;
    });

    // Initial page load
    sendPageEvent(history.location);
  }, [history]);
};

export default usePageEventOnRouteChange;
