/* eslint-disable max-len */
import qs from 'qs';

import apiClient from './apiClient';
import api from '.';

export const searchMerchant = (searchTerm, searchLimit) => {
  const endpoint = `/merchants?keyword=${encodeURIComponent(
    searchTerm,
  )}&limit=${searchLimit}`;

  return apiClient({
    method: 'get',
    url: endpoint,
  })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
};

export const fetchCustomerFeedback = ({
  merchantId,
  startTimestamp,
  endTimeStamp,
}) => {
  const endpoint = `/merchants/${merchantId}/feedback-summary?startTimeStamp=${startTimestamp}&endTimeStamp=${endTimeStamp}`;

  return apiClient({
    method: 'get',
    url: endpoint,
  });
};

/**
 * @param filters Array<String>: expected that it has 3 objects with the following signature
 * { label, value }. label dictates the name of the filter sent, value dictates if it is applied
 * label can be BAD, HAPPY or NEUTRAL
 */
export const fetchCustomerComments = ({
  merchantId,
  startTimeStamp,
  endTimeStamp,
  filters,
}) => {
  const finishedStatus = filters.reduce((filterString, curr) => {
    if (curr.value) {
      return `${curr.label},${filterString}`;
    }
    return filterString;
  }, '');

  const query = qs.stringify({
    startTimeStamp,
    endTimeStamp,
    finishedStatus,
  });

  const endpoint = `/merchants/${merchantId}/feedback-details?${query}`;

  return apiClient({
    method: 'get',
    url: endpoint,
  });
};

export const fetchTaxInvoiceList = ({
  merchantId,
  startTimeStamp,
  endTimeStamp,
}) => {
  const query = qs.stringify({
    start_timestamp: startTimeStamp,
    end_timestamp: endTimeStamp,
  });

  const endpoint = `/merchants/${merchantId}/invoices?${query}`;
  return apiClient({
    method: 'get',
    url: endpoint,
  });
};

export const fetchMerchantTermAndAgreement = ({
  merchantId,
  merchantLocale,
  useSubscriptionAPI,
}) => {
  const query = qs.stringify({ useSubscriptionAPI });
  return api.get(
    `/merchants/v1/${merchantId}/terms/${merchantLocale}?${query}`,
  );
};

export const fetchMerchantData = merchantId =>
  api({
    method: 'get',
    url: `/merchants/v1/${merchantId}`,
  });

export const fetchTaxInvoice = ({ merchantId, invoiceNumber }) =>
  apiClient({
    method: 'get',
    url: `/merchants/${merchantId}/invoices/${invoiceNumber}`,
    responseType: 'blob',
  });

export const fetchBankingInfo = ({ merchantId }) =>
  apiClient({
    method: 'get',
    url: `merchants/${merchantId}/bank-info`,
  });

export const fetchForms = ({ merchantId }) =>
  apiClient({
    method: 'get',
    url: `merchants/${merchantId}/forms`,
  });

export const patchMerchantState = ({
  reason,
  state,
  overCapacityDuration,
  merchantId,
  offBoardReason,
  downReason,
}) =>
  apiClient({
    method: 'PATCH',
    url: `merchants/${merchantId}/state`,
    data: {
      reason,
      state,
      durationMs: parseInt(/* minutes: */ overCapacityDuration, 10) * 60000,
      offBoardReason,
      downReason,
    },
  });

export const fetchLaunchConfirmation = ({ merchantId }) =>
  apiClient({
    method: 'get',
    url: `/merchants/${merchantId}/launch-confirmation`,
  });

export const patchLaunchConfirmation = ({ merchantId, data }) =>
  apiClient({
    method: 'patch',
    url: `/merchants/${merchantId}/launch-confirmation`,
    data,
  });

export const deleteLaunchConfirmation = ({ merchantId }) =>
  apiClient({
    method: 'delete',
    url: `/merchants/${merchantId}/launch-confirmation`,
  });

export const fetchAllBrandGroups = () =>
  apiClient({
    method: 'get',
    url: 'branding/brands',
  });

export const publishBrandChanges = ({ brandId, theme }) =>
  apiClient({
    method: 'post',
    url: `/branding/brands/${brandId}`,
    data: theme,
  });

export const resetBrandToDefault = ({ brandId }) =>
  apiClient({
    method: 'post',
    url: `/branding/brands/${brandId}/reset`,
  });

export const fetchBrandGroup = ({ brandId, theme }) =>
  apiClient({
    method: 'get',
    url: `/branding/brands/${brandId}`,
    data: theme,
  });

export const resetMerchantThemeToDefault = ({ merchantId }) =>
  apiClient({
    method: 'post',
    url: `/branding/merchant/${merchantId}/reset`,
  });

export const getMerchantTheme = ({ merchantId }) =>
  apiClient({
    method: 'get',
    url: `/branding/merchant/${merchantId}`,
  });

export const publishMerchantTheme = ({ merchantId, theme }) =>
  apiClient({
    method: 'post',
    url: `/branding/merchant/${merchantId}`,
    data: theme,
  });
