import React from 'react';
import PropTypes from 'prop-types';

const DownArrow = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.42367 0.840088L6.01367 5.42009L10.6037 0.840088L12.0137
      2.25009L6.01367 8.25009L0.0136719 2.25009L1.42367 0.840088Z"
      fill={color}
    />
  </svg>
);

DownArrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

DownArrow.defaultProps = {
  color: 'black',
  width: 13,
  height: 9,
};

export default DownArrow;
