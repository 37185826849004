import React from 'react';
import PropTypes from 'prop-types';

const VerticalEllipse = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 6 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.77637 5.32324C4.15137 5.32324 5.27637 4.19824 5.27637 2.82324C5.27637
      1.44824 4.15137 0.323242 2.77637 0.323242C1.40137 0.323242 0.276367 1.44824
      0.276367 2.82324C0.276367 4.19824 1.40137 5.32324 2.77637 5.32324ZM2.77637
      7.82324C1.40137 7.82324 0.276367 8.94824 0.276367 10.3232C0.276367 11.6982
      1.40137 12.8232 2.77637 12.8232C4.15137 12.8232 5.27637 11.6982 5.27637 10.3232C5.27637
      8.94824 4.15137 7.82324 2.77637 7.82324ZM2.77637 15.3232C1.40137 15.3232 0.276367
      16.4482 0.276367 17.8232C0.276367 19.1982 1.40137 20.3232 2.77637 20.3232C4.15137
      20.3232 5.27637 19.1982 5.27637 17.8232C5.27637 16.4482 4.15137 15.3232 2.77637 15.3232Z"
      fill={color}
    />
  </svg>
);

VerticalEllipse.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

VerticalEllipse.defaultProps = {
  width: 6,
  height: 21,
  color: 'black',
};

export default VerticalEllipse;
