import _ from 'lodash';
import { createSelector } from 'reselect';
import { Experiments as MerchantExperiments } from 'common/auth/constants';
import { NOTIFICATIONS } from 'common/constants/notifications';

const getMerchantAccess = state => state.merchantFilter.merchants;

export const getPrimaryMerchantId = state =>
  state.merchantFilter.currentMerchantId || '';

export const getMerchantLoadingError = state =>
  state?.merchantFilter?.newMerchantLoadingError;

export const getMerchantList = createSelector(
  getMerchantAccess,
  merchantAccess => {
    if (!merchantAccess) return [];
    const allMerchants = merchantAccess.merchantsList || [];
    return _.map(allMerchants, merchant => ({
      id: merchant.merchantId,
      name: merchant.merchantName,
      address: merchant.address,
      url: {
        name: merchant.merchantUrl.normalizedName,
        id: merchant.merchantUrl.urlId,
      },
    }));
  },
);

export const getTabState = state => state?.navigationTabs?.tabs;
export const getTabsList = createSelector(getTabState, tabs =>
  tabs?.map(tab => ({ ...tab, id: tab?.merchantId })),
);

export const getOtherMerchantIds = createSelector(
  getMerchantList,
  getPrimaryMerchantId,
  (merchants, primaryMerchantId) =>
    merchants
      .filter(merchant => merchant.id !== primaryMerchantId)
      .map(merchant => merchant.id),
);

export const getCurrentMerchant = createSelector(
  state => (state.merchantFilter.merchants || {}).merchantsList,
  getPrimaryMerchantId,
  (merchants, merchantId) => _.find(merchants, { merchantId }),
);

export const getCurrentMerchantUrl = createSelector(
  getCurrentMerchant,
  merchant =>
    (merchant && merchant.merchantUrl && merchant.merchantUrl.urlId) || '',
);

export const getCurrentMerchantNormalizedName = createSelector(
  getCurrentMerchant,
  merchant =>
    (merchant && merchant.merchantUrl && merchant.merchantUrl.normalizedName) ||
    '',
);

export const getCurrentMerchantBasePath = createSelector(
  getCurrentMerchant,
  merchant =>
    (merchant &&
      merchant.merchantUrl &&
      `/${merchant.merchantUrl.normalizedName}/${merchant.merchantUrl.urlId}`) ||
    '',
);

export const getCurrentMerchantTimezone = createSelector(
  getCurrentMerchant,
  merchant => (merchant && merchant.timezone) || 'UTC',
);

export const getCurrentMerchantMetroId = createSelector(
  getCurrentMerchant,
  merchant => (merchant && merchant.metroId) || '',
);

export const getCurrentMerchantCurrency = createSelector(
  getCurrentMerchant,
  merchant => (merchant && merchant.currency) || 'CAD',
);

export const getCurrentMerchantLatLng = createSelector(
  getCurrentMerchant,
  merchant => {
    if (!merchant || !merchant.lat || !merchant.lng) return null;
    return {
      lat: merchant.lat,
      lng: merchant.lng,
    };
  },
);

export const getCurrentMerchantLocale = createSelector(
  getCurrentMerchant,
  merchant => (merchant && merchant.locale) || 'CA',
);

export const getCurrentMerchantName = createSelector(
  getCurrentMerchant,
  merchant => merchant.merchantName,
);

export const getCurrentMerchantCompletedOnboarding = createSelector(
  getCurrentMerchant,
  merchant => (merchant ? merchant.completedOnboarding : false),
);

export const getCurrentMerchantLaunchDate = createSelector(
  getCurrentMerchant,
  merchant => (merchant ? merchant.launchDate : null),
);

export const getCurrentMerchantIsLoyaltyEligible = createSelector(
  getCurrentMerchant,
  merchant => (merchant ? merchant.isLoyaltyEligible : false),
);

export const getCurrentMerchantMenuId = createSelector(
  getCurrentMerchant,
  merchant => {
    if (!merchant) {
      return null;
    }
    const { menuId, sharedMenuId } = merchant;
    return menuId !== sharedMenuId ? sharedMenuId : menuId;
  },
);

export const isSharedMenu = createSelector(getCurrentMerchant, merchant => {
  if (!merchant) {
    return false;
  }
  const { menuId, sharedMenuId } = merchant;
  return menuId !== sharedMenuId;
});

export const isCurrentMerchantEnrolledInR1 = createSelector(
  getCurrentMerchant,
  merchant => {
    if (!merchant) {
      return false;
    }
    if (merchant.hasR1) {
      return true;
    }
    return (
      merchant.ritualOneEnrollmentData &&
      merchant.ritualOneEnrollmentData.ritualOneTier
    );
  },
);

export const getCurrentMerchantCountryCode = createSelector(
  getCurrentMerchant,
  merchant => {
    if (!merchant) {
      return null;
    }
    if (!merchant.address) {
      return 'CA';
    }
    return merchant.address.countryCode;
  },
);

export const isCurrentMerchantAmerican = createSelector(
  getCurrentMerchantCountryCode,
  countryCode => countryCode === 'US',
);

export const getOtherMerchantList = createSelector(
  getMerchantList,
  getCurrentMerchant,
  (merchantList, currentMerchant) => {
    if (!currentMerchant || !merchantList) return [];
    return merchantList.filter(
      merchant => merchant.id !== currentMerchant.merchantId,
    );
  },
);

export const getMerchantListInStorage = createSelector(
  state => state.merchantFilter.merchantListStorage,
  getMerchantList,
  (merchantListStorage, merchantList) => {
    if (!merchantListStorage) return [];
    return merchantListStorage
      .map(merchantId =>
        merchantList.find(merchant => merchant.id === merchantId),
      )
      .filter(merchant => !!merchant);
  },
);

export const getMerchantExperiments = state =>
  state.merchantFilter.experiments || {};

export const getCurrentMerchantExperiments = createSelector(
  getPrimaryMerchantId,
  getMerchantExperiments,
  (merchantId, experiments) => experiments[merchantId] || {},
);

export const currentMerchantHasSquareExperiment = createSelector(
  getCurrentMerchantExperiments,
  experiments =>
    Object.values(experiments).find(
      ({ attributeName, attributeValue }) =>
        attributeName === MerchantExperiments.SQUARE_INTEGRATION_ENABLED &&
        attributeValue === '1',
    ),
);

export const currentMerchantHasMenuEditAccess = createSelector(
  getCurrentMerchantExperiments,
  experiments =>
    Object.values(experiments).find(
      ({ attributeName, attributeValue }) =>
        attributeName === MerchantExperiments.SELF_SERVE_MENU_ENABLED &&
        attributeValue === '1',
    ),
);

export const isCurrentMerchantBillingEnabled = createSelector(
  getCurrentMerchantExperiments,
  experiments =>
    Object.values(experiments).find(
      ({ attributeName, attributeValue }) =>
        attributeName === MerchantExperiments.MERCHANT_BILLING_ENABLED &&
        attributeValue === '1',
    ),
);

// TODO: replace with actual selector
export const isCurrentMerchantTrialExpired = createSelector(() => false);

// TODO: replace with actual selector
export const getCurrentMerchantTrialDaysRemaining = createSelector(() => 30);

export const isCurrentMerchantDeliveryConfigEnabled = createSelector(
  getCurrentMerchantExperiments,
  experiments =>
    Object.values(experiments).some(
      ({ attributeName, attributeValue }) =>
        attributeName ===
          MerchantExperiments.MERCHANT_DELIVERY_CONFIG_ENABLED &&
        attributeValue === '1',
    ),
);

export const getOrderMethodsEnabled = createSelector(
  getCurrentMerchantExperiments,
  experiments =>
    Object.values(experiments).some(
      ({ attributeName, attributeValue }) =>
        attributeName === MerchantExperiments.ORDER_DEVICE_SELECTION_ENABLED &&
        attributeValue === '1',
    ),
);

export const getCurrentMerchantNotifications = createSelector(
  getPrimaryMerchantId,
  state => state.merchantFilter.notifications || {},
  (merchantId, notifications) => notifications[merchantId] || [],
);

export const getShareProgramPopup = createSelector(
  getCurrentMerchantNotifications,
  notifications =>
    notifications.find(
      notification =>
        notification.notificationType === NOTIFICATIONS.SHARE_PROGRAM_POPUP,
    ),
);

export const getShowShareProgramPopup = createSelector(
  getShareProgramPopup,
  shareProgramPopup => !!shareProgramPopup,
);
