import {
  METRO_LIST_REQUEST,
  METRO_LIST_SUCCESS,
  METRO_LIST_FAILURE,
  ZONE_LIST_REQUEST,
  ZONE_LIST_SUCCESS,
  ZONE_LIST_FAILURE,
  MERCHANT_STATE_REQUEST,
  MERCHANT_STATE_SUCCESS,
  MERCHANT_STATE_FAILURE,
  MERCHANT_STATE_FOR_LAUNCH_REQUEST,
  MERCHANT_STATE_FOR_LAUNCH_SUCCESS,
  MERCHANT_STATE_FOR_LAUNCH_FAILURE,
  SET_SUCCESS_MESSAGE,
  CLEAR_SUCCESS_MESSAGE,
  MERCHANT_ONBOARDING_STEP,
} from './actions';

export const INITIAL_STATE = {
  isFetchingMetroList: false,
  metroList: [],
  fetchMetroListError: null,
  isFetchingZoneList: false,
  zoneList: [],
  fetchZoneListError: null,
  isFetchingMerchantState: true,
  merchantState: null,
  fetchMerchantStateError: null,
  isFetchingMerchantStateForLaunch: false,
  merchantStateForLaunch: null,
  fetchMerchantStateForLaunchError: null,
  successMessage: null,
  onboardingStep: 0,
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case METRO_LIST_REQUEST:
      return {
        ...state,
        isFetchingMetroList: true,
        fetchMetroListError: null,
      };
    case METRO_LIST_SUCCESS:
      return {
        ...state,
        isFetchingMetroList: false,
        metroList: action.payload,
      };
    case METRO_LIST_FAILURE:
      return {
        ...state,
        isFetchingMetroList: false,
        fetchMetroListError: action.payload,
      };
    case ZONE_LIST_REQUEST:
      return {
        ...state,
        isFetchingZoneList: true,
        fetchZoneListError: null,
      };
    case ZONE_LIST_SUCCESS:
      return {
        ...state,
        isFetchingZoneList: false,
        zoneList: action.payload,
      };
    case ZONE_LIST_FAILURE:
      return {
        ...state,
        isFetchingZoneList: false,
        fetchZoneListError: action.payload,
      };
    case MERCHANT_STATE_REQUEST:
      return {
        ...state,
        isFetchingMerchantState: true,
        fetchMerchantStateError: null,
      };
    case MERCHANT_STATE_SUCCESS:
      return {
        ...state,
        isFetchingMerchantState: false,
        merchantState: action.payload,
      };
    case MERCHANT_STATE_FAILURE:
      return {
        ...state,
        isFetchingMerchantState: false,
        fetchMerchantStateError: action.payload,
      };
    case MERCHANT_STATE_FOR_LAUNCH_REQUEST:
      return {
        ...state,
        isFetchingMerchantStateForLaunch: true,
        fetchMerchantStateForLaunchError: null,
      };
    case MERCHANT_STATE_FOR_LAUNCH_SUCCESS:
      return {
        ...state,
        isFetchingMerchantStateForLaunch: false,
        merchantStateForLaunch: action.payload,
      };
    case MERCHANT_STATE_FOR_LAUNCH_FAILURE:
      return {
        ...state,
        isFetchingMerchantStateForLaunch: false,
        fetchMerchantStateForLaunchError: action.payload,
      };
    case MERCHANT_ONBOARDING_STEP:
      return {
        ...state,
        onboardingStep: action.payload,
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.payload,
      };
    case CLEAR_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: null,
      };
    default:
      return state;
  }
};

export default settingsReducer;
