import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Store from 'common/assets/store.svg';

const Container = styled.div`
  display: flex;
  padding: 15px;
  align-items: baseline;
  &:hover {
    background: ${props => props.theme.colours.background};
    cursor: pointer;
  }
`;

const Text = styled.div`
  margin-left: 16px;
`;

const StoreIcon = styled.div`
  background: url(${Store});
  height: 16px;
  width: 18px;
`;

export const NoResultRow = withTranslation('global')(({ input, t }) => (
  <Container>
    <StoreIcon />
    <Text>
      <strong>{`${input} `}</strong>-<i>{` ${t('noResults')}`}</i>
    </Text>
  </Container>
));

NoResultRow.propTypes = {
  input: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const SearchDropdownRow = ({
  merchantName,
  onClick,
  input,
  merchantAddress,
  ...rest
}) => (
  <Container onClick={onClick} {...rest}>
    <StoreIcon />
    <Text>
      {merchantName}
      {merchantAddress}
    </Text>
  </Container>
);

SearchDropdownRow.propTypes = {
  merchantName: PropTypes.string.isRequired,
  merchantAddress: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  input: PropTypes.string.isRequired,
};

SearchDropdownRow.defaultProps = {
  onClick: () => {},
};

export default SearchDropdownRow;
