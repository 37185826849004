import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const IconWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  font-size: 60px;
  color: #ccc;
`;

const Label = styled.div`
  flex: 1;
  display: flex;
  margin-top: 10px;
  font-size: 16px;
  color: #6c6d79;
  max-width: 90%;
  text-align: center;
`;

const WidgetPlaceholder = ({ label, icon }) => (
  <NoDataContainer>
    {icon && <IconWrapper>{icon}</IconWrapper>}
    <Label>{label}</Label>
  </NoDataContainer>
);

WidgetPlaceholder.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
};

WidgetPlaceholder.defaultProps = {
  label: null,
  icon: null,
};

export default WidgetPlaceholder;
