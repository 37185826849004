import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  transform: ${({ direction }) => {
    switch (direction) {
      case 'down':
        return 'rotateX(-180deg)';
      case 'up':
      default:
        return 'rotateX(0deg)';
    }
  }};
`;

const Rating = ({ width, height, color, direction }) => (
  <StyledSvg
    width={width}
    height={height}
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    direction={direction}
  >
    <path
      d="M0.930176 16.6638H4.13018V7.06382H0.930176V16.6638ZM18.5302 7.86382C18.5302
      6.98382 17.8102 6.26382 16.9302 6.26382H11.8822L12.6422 2.60782L12.6662 2.35182C12.6662
      2.02382 12.5302 1.71982 12.3142 1.50382L11.4662 0.663818L6.20218 5.93582C5.90618 6.22382
      5.73018 6.62382 5.73018 7.06382V15.0638C5.73018 15.9438 6.45018 16.6638 7.33018
      16.6638H14.5302C15.1942 16.6638 15.7622 16.2638 16.0022 15.6878L18.4182 10.0478C18.4902
      9.86382 18.5302 9.67182 18.5302 9.46382V7.93582L18.5222 7.92782L18.5302 7.86382Z"
      fill={color}
    />
  </StyledSvg>
);

Rating.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down']),
};

Rating.defaultProps = {
  width: 19,
  height: 17,
  color: 'black',
  direction: 'up',
};

export default Rating;
