import { createSelector } from 'reselect';

import { getUserLocale } from './users';

export const getPartnerSupportUrl = createSelector(
  getUserLocale,
  userLocale =>
    process.env[`REACT_APP_ZENDESK_URL_${userLocale.replace('-', '_')}`],
);

export { getUserLocale };
