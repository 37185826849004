import createReducer from '../common/utils/createReducer';

export const INITIAL_STATE = {
  locale: undefined,
};

const updateLocale = (state, payload) => ({
  ...state,
  locale: payload.locale,
});

const reducers = {
  'user/UPDATE_LOCALE': updateLocale,
};

export default createReducer(INITIAL_STATE, reducers);
