import React from 'react';
import PropTypes from 'prop-types';

import WidgetPlaceholder from './WidgetPlaceholder';

const WidgetContent = ({
  children,
  showEmptyState,
  showPartialDataState,
  emptyStateLabel,
  partialDataStateLabel,
  icon,
}) => {
  if (showEmptyState || showPartialDataState) {
    const label = showEmptyState ? emptyStateLabel : partialDataStateLabel;

    return <WidgetPlaceholder label={label} icon={icon} />;
  }

  return children;
};

WidgetContent.propTypes = {
  children: PropTypes.node,
  showEmptyState: PropTypes.bool,
  showPartialDataState: PropTypes.bool,
  emptyStateLabel: PropTypes.string,
  partialDataStateLabel: PropTypes.string,
  icon: PropTypes.node,
};

WidgetContent.defaultProps = {
  children: null,
  showEmptyState: false,
  showPartialDataState: false,
  emptyStateLabel: null,
  partialDataStateLabel: null,
  icon: null,
};

export default WidgetContent;
