import React from 'react';
import PropTypes from 'prop-types';

const AU = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="21" height="15" rx="2" fill="white" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="16"
    >
      <rect width="21" height="15" rx="2" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <rect width="21" height="15" fill="#0A17A7" />
      <path
        d="M0 -0.333333H-0.901086L-0.21693 0.253086L3.16667 3.15331V3.82846L-0.193746
        6.22876L-0.333333 6.32846V6.5V7V7.60142L0.176666 7.28267L4.0956 4.83333H4.89318L8.26313
        7.24044C8.34771 7.30086 8.44905 7.33333 8.55299 7.33333C9.01598 7.33333 9.22907 6.75732
        8.87754 6.45601L5.83333 3.84669V3.17154L9.05416 0.87095C9.22936 0.745809 9.33333 0.543761
        9.33333 0.328461V0V-0.601416L8.82333 -0.282666L4.9044 2.16667H4.10682L0.693746
        -0.271244L0.606822 -0.333333H0.5H0Z"
        fill="#FF2E3B"
        stroke="white"
        strokeWidth="0.666667"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.5V4.5H3.5V6.83333C3.5 7.20152 3.79848 7.5 4.16667 7.5H4.83333C5.20152 7.5 5.5 7.20152
        5.5 6.83333V4.5H8.83333C9.20152 4.5 9.5 4.20152 9.5 3.83333V3.16667C9.5 2.79848 9.20152 2.5
        8.83333 2.5H5.5V0H3.5V2.5H0Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3H4V2.5V0H5V2.5V3H9V4H5V4.5V7H4V4.5V4H0V3Z"
        fill="url(#paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 12.25L3.61832 12.7135L3.78671 11.7318L3.07342 11.0365L4.05916 10.8932L4.5 10L4.94084
        10.8932L5.92658 11.0365L5.21329 11.7318L5.38168 12.7135L4.5 12.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 13L14.2929 13.2071L14.5 12.5L14.2929 11.7929L15 12L15.7071 11.7929L15.5 12.5L15.7071
        13.2071L15 13Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3.5L14.2929 3.70711L14.5 3L14.2929 2.29289L15 2.5L15.7071 2.29289L15.5 3L15.7071 3.70711L15 3.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 6.5L17.2929 6.70711L17.5 6L17.2929 5.29289L18 5.5L18.7071 5.29289L18.5 6L18.7071 6.70711L18 6.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.5L11.2929 7.70711L11.5 7L11.2929 6.29289L12 6.5L12.7071 6.29289L12.5 7L12.7071 7.70711L12 7.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 8.75L16.1464 8.85355L16.25 8.5L16.1464 8.14645L16.5 8.25L16.8536 8.14645L16.75 8.5L16.8536
        8.85355L16.5 8.75Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="0"
        y1="0"
        x2="0"
        y2="7.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="0"
        y1="0"
        x2="0"
        y2="7"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2E3B" />
        <stop offset="1" stopColor="#FC0D1B" />
      </linearGradient>
    </defs>
  </svg>
);

AU.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

AU.defaultProps = {
  width: 21,
  height: 15,
};

export default AU;
