// This import loads the firebase namespace.
import * as firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/analytics';
import 'firebase/remote-config';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const client = {
  client: null,
  isEnabled: false,
  getClient: async () => {
    if (client.client) {
      return client.client;
    }
    return client.createClient();
  },
  createClient: async () => {
    const Firebase = firebase.initializeApp(firebaseConfig);

    client.isEnabled = await firebase.analytics.isSupported();
    if (client.isEnabled) {
      firebase.analytics();
      firebase.remoteConfig();
    }
    client.client = Firebase;
    return client.client;
  },
  getIsEnabled: () => client.isEnabled,
};

export default client;
