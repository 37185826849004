import React, { useContext, useEffect, useState } from 'react';
import { RemoteConfigContext } from './context';
import firebase from './firebase';

const DEFAULT_REMOTE_CONFIG = {
  portal_subscription_billing_setting_enabled: true,
  show_printer_option: true,
  portal_delivery_config_enabled: true,
  portal_stuart_delivery_config_enabled: false,
  portal_discounts_availability_enabled: false,
  portal_onboarding_checklist_enabled: false,
  menu_disabled_items: '',
  portal_merchant_plans_enabled: true,
  portal_merchant_onboarding_flow_enabled: false,
  portal_scheduled_orders_config_enabled: false,
  portal_refunds_enabled: false,
  portal_pos_menu_enabled: true,
  portal_supported_pos_integration_types: '["SQUARE", "KOUNTA"]',
  portal_share_program_enrolling_enabled: true,
  portal_share_program_popup_enabled: false,
  portal_group_orders_config_enabled: false,
  portal_require_current_account_number: true,
};

const useRemoteConfigValue = defaultConfig => {
  const [remoteConfig, setRemoteConfig] = useState();
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      const firebaseImpl = await firebase.getClient();
      if (firebase.getIsEnabled()) {
        const newRemoteConfig = firebaseImpl.remoteConfig();
        newRemoteConfig.settings = {
          minimumFetchIntervalMillis:
            process.env.REACT_APP_FIREBASE_REFRESH_INTERVAL,
          fetchTimeoutMillis: 10000,
        };
        newRemoteConfig.defaultConfig = defaultConfig;
        newRemoteConfig.fetchAndActivate().then(() => setConfigLoaded(true));
        setRemoteConfig(newRemoteConfig);
      } else {
        setConfigLoaded(true);
      }
    };
    initialize();
  }, []);

  const getBoolean = key => {
    if (firebase.getIsEnabled() && configLoaded) {
      return remoteConfig?.getBoolean(key);
    }
    return defaultConfig[key] === true;
  };

  const getString = key => {
    if (firebase.getIsEnabled() && configLoaded) {
      return remoteConfig?.getString(key);
    }
    return defaultConfig[key];
  };

  const getJSON = key => {
    try {
      const str = getString(key);
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  };

  return {
    firebase,
    remoteConfig,
    configLoaded,
    getBoolean,
    getString,
    getJSON,
  };
};

export const RemoteConfigProvider = ({
  defaultConfig = DEFAULT_REMOTE_CONFIG,
  children,
}) => {
  const remoteConfigValue = useRemoteConfigValue(defaultConfig);
  return (
    <RemoteConfigContext.Provider value={remoteConfigValue}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfig = () => useContext(RemoteConfigContext);
