import { combineReducers } from 'redux';
import { get } from 'lodash';
import createReducer from 'common/utils/createReducer';

const INITIAL_STATE = {};

const LOAD_REQUEST = (state, payload) => {
  if (Array.isArray(payload.url)) {
    return {
      ...state,
      ...payload.url.reduce(
        (obj, { url }) => ({
          ...obj,
          [url]: {
            isFetching: true,
            data: get(state, [url, 'data'], null),
          },
        }),
        {},
      ),
    };
  }
  return {
    ...state,
    [payload.url]: {
      isFetching: true,
      data: get(state, [payload.url, 'data'], null),
      error: null,
    },
  };
};

const LOAD_SUCCESS = (state, payload) => {
  if (Array.isArray(payload.url)) {
    return {
      ...state,
      ...payload.data.reduce(
        (obj, { data, url }) => ({
          ...obj,
          [url]: {
            isFetching: false,
            data,
          },
        }),
        {},
      ),
    };
  }
  return {
    ...state,
    [payload.url]: {
      isFetching: false,
      data: payload.data,
    },
  };
};

const LOAD_ERROR = (state, payload) => ({
  ...state,
  [payload.url]: {
    isFetching: false,
    data: null,
    error: payload.error,
  },
});

const reducers = {
  'api/LOAD_REQUEST': LOAD_REQUEST,
  'api/LOAD_SUCCESS': LOAD_SUCCESS,
  'api/LOAD_ERROR': LOAD_ERROR,
};

export default combineReducers({
  queries: createReducer(INITIAL_STATE, reducers),
});
