import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import locales from './locales';

i18n.use(LanguageDetector).init({
  fallbackLng: 'en-US',
  debug: false,
  resources: locales,
  order: ['localStorage', 'navigator'],
  detection: {
    lookupLocalStorage: 'i18nextLng',
  },
});

export const addNewResources = (resourceSet, namespace = 'c') => {
  Object.keys(locales).forEach(locale => {
    try {
      i18n.addResources(locale, namespace, resourceSet);
    } catch (e) {
      // Occurs when there is something misconfigured from contentful
      // eslint-disable-next-line no-console
      console.error('Could not load all strings', e);
    }
  });
};

export default i18n;
