import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { reqInterceptor, getResponse, getError } from './apiClient';

const axiosConfig = {
  baseURL: process.env.REACT_APP_NODE_API,
  isAuthReq: true,
  headers: {
    common: {},
  },
};

if (
  ['development', 'sandbox', 'qa'].includes(process.env.REACT_APP_STAGE_ENV)
) {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get('server_backend_url')) {
    axiosConfig.headers.common['x-ritual-backend-url'] = searchParams.get(
      'server_backend_url',
    );
  }
}

const api = axios.create(axiosConfig);

api.interceptors.request.use(reqInterceptor);

api.interceptors.response.use(getResponse, getError);

export default api;
