import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Caption } from 'common/components/Typography';
import { LinePlaceholder } from 'common/components/Widget/EmptyWidget';
import theme, { Subtitle1 } from 'common/style/theme';

const NewTag = styled.div`
  background-color: ${props => props.theme.colours.green};
  border-radius: 4px;
  color: ${props => props.theme.rtColors.ui7};
  display: flex;
  margin-left: 16px;
  padding: 3px 8px;
`;

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 16px;
  position: relative;
  width: 26px;
`;

export const StyledNavLink = styled(NavLink)`
  align-items: center;
  color: ${props => props.theme.colours.dolphin};
  display: flex;
  padding: 16px 24px;
  ${IconWrapper} {
    opacity: 0.5;
  }
  &:hover {
    color: ${props => props.theme.rtColors.teal500};
    text-decoration: none;
    ${IconWrapper} {
      opacity: 1;
    }
  }
  &.active {
    pointer-events: none;
    color: ${props => props.theme.rtColors.teal500};
    ${IconWrapper} {
      opacity: 1;
    }
  }
  ${props =>
    props.disabled &&
    css`
    opacity: 0.5;
    pointer-events: none;
    &:hover {
      color: ${props.theme.colours.dolphin}
      text-decoration: none;
    }
  `}
`;

export const IconText = styled(Subtitle1)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const NavItemWrapper = styled.div``;

const NavItem = ({
  to,
  label,
  iconComponent,
  isActive,
  onClick,
  exact,
  isPlaceholder,
  showNewTag,
  disabled,
  ...rest
}) => {
  const IconComponent = iconComponent;

  const [isHovering, setIsHover] = useState(false);
  const iconColor =
    isActive || isHovering ? { color: theme.rtColors.teal500 } : {};

  return (
    <NavItemWrapper
      {...rest}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <StyledNavLink
        to={to}
        onClick={e => {
          if (disabled) {
            e.preventDefault();
          } else {
            onClick(e);
          }
        }}
        exact={exact}
        data-testid={`link-${to}`}
        disabled={disabled}
      >
        <IconWrapper>
          <IconComponent {...iconColor} />
        </IconWrapper>
        {isPlaceholder ? (
          <LinePlaceholder
            width={label.length * 10}
            height="20"
            borderRadius="30"
            backgroundColor={theme.colours.grayLightish}
          />
        ) : (
          <IconText>{label}</IconText>
        )}
        {showNewTag && (
          <NewTag>
            <Caption>NEW</Caption>
          </NewTag>
        )}
      </StyledNavLink>
    </NavItemWrapper>
  );
};

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  label: PropTypes.string.isRequired,
  iconComponent: PropTypes.elementType.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  isPlaceholder: PropTypes.bool,
  showNewTag: PropTypes.bool,
  disabled: PropTypes.bool,
};

NavItem.defaultProps = {
  onClick: undefined,
  exact: false,
  isActive: false,
  isPlaceholder: false,
  showNewTag: false,
  disabled: false,
};

export default NavItem;
