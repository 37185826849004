import React from 'react';
import PropTypes from 'prop-types';

const Remove = ({ width = 24, height = 24, color = 'black' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.48 6.48 2 12 2C17.52
    2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM12 13.4142L14.1213
    15.5355C14.5102 15.9244 15.1466 15.9244 15.5355 15.5355C15.9244 15.1466 15.9244
    14.5102 15.5355 14.1213L13.4142 12L15.5355 9.87868C15.9244 9.48977 15.9244 8.85337
    15.5355 8.46447C15.1466 8.07556 14.5102 8.07556 14.1213 8.46447L12 10.5858L9.87868
    8.46447C9.48977 8.07556 8.85337 8.07556 8.46447 8.46447C8.07556 8.85337 8.07556
    9.48977 8.46447 9.87868L10.5858 12L8.46447 14.1213C8.07556 14.5102 8.07556 15.1466
    8.46447 15.5355C8.85337 15.9244 9.48977 15.9244 9.87868 15.5355L12 13.4142Z"
      fill={color}
    />
  </svg>
);

Remove.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Remove;
