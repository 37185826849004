import styled, { css } from 'styled-components';
import {
  rtColors,
  rtShadows,
  Typography,
} from '@ritualco/ritual-frontend-components';
import { desktop as isDesktop, mobile as isMobile, breakpoints } from './media';

const colours = {
  /*
   * WARNING!!!
   * These colours are officially deprecated
   * Please use rtColors from ritual-frontend-components instead
   * If you touch a file using these colours, migrate them to rtColors
   * WARNING!!!
   */
  background: '#f3f4f7',
  blackDarker: '#000000',
  grayLightish: '#E1E5E9',
  white: '#ffffff',
  dolphin: '#9B9EAE',
  dark: '#333333',
  ui1: '#1A1B1D',
  ui2: '#5C5F6C',
  ui3: '#A5A8B8',
  ui12: '#5C5F6C',
  green: '#54B465',
  red: '#e12122',
  orange: '#F9A34F',
  yellow: '#FFC107',
  lightBlue: '#24C9FB',
  darkBlue: '#4C1EE9',
  turquoise: '#00A6CB',
  tealAction: '#E4F7FA',
  purpleLight: '#FBFBFF',
  // Old colours
  legacy: {
    disabled: '#cccccc',
  },
  darkGreen: '#1B5E20',
  backgroundGreen: '#E8F5E9',
  overcast: '#EDEFF4',
  asphalt: '#707380',
  primary: '#0796c1',
  primaryLight: '#a1e8e5',
  secondary: '#229eb3',
  tertiary: '#e6e8ec',
  danger: '#db305c',
  warning: '#eaa47f',
  warningBackground: 'rgba(251, 234, 229, 0.7)',
  blue: '#007ACE',
  backgroundBlue: '#ebf7fa',
  shadow: 'rgba(0, 0, 0, 0.04)',
  gray: '#b2bec3',
  lighterBlue: '#1EE9E9',
  gray100: '#707380',
  chevronGray: '#FFFAFB',
  grayLightish50: 'rgba(225, 229, 233, 0.5)',
  gray10: 'rgba(237, 239, 244)',
  gray5: 'rgba(237, 239, 244, 0.5)',
  gray20: '#E9ECEF',
  grayLighter: '#efefef',
  grayLight: '#ededed',
  grayDarkish: '#4F4F4F',
  grayDark: '#414141',
  grayDarker: '#383838',
  black: '#1d1f21',
  black10: 'rgba(0, 0, 0, 0.10)',
  black20: 'rgba(0, 0, 0, 0.20)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black80: 'rgba(0, 0, 0, 0.8)',
  dust: '#F3F4F7',
  backgroundLight: '#f6f7f8',
  modalBackground: '#EDEFF4',
  backgroundDark: '#707380',
  backgroundLighter: '#FBFBFB',
  purple: '#263070',
  purpleDarkish: '#8262F0',
  purpleDark: '#4C1EE9',
  red10: 'rgba(225, 33, 34, 0.1)',
  lighterRed: '#FC2562',
  lightRed: 'rgba(233, 148, 168, 0.2)',
  lightGreen: 'rgba(227, 241, 223, 0.6)',
  teal: '#05B4E9',
  magenta: '#FC2562',
  magentaBackground: '#FFFBFC',
  systemRed: '#A42902',
  mediumGreen: '#4DA93B',

  charts: {
    completedOrders: ['#263070'],
    salesComparison: {
      multiLocation: ['#263070', '#05B4E9', '#FC2562'],
      multiYear: ['#263070', 'rgba(38, 48, 112, 0.5)'],
    },
  },
  unHappy: '#DC3545',
};

const boxShadow = {
  soft: '0px 1px 4px rgba(219, 222, 230, 0.5)',
  normal: `0px 3px 20px ${colours.shadow}`,
  heavy: '0px 7px 25px rgba(0, 0, 0, 0.11)',
  card: '0px 6px 18px rgba(219, 222, 229, 0.4)',
};

const layout = {
  footer: {
    height: 75,
    mobileHeight: 40,
  },
  sideNav: {
    expandedWidth: 250,
  },
  topNav: {
    height: 112,
    noTabHeight: 55,
    mobileHeight: 59,
  },
  slideout: {
    maxWidth: 550,
  },
};

const themeVariable = {
  sideNavBackground: colours.white,
};

const text = {
  fontSize: {
    displayXXLarge: '48px',
    displayXLarge: '42px',
    displayLarger: '34px',
    displayLarge: '28px',
    displayMedium: '26px',
    displayMediumSmall: '24px',
    displaySmall: '20px',
    heading: '16px',
    body: '14px',
    small: '12px',
  },
};

const zIndex = {
  navigationBar: 100,
  slideout: 1200,
  sideNav: 1050,
  onboardingModal: 1070,
  tooltip: 100,
  mobile: {
    navigationBar: 101,
  },
};

export const H2Regular = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -0.5px;
`;

export const H4Regular = styled.h4`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 40px;
`;

export const SectionTitle = styled(H4Regular)`
  margin-bottom: 24px;
`;

export const BigValue = styled(H4Regular)`
  font-weight: bold;
`;

export const H3Light = styled.h3`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 56px;
`;

export const PageHeader = styled(H3Light)`
  margin: 48px 34px 24px 34px;
`;

export const Body1Regular = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
`;

export const H5Regular = styled.h5`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
`;

export const H6Regular = styled.h6`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.25px;
`;

export const Subtitle1 = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const Subtitle2 = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
`;

export const Paragraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
`;

const noScrollbars = css`
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const { typeScale } = Typography;

export default {
  breakpoints,
  boxShadow,
  text,
  colours,
  layout,
  zIndex,
  themeVariable,
  rtColors,
  rtShadows,
  typeScale,
  noScrollbars,
  isDesktop,
  isMobile,
};
