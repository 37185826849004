import { Permission } from 'common/auth/constants';
import { RequirementType } from 'common/components/RequiresPermission';

import {
  EMPTY_PATH,
  SALES_PATH,
  SETTING_PATH,
  OFFERS_PATH,
  SHARE_PROGRAM_PATH,
  EARNINGS_PATH,
  FEEDBACKS_PATH,
  LOYALTY_PATH,
  SUPPORT_PATH,
  PROFILE_PATH,
  WEB_PATH,
  MENU_PATH,
  MULTISTORE_SUMMARY_PATH,
  MULTISTORE_TRANSACTION_PATH,
  MULTISTORE_BRAND_GROUP_PATH,
  DISCOUNTS_PATH,
} from '../Portal/paths';
import {
  HomeIcon,
  SalesTabIcon,
  GearIcon,
  SupportIcon,
  ShareCardIcon,
  EarningsIcon,
  FeedbackIcon,
  ProfileIcon,
  LoyaltyIcon,
  WebIcon,
  MenuIcon,
  TransactionsIcon,
  PaletteIcon,
  DiscountsIcon,
} from './icons';

const buildNavItem = ({
  permissions = [Permission.DASHBOARD_READ],
  permissionRequirementType = undefined,
  to,
  label,
  icon,
  exact = false,
  merchantPath = true,
  category = 'pp_navigation',
  screen = 'pp_navigation',
  r1Required = false,
  action,
  showNewTag,
  experiments,
  isInternal,
  group,
  disabled,
}) => ({
  permissions,
  permissionRequirementType,
  to,
  label,
  r1Required,
  icon,
  exact,
  merchantPath,
  analytics: {
    category,
    screen,
    action,
    label: `|${window.location.href}`,
  },
  showNewTag,
  experiments,
  isInternal,
  group,
  disabled,
});

const fullNavList = [
  {
    to: EMPTY_PATH,
    label: 'home',
    icon: HomeIcon,
    exact: true,
    action: 'RIT_click_home',
  },
  {
    permissions: [Permission.SALES_SUMMARY_READ],
    to: SALES_PATH,
    label: 'sales',
    icon: SalesTabIcon,
    action: 'RIT_click_sales',
    group: 'reports',
  },
  {
    permissions: [Permission.MARKETING_READ],
    to: DISCOUNTS_PATH,
    label: 'discounts',
    icon: DiscountsIcon,
    r1Required: true,
    action: 'RIT_click_discounts',
    group: 'growth',
  },
  {
    to: OFFERS_PATH,
    label: 'offers',
    icon: ShareCardIcon,
    action: 'RIT_click_offers',
    group: 'growth',
  },
  {
    to: SHARE_PROGRAM_PATH,
    label: 'share_program',
    icon: ShareCardIcon,
    action: 'RIT_click_offers',
    group: 'growth',
  },
  {
    permissions: [Permission.MERCHANT_LOYALTY_READ],
    to: LOYALTY_PATH,
    label: 'loyalty',
    icon: LoyaltyIcon,
    action: 'RIT_click_loyalty',
    group: 'growth',
  },
  {
    to: EARNINGS_PATH,
    label: 'earnings',
    icon: EarningsIcon,
    action: 'RIT_click_earnings',
    group: 'reports',
  },
  {
    permissions: [
      Permission.STORE_DETAILS_READ,
      Permission.BANKING_INFO_READ,
      Permission.PARTNER_USER_READ,
    ],
    permissionRequirementType: RequirementType.ANY,
    to: SETTING_PATH,
    label: 'settings',
    icon: GearIcon,
    action: 'RIT_click_settings',
    group: 'profile',
  },
  {
    permissions: [Permission.STORE_DETAILS_READ, Permission.PARTNER_USER_READ],
    permissionRequirementType: RequirementType.ANY,
    to: SUPPORT_PATH,
    label: 'support',
    icon: SupportIcon,
    action: 'RIT_click_support',
    group: 'profile',
  },
  {
    to: FEEDBACKS_PATH,
    label: 'feedbacks',
    icon: FeedbackIcon,
    category: '/feedback',
    screen: 'pp_feedback',
    action: 'RIT_page_load_feedback',
    group: 'reports',
  },
  {
    to: MENU_PATH,
    label: 'menu',
    icon: MenuIcon,
    category: '/menu',
    screen: 'pp_menu',
    action: 'RIT_page_load_menu',
    group: 'management',
  },
  {
    permissions: [],
    to: PROFILE_PATH,
    label: 'profile',
    icon: ProfileIcon,
    merchantPath: false,
    action: 'RIT_click_profile',
  },
  {
    to: WEB_PATH,
    label: 'web',
    icon: WebIcon,
    action: 'RIT_click_webbuttons',
    group: 'management',
  },
  {
    to: MULTISTORE_SUMMARY_PATH,
    label: 'multistore_sales',
    icon: SalesTabIcon,
    action: 'RIT_click_ms_sales',
    merchantPath: false,
  },
  {
    to: MULTISTORE_TRANSACTION_PATH,
    label: 'multistore_transactions',
    icon: TransactionsIcon,
    action: 'RIT_click_ms_transactions',
    merchantPath: false,
  },
  {
    to: MULTISTORE_BRAND_GROUP_PATH,
    label: 'multistore_branding',
    icon: PaletteIcon,
    action: 'RIT_click_ms_branding',
    merchantPath: false,
  },
].map(navItem => buildNavItem(navItem));

const constructNavObjList = navList =>
  navList.map(key => fullNavList.find(page => page.label === key));

export const mainMerchantNavList = constructNavObjList([
  'home',
  'sales',
  'earnings',
  'feedbacks',
  'discounts',
  'offers',
  'menu',
  'web',
  'settings',
  'support',
]);

export const loyaltyMerchantNavList = constructNavObjList([
  'home',
  'sales',
  'earnings',
  'feedbacks',
  'discounts',
  'loyalty',
  'offers',
  'menu',
  'web',
  'settings',
  'support',
]);

export const mainMerchantNavListShareProgram = constructNavObjList([
  'home',
  'sales',
  'earnings',
  'feedbacks',
  'discounts',
  'share_program',
  'menu',
  'web',
  'settings',
  'support',
]);

export const loyaltyMerchantNavListShareProgram = constructNavObjList([
  'home',
  'sales',
  'earnings',
  'feedbacks',
  'discounts',
  'loyalty',
  'share_program',
  'menu',
  'web',
  'settings',
  'support',
]);

export const profileNavList = constructNavObjList(['profile']);

export const multistoreNavList = constructNavObjList([
  'multistore_sales',
  'multistore_transactions',
]);

export const r1MultiStoreNavList = constructNavObjList([
  'multistore_sales',
  'multistore_transactions',
  'multistore_branding',
]);

export default fullNavList;
