import React from 'react';
import PropTypes from 'prop-types';

const CA = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="20.5"
      height="14.5"
      rx="1.75"
      fill="white"
      stroke="#F5F5F5"
      strokeWidth="0.5"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="21"
      height="15"
    >
      <rect
        x="0.25"
        y="0.25"
        width="20.5"
        height="14.5"
        rx="1.75"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect x="15" width="6" height="15" fill="#FF3131" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15H6V0H0V15Z"
        fill="#FF3131"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2676 6.73241C12.0376 6.96235 11.6491 6.75442 11.7129 6.43554L12
        5L11 5.5L10.5 4L10 5.5L9 5L9.28711 6.43554C9.35088 6.75442 8.96235 6.96235
        8.73241 6.73241C8.60405 6.60405 8.39595 6.60405 8.26759 6.73241L8 7L7 6.5L7.5
        7.5L7.2357 7.7643C7.10553 7.89447 7.10553 8.10553 7.2357 8.2357L8.5 9.5H10L10.25
        11H10.75L11 9.5H12.5L13.7643 8.2357C13.8945 8.10553 13.8945 7.89447 13.7643 7.7643L13.5
        7.5L14 6.5L13 7L12.7324 6.73241C12.6041 6.60405 12.3959 6.60405 12.2676 6.73241Z"
        fill="#FF3131"
      />
    </g>
  </svg>
);

CA.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

CA.defaultProps = {
  width: 21,
  height: 15,
};

export default CA;
