import './wdyr';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import 'react-dates/initialize';

import 'react-dates/lib/css/_datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import i18n from './i18n';
import theme from './common/style/theme';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    height: 100%;
    color: ${props => props.theme.rtColors.ui1};
  }
  #root {
    height: 100%;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0;
  }
`;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </I18nextProvider>,
  document.getElementById('root'),
);

window.addEventListener('error', err => {
  if (err.name === 'ChunkLoadError') {
    window.location.reload();
  }
});
