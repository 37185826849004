import styled from 'styled-components';
import theme from 'common/style/theme';
import { mobile } from 'common/style/media';

const Card = styled.div`
  background: #fff;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: ${theme.boxShadow.normal};
`;

export const MediumCard = styled.div`
  background: ${theme.rtColors.ui7};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 32px;
  box-shadow: ${theme.boxShadow.soft};
  ${mobile`
    padding: 24px;
  `}
`;

export default Card;
