/* eslint-disable import/prefer-default-export */

import React from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { AlertList } from 'react-bs-notifier';
import { createStructuredSelector } from 'reselect';
import { compose } from 'lodash/fp';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';

import createReducer from '../utils/createReducer';

const runErrorHandler = ({ error, dispatch } = {}) => {
  Sentry.captureException(error);
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('handleError', error);
  }
  const errorLabel = _.get(error, 'response.data.label');
  const errorType = _.get(error, 'errorType');
  if (errorLabel === 'MERCHANT/PHONE_NUMBER_VALIDATION_FAILURE') {
    dispatch({ type: 'errors/INVALID_PHONE_NUMBER' });
  } else if (errorType === 'bankingInfoValidation') {
    dispatch({ type: 'errors/FORM_VALIDATION_FAILED' });
  } else {
    dispatch({ type: 'errors/UNEXPECTED_ERROR' });
  }
};

export const handleError = error => dispatch =>
  runErrorHandler({ error, dispatch });

const dismissError = timestamp => ({
  type: 'errors/DISMISS_ERROR',
  payload: { timestamp },
});

export const userErrorAction = ({ message, headline }) => ({
  type: 'errors/USER_ERROR',
  payload: { message, headline },
});

export const reducer = createReducer([], {
  'errors/UNEXPECTED_ERROR': state =>
    state.concat({
      timestamp: new Date().getTime(),
    }),
  'errors/INVALID_PHONE_NUMBER': state =>
    state.concat({
      timestamp: new Date().getTime(),
      message: 'invalidPhoneNumberError',
    }),
  'errors/FORM_VALIDATION_FAILED': state =>
    state.concat({
      timestamp: new Date().getTime(),
      message: 'formValidationFailed',
    }),
  'errors/USER_ERROR': (state, { message, headline }) =>
    state.concat({
      timestamp: new Date().getTime(),
      message,
      headline,
    }),
  'errors/DISMISS_ERROR': (state, { timestamp }) =>
    state.filter(error => error.timestamp !== timestamp),
});

export const withErrorHandler = WrappedComponent => {
  const WithErrorHandler = ({ handleError: handleErrorAction, ...props }) => (
    <WrappedComponent {...props} handleError={handleErrorAction} />
  );

  WithErrorHandler.propTypes = {
    handleError: PropTypes.func.isRequired,
  };

  return connect(null, { handleError })(WithErrorHandler);
};

export const useErrorHandler = () => {
  const dispatch = useDispatch();
  return error => runErrorHandler({ error, dispatch });
};

export const getErrorList = state => state.errors;

export const ErrorList = compose([
  connect(
    createStructuredSelector({
      errors: getErrorList,
    }),
    { dismissError },
  ),
  withTranslation('errors'),
])(({ errors, t, dismissError: dismissErrorLocal }) => (
  <AlertList
    alerts={errors.map(error => ({
      id: error.timestamp,
      type: 'danger',
      message: error.message ? t(error.message) : t('unexpectedError'),
      headline: error.headline || t('errorHeadLine'),
    }))}
    position="top-left"
    timeout={6000}
    onDismiss={alert => dismissErrorLocal(alert.id)}
  />
));

const UnexpectedErrorWrapper = styled.div`
  padding: 20px;
`;

export const UnexpectedError = withTranslation('errors')(({ t, ...rest }) => (
  <UnexpectedErrorWrapper {...rest}>
    <Alert color="danger">{t('unexpectedError')}</Alert>
  </UnexpectedErrorWrapper>
));
