/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const RepeatComponent = ({ times, render }) => (
  <Fragment>
    {Array(times)
      .fill()
      .map((_, idx) => render(idx))}
  </Fragment>
);

RepeatComponent.propTypes = {
  times: PropTypes.number,
  render: PropTypes.func.isRequired,
};

RepeatComponent.defaultProps = {
  times: 0,
};
