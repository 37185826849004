import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7036 1.56293L10.6436 0.50293L6.17355 4.97293L1.70355 0.50293L0.643555 1.56293L5.11356
      6.03293L0.643555 10.5029L1.70355 11.5629L6.17355 7.09293L10.6436 11.5629L11.7036 10.5029L7.23356
      6.03293L11.7036 1.56293Z"
      fill={color}
    />
  </svg>
);

CloseIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

CloseIcon.defaultProps = {
  color: 'black',
  size: 12,
};

export default CloseIcon;
