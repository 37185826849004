import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import theme from 'common/style/theme';

const ResponsiveImage = ({ id, className = null, options = {} }) => {
  const { t } = useTranslation('c');

  try {
    const modifiedId = `${id.slice(0, -4)}_img`;
    const image = t(modifiedId, { returnObjects: true });
    if (!image) {
      return id;
    }

    const { alt, title, mobile, desktop } = image;
    const query = qs.stringify(options, { addQueryPrefix: true });

    return (
      <picture className={className}>
        <source
          media={`(min-width: ${theme.breakpoints.desktopMin}px)`}
          srcSet={desktop ? `${desktop}${query}` : ''}
        />
        <img
          style={{ width: '100%', height: '100%' }}
          src={mobile ? `${mobile}${query}` : ''}
          alt={alt}
          title={title}
        />
      </picture>
    );
  } catch (e) {
    return id;
  }
};

ResponsiveImage.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.shape({
    h: PropTypes.string,
  }),
};

export default ResponsiveImage;
