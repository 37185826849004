import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { useEffectOnce } from 'react-use';

import { hydrateAuth } from 'common/auth/actions';
import { getAuthError, getAuthHydrated } from 'common/auth/selectors';
import LoadingScreen from 'common/components/Loading';
import { getUserLocale } from 'selectors';

const BootstrapProps = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

const Bootstrap = ({ children }) => {
  const dispatch = useDispatch();
  const authHydrated = useSelector(getAuthHydrated);
  const userLocale = useSelector(getUserLocale);
  const error = useSelector(getAuthError);
  const [resourceSet, setResourceSet] = useState({});

  useEffectOnce(() => {
    const fetchData = async () => {
      const result = await hydrateAuth()(dispatch);
      setResourceSet(result.resourceSet);
    };
    fetchData();
  });

  if (authHydrated) {
    return (
      <IntlProvider
        locale={userLocale.replace('_', '-')}
        defaultLocale="en-US"
        messages={resourceSet}
      >
        {children}
      </IntlProvider>
    );
  }
  if (error) {
    return <div>An error has occurred</div>;
  }
  return <LoadingScreen size={64} />;
};

Bootstrap.propTypes = BootstrapProps;

export default Bootstrap;
