import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

import GeneralErrorPage from 'common/components/GeneralErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // render fallback UI
      return (
        <GeneralErrorPage
          title="Oops, something went wrong"
          // eslint-disable-next-line max-len
          desc="We're sorry that this happened, and we'll look into it!"
        />
      );
    }

    // when there's not an error, render children untouched
    // eslint-disable-next-line react/prop-types, react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
