import React from 'react';
import styled from 'styled-components';

import {
  Arrow,
  Profile as ProfileIcon,
  Lock as LockIcon,
  SupportIcon,
} from 'common/components/Icons';

export const HomeIcon = ({ color }) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    shapeRendering="geometricprecision"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      fill={color || 'black'}
      clipRule="evenodd"
      d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z"
    />
  </svg>
);

export const ShareCardIcon = ({ color }) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      fill={color || 'black'}
      clipRule="evenodd"
      d="M19.554 8.21125e-07H2.44597C2.11662 -0.000275497 1.79047 0.069189 1.4874 0.204305C1.18432 0.339421 0.910446 0.537385 0.682173 0.786255C0.4539 1.03513 0.276092 1.32981 0.159181 1.65257C0.0422705 1.97534 -0.0112182 2.31958 0.00195573 2.66456V13.3354C-0.0112182 13.6804 0.0422705 14.0247 0.159181 14.3474C0.276092 14.6702 0.4539 14.9649 0.682173 15.2137C0.910446 15.4626 1.18432 15.6605 1.4874 15.7956C1.79047 15.9307 2.11662 16.0003 2.44597 16H19.554C19.8834 16.0003 20.2092 15.9307 20.5123 15.7956C20.8154 15.6605 21.0893 15.4626 21.3175 15.2137C21.5458 14.9649 21.7239 14.6702 21.8408 14.3474C21.9577 14.0247 22.0112 13.6804 21.998 13.3354V2.66456C22.0112 2.31958 21.9577 1.97534 21.8408 1.65257C21.7239 1.32981 21.5458 1.03513 21.3175 0.786255C21.0893 0.537385 20.8154 0.339421 20.5123 0.204305C20.2092 0.069189 19.8834 -0.000275497 19.554 8.21125e-07ZM16.5477 7.40429L12.063 12.1185C11.9244 12.2645 11.7599 12.3804 11.5785 12.4594C11.3971 12.5385 11.2024 12.5792 11.006 12.5792C10.8095 12.5792 10.6152 12.5385 10.4337 12.4594C10.2523 12.3804 10.0875 12.2645 9.94894 12.1185L5.46428 7.40429C5.33866 7.27334 5.23904 7.11767 5.17101 6.94619C5.10299 6.77471 5.06779 6.59085 5.06779 6.40513C5.06779 6.21941 5.10299 6.03555 5.17101 5.86407C5.23904 5.69259 5.33866 5.53684 5.46428 5.40589L6.40525 4.40673C6.53121 4.27465 6.68071 4.16985 6.8453 4.09836C7.0099 4.02688 7.18625 3.99007 7.36442 3.99007C7.54258 3.99007 7.71894 4.02688 7.88353 4.09836C8.04812 4.16985 8.19762 4.27465 8.32358 4.40673L10.8899 7.10968C10.9215 7.14029 10.9629 7.15729 11.006 7.15729C11.049 7.15729 11.0904 7.14029 11.122 7.10968L13.6884 4.40673C13.8143 4.27465 13.9638 4.16985 14.1284 4.09836C14.293 4.02688 14.4694 3.99007 14.6475 3.99007C14.8257 3.99007 15.002 4.02688 15.1666 4.09836C15.3312 4.16985 15.481 4.27465 15.607 4.40673L16.5477 5.40589C16.7867 5.66809 16.9203 6.01681 16.9203 6.37948C16.9203 6.74216 16.7867 7.09088 16.5477 7.35308V7.40429Z"
    />
  </svg>
);

export const LoyaltyIcon = ({ color }) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      fill={color || '#333333'}
      clipRule="evenodd"
      d="M1.12148 7.14176C0.576859 6.35059 0.636081 5.2873 1.26625 4.56351L4.60466 0.708604C4.99737 0.256275 5.56145 0 6.15885 0H13.8411C14.4386 0 15.0028 0.256508 15.3955 0.708837L18.7336 4.56332C19.3639 5.28712 19.4232 6.35052 18.8785 7.14176L11.6916 17.604C11.3934 18.0383 10.958 18.3285 10.4722 18.4478L10.4429 18.455H10.435L10.4249 18.457C10.4183 18.4583 10.4099 18.4597 10.4001 18.4607C10.2742 18.4838 10.1424 18.5 10 18.5C9.86076 18.5 9.71661 18.4828 9.59088 18.4576L9.57562 18.4546L9.56445 18.4509L9.56086 18.4501L9.55944 18.4499C9.55085 18.4482 9.5335 18.4447 9.51237 18.4383C9.03068 18.3181 8.59754 18.0288 8.30784 17.6031L1.12148 7.14176ZM13.8481 1.4149C14.0368 1.40966 14.2138 1.49276 14.3411 1.64214L17.3746 5.145H14.0644L12.2319 1.415L13.8411 1.4151L13.8481 1.4149ZM2.61919 5.1375L5.65729 1.64405L5.65893 1.64214C5.78214 1.49755 5.96661 1.415 6.15885 1.415H7.76778L5.93573 5.1375H2.61919ZM12.7162 6.5525L10.0003 16.2426L7.29107 6.5525H12.7162ZM9.32297 1.4075L7.49049 5.1375H12.502L10.6695 1.4075H9.32297ZM8.19106 14.9655L7.04018 13.2935L2.41388 6.5525H5.83422L8.19106 14.9655ZM17.5861 6.5525L11.8101 14.9677L14.1658 6.5525H17.5861Z"
    />
  </svg>
);

export const SalesTabIcon = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    shapeRendering="geometricprecision"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      fill={color || 'black'}
      clipRule="evenodd"
      d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM4 14H6V7H4V14ZM10 14H8V4H10V14ZM12 14H14V10H12V14Z"
    />
  </svg>
);

export const GearIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    shapeRendering="geometricprecision"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      fill={color || 'black'}
      clipRule="evenodd"
      d="M17.5 10C17.5 10.34 17.47 10.66 17.43 10.98L19.54 12.63C19.73 12.78 19.78 13.05 19.66 13.27L17.66 16.73C17.54 16.95 17.28 17.04 17.05 16.95L14.56 15.95C14.04 16.34 13.48 16.68 12.87 16.93L12.49 19.58C12.46 19.82 12.25 20 12 20H7.99996C7.74996 20 7.53996 19.82 7.50996 19.58L7.12996 16.93C6.51996 16.68 5.95996 16.35 5.43996 15.95L2.94996 16.95C2.72996 17.03 2.45996 16.95 2.33996 16.73L0.339961 13.27C0.219961 13.05 0.269961 12.78 0.459961 12.63L2.56996 10.98C2.52996 10.66 2.49996 10.33 2.49996 10C2.49996 9.67 2.52996 9.34 2.56996 9.02L0.459961 7.37C0.269961 7.22 0.209961 6.95 0.339961 6.73L2.33996 3.27C2.45996 3.05 2.71996 2.96 2.94996 3.05L5.43996 4.05C5.95996 3.66 6.51996 3.32 7.12996 3.07L7.50996 0.42C7.53996 0.18 7.74996 0 7.99996 0H12C12.25 0 12.46 0.18 12.49 0.42L12.87 3.07C13.48 3.32 14.04 3.65 14.56 4.05L17.05 3.05C17.27 2.97 17.54 3.05 17.66 3.27L19.66 6.73C19.78 6.95 19.73 7.22 19.54 7.37L17.43 9.02C17.47 9.34 17.5 9.66 17.5 10ZM6.49996 10C6.49996 11.93 8.06996 13.5 9.99996 13.5C11.93 13.5 13.5 11.93 13.5 10C13.5 8.07 11.93 6.5 9.99996 6.5C8.06996 6.5 6.49996 8.07 6.49996 10Z"
    />
  </svg>
);

export const EarningsIcon = ({ color }) => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color || 'black'}
      d="M18.4741 0.0148926H2.47412C1.36412 0.0148926 0.484121 0.904893 0.484121 2.01489L0.474121 14.0149C0.474121 15.1249 1.36412 16.0149 2.47412 16.0149H18.4741C19.5841 16.0149 20.4741 15.1249 20.4741 14.0149V2.01489C20.4741 0.904893 19.5841 0.0148926 18.4741 0.0148926ZM18.4741 14.0149H2.47412V8.01489H18.4741V14.0149ZM18.4741 4.01489H2.47412V2.01489H18.4741V4.01489Z"
    />
  </svg>
);

export const FeedbackIcon = ({ color }) => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      fill={color || '#333333'}
      clipRule="evenodd"
      d="M2 0H18C19.1 0 20 0.9 20 2V16C20 17.1 19.1 18 18 18H4L0 22L0.01 2C0.01 0.9 0.9 0 2 0ZM6.52633 9.21152C6.52633 11.3038 8.25409 12.9 10.3464 12.9C12.4387 12.9 14.1033 11.3038 14.1033 9.21152C14.1033 9.20817 14.0595 9.2241 13.9788 9.2535C13.5294 9.41707 11.934 9.99783 10.3464 10C8.64404 10.0023 6.94961 9.36858 6.59423 9.23567C6.54983 9.21906 6.52633 9.21027 6.52633 9.21152ZM4.63159 6.26316C4.63159 6.96078 5.19713 7.52632 5.89475 7.52632C6.59237 7.52632 7.15791 6.96078 7.15791 6.26316C7.15791 5.56554 6.59237 5 5.89475 5C5.19713 5 4.63159 5.56554 4.63159 6.26316ZM13.4737 6.26316C13.4737 6.96078 14.0392 7.52632 14.7369 7.52632C15.4345 7.52632 16 6.96078 16 6.26316C16 5.56554 15.4345 5 14.7369 5C14.0392 5 13.4737 5.56554 13.4737 6.26316Z"
    />
  </svg>
);

export const MenuIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      fill={color || 'black'}
      d="M8.1 13.34l2.83-2.83L3.91 3.5c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L3.7 19.87l1.41 1.41L12 14.41l6.88 6.88 1.41-1.41L13.41 13l1.47-1.47z"
    />
  </svg>
);

export const WebIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      fill={color || '#333333'}
      clipRule="evenodd"
      d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4ZM14.5 9H4V12.5H14.5V9ZM4 14.5H14.5V18H4V14.5ZM16.5 18H20V9H16.5V18Z"
    />
  </svg>
);

export const DiscountsIcon = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.17969 5H16.1797C16.7297 5 17.1797 4.55 17.1797 4C17.1797 3.45 16.7297 3 16.1797 3H3.17969C2.62969 3 2.17969 3.45 2.17969 4C2.17969 4.55 2.62969 5 3.17969 5Z"
      fill={color || '#333333'}
    />
    <path
      d="M15.1798 16H17.1798V13H17.3598C17.9898 13 18.4598 12.42 18.3398 11.8L17.3398 6.8C17.2498 6.34 16.8398 6 16.3598 6H2.99976C2.51976 6 2.10976 6.34 2.01976 6.8L1.01976 11.8C0.899761 12.42 1.36976 13 1.99976 13H2.17976V18C2.17976 18.55 2.62976 19 3.17976 19H10.1798C10.7298 19 11.1798 18.55 11.1798 18V13H15.1798V16ZM9.17976 17H4.17976V13H9.17976V17Z"
      fill={color || '#333333'}
    />
    <path
      d="M22.1797 17H20.1797V15C20.1797 14.45 19.7297 14 19.1797 14C18.6297 14 18.1797 14.45 18.1797 15V17H16.1797C15.6297 17 15.1797 17.45 15.1797 18C15.1797 18.55 15.6297 19 16.1797 19H18.1797V21C18.1797 21.55 18.6297 22 19.1797 22C19.7297 22 20.1797 21.55 20.1797 21V19H22.1797C22.7297 19 23.1797 18.55 23.1797 18C23.1797 17.45 22.7297 17 22.1797 17Z"
      fill={color || '#333333'}
    />
  </svg>
);

export const TransactionsIcon = ({ color }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color || '#333333'}
      d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM4 14H6V7H4V14ZM10 14H8V4H10V14ZM12 14H14V10H12V14Z"
    />
    <rect x="8" y="4" width="2" height="10" fill={color || '#333333'} />
    <rect x="12" y="4" width="2" height="10" fill={color || '#333333'} />
    <rect x="4" y="4" width="2" height="10" fill={color || '#333333'} />
    <path
      d="M3 6.54395H14.0875"
      stroke="white"
      strokeWidth="0.912548"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.543 4L14.0867 6.54373"
      stroke="white"
      strokeWidth="0.912548"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0867 6.54395L11.543 9.08767"
      stroke="white"
      strokeWidth="0.912548"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0875 11.3682H3"
      stroke="white"
      strokeWidth="0.912548"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.54373 13.9119L3 11.3682"
      stroke="white"
      strokeWidth="0.912548"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 11.3689L5.54373 8.8252"
      stroke="white"
      strokeWidth="0.912548"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PaletteIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path
      fill={color || ''}
      d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
    />
  </svg>
);

const ArrowStyled = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackIcon = () => (
  <ArrowStyled>
    <Arrow direction="left" width={14} height={14} />
  </ArrowStyled>
);

export { ProfileIcon, LockIcon, SupportIcon };
