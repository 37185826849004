import React from 'react';
import PropTypes from 'prop-types';

const Profile = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0H2C0.89 0 0 0.9 0
      2ZM12 6C12 7.66 10.66 9 9 9C7.34 9 6 7.66 6 6C6 4.34 7.34 3 9 3C10.66 3 12 4.34 12 6ZM9 10.9C7
      10.9 3 12 3 14V15H15V14C15 12 11 10.9 9 10.9Z"
      fill={color}
    />
  </svg>
);

Profile.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Profile.defaultProps = {
  color: 'black',
  width: 18,
  height: 18,
};

export default Profile;
