import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { RepeatComponent } from 'common/utils';
import Card from 'common/components/Card';
import theme from 'common/style/theme';
import { withTranslation } from 'react-i18next';

export const LinePlaceholder = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => props.borderRadius}px;
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  ${props =>
    props.withBorderBottom &&
    `
    border-bottom: 1px solid ${props.theme.colours.grayLightish}
    padding-bottom: 16px;
  `}
`;

const WidgetWrapper = styled.div`
  padding-bottom: 1.25rem;
  height: 100%;
`;

const WidgetCard = styled(Card)`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 0;
  margin-bottom: 10px;
  ${Line} {
    margin-bottom: 16px;
  }
`;

export const EmptyWidget = () => (
  <WidgetWrapper>
    <WidgetCard>
      <Line>
        <LinePlaceholder
          width="160"
          height="20"
          borderRadius="30"
          backgroundColor={theme.colours.grayLightish}
        />
        <LinePlaceholder
          width="23"
          height="23"
          borderRadius="2"
          backgroundColor={theme.colours.backgroundLight}
        />
      </Line>
      <Line withBorderBottom>
        <LinePlaceholder
          width="67"
          height="15"
          borderRadius="30"
          backgroundColor={theme.colours.grayLightish}
        />
        <LinePlaceholder
          width="62"
          height="9"
          borderRadius="30"
          backgroundColor={theme.colours.backgroundLight}
        />
      </Line>
      <Line withBorderBottom>
        <LinePlaceholder
          width="67"
          height="15"
          borderRadius="30"
          backgroundColor={theme.colours.grayLightish}
        />
        <LinePlaceholder
          width="62"
          height="9"
          borderRadius="30"
          backgroundColor={theme.colours.backgroundLight}
        />
        <LinePlaceholder
          width="119"
          height="9"
          borderRadius="30"
          backgroundColor={theme.colours.backgroundLight}
        />
      </Line>
      <Line>
        <LinePlaceholder
          width="147"
          height="20"
          borderRadius="30"
          backgroundColor={theme.colours.grayLightish}
        />
        <LinePlaceholder
          width="173"
          height="32"
          borderRadius="30"
          backgroundColor={theme.colours.backgroundLight}
        />
      </Line>
    </WidgetCard>
  </WidgetWrapper>
);

const LineGraph = styled(LinePlaceholder)`
  margin-right: 24px;
`;

const GraphRow = styled(Row)`
  align-items: flex-end;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const EmptyGraphWidget = () => (
  <WidgetWrapper>
    <WidgetCard>
      <Line>
        <LinePlaceholder
          width="160"
          height="20"
          borderRadius="30"
          backgroundColor={theme.colours.grayLightish}
        />
        <LinePlaceholder
          width="23"
          height="23"
          borderRadius="2"
          backgroundColor={theme.colours.backgroundLight}
        />
      </Line>
      <Row style={{ marginTop: '50px', marginBottom: '28px' }}>
        <Col
          md="1"
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <RepeatComponent
            times={5}
            render={idx => (
              <LinePlaceholder
                key={idx}
                width="34"
                height="15"
                borderRadius="30"
                backgroundColor={theme.colours.grayLightish}
              />
            )}
          />
        </Col>
        <Col md="11">
          <GraphRow>
            <RepeatComponent
              times={10}
              render={idx => (
                <Fragment key={idx}>
                  <LineGraph
                    width="17"
                    height="93"
                    borderRadius="30"
                    backgroundColor={theme.colours.grayLightish}
                  />
                  <LineGraph
                    width="17"
                    height="138"
                    borderRadius="30"
                    backgroundColor={theme.colours.grayLightish}
                  />
                  <LineGraph
                    width="17"
                    height="173"
                    borderRadius="30"
                    backgroundColor={theme.colours.grayLightish}
                  />
                </Fragment>
              )}
            />
          </GraphRow>
        </Col>
      </Row>
    </WidgetCard>
  </WidgetWrapper>
);

export const EmptyPayroll = withTranslation('dashboard')(({ t }) => (
  <WidgetWrapper>
    <WidgetCard>
      <Line>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ marginRight: '20px', fontSize: '32px' }}>
            {t('last4Week')}
          </div>
          <LinePlaceholder
            width="174"
            height="20"
            borderRadius="30"
            backgroundColor={theme.colours.grayLightish}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginRight: '14px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <LinePlaceholder
              width="110"
              height="9"
              borderRadius="30"
              backgroundColor={theme.colours.backgroundLight}
              style={{ marginBottom: '6px' }}
            />
            <LinePlaceholder
              width="152"
              height="9"
              borderRadius="30"
              backgroundColor={theme.colours.backgroundLight}
            />
          </div>
          <LinePlaceholder
            width="118"
            height="20"
            borderRadius="30"
            backgroundColor={theme.colours.backgroundLight}
          />
        </div>
      </Line>
    </WidgetCard>
  </WidgetWrapper>
));
