import styled from 'styled-components';
import {
  Overhead,
  Caption,
  BodySmall,
  Body,
  BodyLarge,
  H6,
  H5,
  H4,
  H3,
  H2,
  H1,
  Display,
  Jumbo,
  Mega,
  typeScale,
} from '@ritualco/ritual-frontend-components/build/components/Typography';

const TitleData = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const BodySmallText = styled.div`
  font-size: 14px;
`;

const ErrorText = styled(BodySmallText)`
  color: ${props => props.theme.colours.red};
`;

const BodySecondaryCardText = styled.div`
  font-size: 14px;
  color: #9b9eae;
`;

export {
  TitleData,
  BodySmallText,
  ErrorText,
  BodySecondaryCardText,
  Overhead,
  Caption,
  BodySmall,
  Body,
  BodyLarge,
  H6,
  H5,
  H4,
  H3,
  H2,
  H1,
  Display,
  Jumbo,
  Mega,
  typeScale,
};
