import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Logo from 'common/components/Icons/Logo';
import theme from 'common/style/theme';

const Page = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

const LogoWrapper = styled.div`
  margin-bottom: 70px;
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 60px;
`;

const Warning = styled.div`
  width: 65%;
`;

const IEPage = ({ t }) => (
  <Page>
    <Container>
      <LogoWrapper>
        <Logo
          color={theme.colours.black}
          chevronColor={theme.colours.chevronGray}
          width={150}
        />
      </LogoWrapper>
      <Header>{t('header')}</Header>
      <Warning>{t('warning')}</Warning>
    </Container>
  </Page>
);

IEPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('global')(IEPage);
