/* eslint import/prefer-default-export: 0 */

export const Permission = {
  UNKNOWN_PERMISSION: 'UNKNOWN_PERMISSION',
  MERCHANT_TERMS_READ: 'MERCHANT_TERMS_READ',
  MERCHANT_TERMS_MANAGE: 'MERCHANT_TERMS_MANAGE',
  MERCHANT_AGREEMENT_READ: 'MERCHANT_AGREEMENT_READ',
  MERCHANT_SEARCH: 'MERCHANT_SEARCH',
  MERCHANT_AGREEMENT_MANAGE: 'MERCHANT_AGREEMENT_MANAGE',
  MERCHANT_IN_APP_DETAILS_READ: 'MERCHANT_IN_APP_DETAILS_READ',
  MERCHANT_IN_APP_DETAILS_MANAGE: 'MERCHANT_IN_APP_DETAILS_MANAGE',
  CONTACT_INFO_READ: 'CONTACT_INFO_READ',
  CONTACT_INFO_MANAGE: 'CONTACT_INFO_MANAGE',
  BANKING_INFO_READ: 'BANKING_INFO_READ',
  BANKING_INFO_MANAGE: 'BANKING_INFO_MANAGE',
  SUMMARY_CONFIRMATION_READ: 'SUMMARY_CONFIRMATION_READ',
  SUMMARY_CONFIRMATION_MANAGE: 'SUMMARY_CONFIRMATION_MANAGE',
  DASHBOARD_READ: 'DASHBOARD_READ',
  SALES_SUMMARY_READ: 'SALES_SUMMARY_READ',
  MARKETING_READ: 'MARKETING_READ',
  MARKETING_MANAGE: 'MARKETING_MANAGE',
  STORE_DETAILS_READ: 'STORE_DETAILS_READ',
  STORE_DETAILS_MANAGE: 'STORE_DETAILS_MANAGE',
  PARTNER_USER_READ: 'PARTNER_USER_READ',
  PARTNER_USER_MANAGE: 'PARTNER_USER_MANAGE',
  FORM_READ: 'FORM_READ',
  FORM_MANAGE: 'FORM_MANAGE',
  MERCHANT_DATA_READ: 'MERCHANT_DATA_READ',
  VIEW_ORDER_DETAILS: 'VIEW_ORDER_DETAILS',
  SKIP_ONBOARDING: 'SKIP_ONBOARDING',
  CUSTOMER_SUPPORT_TICKET_READ: 'CUSTOMER_SUPPORT_TICKET_READ',
  INTERNAL_TEST: 'INTERNAL_TEST',
  MERCHANT_OPERATIONS_CONFIG_MANAGE: 'MERCHANT_OPERATIONS_CONFIG_MANAGE',
  MERCHANT_OPERATIONS_CONFIG_READ: 'MERCHANT_OPERATIONS_CONFIG_READ',
  MERCHANT_LOCATION_READ: 'MERCHANT_LOCATION_READ',
  MERCHANT_LOCATION_MANAGE: 'MERCHANT_LOCATION_MANAGE',
  MERCHANT_STATE_READ: 'MERCHANT_STATE_READ',
  MERCHANT_STATE_MANAGE: 'MERCHANT_STATE_MANAGE',
  MERCHANT_STORE_INFO_READ: 'MERCHANT_STORE_INFO_READ',
  MERCHANT_STORE_INFO_MANAGE: 'MERCHANT_STORE_INFO_MANAGE',
  MERCHANT_FEES_READ: 'MERCHANT_FEES_READ',
  MERCHANT_FEES_MANAGE: 'MERCHANT_FEES_MANAGE',
  MERCHANT_CREATE: 'MERCHANT_CREATE',
  MERCHANT_HOMESCREEN_IMAGE_MANAGE: 'MERCHANT_HOMESCREEN_IMAGE_MANAGE',
  MERCHANT_HOMESCREEN_IMAGE_READ: 'MERCHANT_HOMESCREEN_IMAGE_READ',
  MERCHANT_HOURS_READ: 'MERCHANT_HOURS_READ',
  MERCHANT_HOURS_MANAGE: 'MERCHANT_HOURS_MANAGE',
  MERCHANT_MENU_VIEW: 'MERCHANT_MENU_VIEW',
  MERCHANT_MENU_ASSIGNMENT: 'MERCHANT_MENU_ASSIGNMENT',
  OFFERS_READ: 'OFFERS_READ',
  OFFERS_MANAGE: 'OFFERS_MANAGE',
  DEVICE_LINK: 'DEVICE_LINK',
  DEVICE_READ: 'DEVICE_READ',
  DEVICE_UNLINK: 'DEVICE_UNLINK',
  MERCHANT_PAYMENT_INFO_MANAGE: 'MERCHANT_PAYMENT_INFO_MANAGE',
  MERCHANT_PAYMENT_INFO_READ: 'MERCHANT_PAYMENT_INFO_READ',
  MERCHANT_LOYALTY_MANAGE: 'MERCHANT_LOYALTY_MANAGE',
  MERCHANT_LOYALTY_READ: 'MERCHANT_LOYALTY_READ',
  MERCHANT_BIG_BANG_CONFIG_READ: 'MERCHANT_BIG_BANG_CONFIG_READ',
  MERCHANT_BIG_BANG_CONFIG_MANAGE: 'MERCHANT_BIG_BANG_CONFIG_MANAGE',
  BRANDING_READ: 'BRANDING_READ',
  BRANDING_MANAGE: 'BRANDING_MANAGE',
  MERCHANT_BRAND_READ: 'MERCHANT_BRAND_READ',
  MERCHANT_BRAND_MANAGE: 'MERCHANT_BRAND_MANAGE',
  MERCHANT_DELIVERY_CONFIG_MANAGE: 'MERCHANT_DELIVERY_CONFIG_MANAGE',
  MERCHANT_DELIVERY_CONFIG_READ: 'MERCHANT_DELIVERY_CONFIG_READ',
  MERCHANT_LOYALTY_UNENROLL: 'MERCHANT_LOYALTY_UNENROLL',
  MENU_LIVE_MANAGE: 'MENU_LIVE_MANAGE',
  POS_INTEGRATION_MANAGE: 'POS_INTEGRATION_MANAGE',
  ORDER_METHODS_READ: 'ORDER_METHODS_READ',
  ORDER_METHODS_MANAGE_EMAIL: 'ORDER_METHODS_MANAGE_EMAIL',
  ORDER_METHODS_MANAGE_DEVICE: 'ORDER_METHODS_MANAGE_DEVICE',
  ORDER_METHODS_MANAGE_POS: 'ORDER_METHODS_MANAGE_POS',
  MERCHANT_SCHEDULED_ORDERS_CONFIG_MANAGE:
    'MERCHANT_SCHEDULED_ORDERS_CONFIG_MANAGE',
  MERCHANT_SCHEDULED_ORDERS_CONFIG_READ:
    'MERCHANT_SCHEDULED_ORDERS_CONFIG_READ',
  MERCHANT_REFUND_PIN_MANAGE: 'MERCHANT_REFUND_PIN_MANAGE',
  MERCHANT_REFUND_PIN_READ: 'MERCHANT_REFUND_PIN_READ',
  MERCHANT_ORDERS_MANAGE: 'MERCHANT_ORDERS_MANAGE',
  MERCHANT_ORDERS_READ: 'MERCHANT_ORDERS_READ',
  MERCHANT_SCHEDULED_CONFIG_MANAGE: 'MERCHANT_SCHEDULED_CONFIG_MANAGE',
  MERCHANT_SCHEDULED_CONFIG_READ: 'MERCHANT_SCHEDULED_CONFIG_READ',
};

export const Experiments = {
  MERCHANT_WEB_BUTTONS: 'MERCHANT_WEB_BUTTONS',
  MERCHANT_HOLIDAY_HOURS: 'MERCHANT_HOLIDAY_HOURS',
  SQUARE_INTEGRATION_ENABLED: 'SQUARE_INTEGRATION_ENABLED',
  SELF_SERVE_MENU_ENABLED: 'SELF_SERVE_MENU_ENABLED',
  MERCHANT_BILLING_ENABLED: 'MERCHANT_BILLING_ENABLED',
  MERCHANT_DELIVERY_CONFIG_ENABLED: 'MERCHANT_DELIVERY_CONFIG_ENABLED',
  ORDER_DEVICE_SELECTION_ENABLED: 'ORDER_DEVICE_SELECTION_ENABLED',
};

export const UserExperiments = {
  MULTI_STORE_OVERVIEW: 'MULTI_STORE_OVERVIEW',
};
