import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import generalErrorImage from 'common/assets/generalErrorImage.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 600px;
  width: 416px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  padding: 18px 18px;
`;

export const GeneralErrorIcon = styled.div`
  margin-top: 40px;
  background: url(${generalErrorImage});
  width: 257px;
  height: 314px;
`;

const GeneralErrorPage = ({ title, desc }) => {
  const { t } = useTranslation('c');
  return (
    <Container>
      <h2>{title || t('global:generalErrorTitle')}</h2>
      <div>{desc || t('global:generalErrorDesc')}</div>
      <GeneralErrorIcon />
    </Container>
  );
};

GeneralErrorPage.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
};

export default GeneralErrorPage;
