import { every, includes, some } from 'lodash';
import { createSelector } from 'reselect';
import { getCurrentMerchant } from 'core/MerchantFilter/selectors';
import { Permission, UserExperiments } from 'common/auth/constants';

export const getUserId = state => state.auth.user.user.userId;
export const getUser = state =>
  state.auth.user ? state.auth.user.user : undefined;
export const getIsShowingPrivateDataMask = state =>
  state.auth.isShowingPrivateDataMask;
export const isUserAuthenticated = state => state.auth.isAuthenticated || false;
export const getAuthHydrated = state => state.auth.authHydrated;
export const getAuthError = state => state.auth.error;

export const getUserPermissions = createSelector(
  getCurrentMerchant,
  getUser,
  (currentMerchant, user) => {
    if (!user) return [];
    if (!currentMerchant) {
      return user.userPermissions
        ? user.userPermissions.map(({ id }) => id)
        : [];
    }
    return (currentMerchant.permissions
      ? currentMerchant.permissions.map(({ id }) => id)
      : []
    ).concat(user.userPermissions.map(({ id }) => id));
  },
);

export const getUserProfile = createSelector(getUser, user => {
  if (!user) return null;
  return user.userProfile;
});

export const getCurrentUserEmail = createSelector(
  getUserProfile,
  userProfile => {
    if (!userProfile) return '';
    return userProfile.email;
  },
);

export const getCurrentUserFirstName = createSelector(
  getUserProfile,
  userProfile => {
    if (!userProfile) return '';
    return userProfile.firstName;
  },
);

export const getCurrentUserLastName = createSelector(
  getUserProfile,
  userProfile => {
    if (!userProfile) return '';
    return userProfile.lastName;
  },
);

export const getCurrentUserFullName = createSelector(
  getCurrentUserFirstName,
  getCurrentUserLastName,
  (firstName, lastName) => `${firstName} ${lastName}`,
);

export const getUserProfileUpdateStatus = state =>
  state.auth.updateUserProfileStatus;

export const getUserManagementExternalId = createSelector(
  getUser,
  user => user.userManagementExternalId || '',
);

export const isSuperUser = createSelector(getUserPermissions, permissions =>
  permissions.includes(Permission.INTERNAL_TEST),
);

export const canUserSkipOnboarding = createSelector(
  getUserPermissions,
  userPermission => userPermission.includes(Permission.SKIP_ONBOARDING),
);

export const hasAllPermissions = (state, permissions) => {
  const userPermissions = getUserPermissions(state);
  return every(permissions, permission =>
    includes(userPermissions, permission),
  );
};

export const hasAnyPermission = (state, permissions) => {
  const userPermissions = getUserPermissions(state);
  return some(permissions, permission => includes(userPermissions, permission));
};

export const getUserExperiments = createSelector(getUserProfile, userProfile =>
  userProfile ? userProfile.experiments : [],
);

export const hasMultiStoreExperiment = createSelector(
  getUserExperiments,
  experiments => {
    const multiStoreOverviewEnrollment = experiments.find(
      ({ name, value }) =>
        name === UserExperiments.MULTI_STORE_OVERVIEW && value === '1',
    );
    return !!multiStoreOverviewEnrollment;
  },
);

export const hasR1 = createSelector(getUser, user => {
  if (!user) return false;
  return user.hasR1;
});
