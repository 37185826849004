/**
 * Event actions related to login
 */
export const EVENT_ACTION_PORTAL_LOGIN_SUCCESS = 'PORTAL_LOGIN_SUCCESS';

/**
 * V3 Event actions related to Restaurant Share Program
 * https://docs.google.com/spreadsheets/d/1IIUWM6Cby3SBPWJLu-HqU3Lt8WBYdNqaXhVqW8tQ9PE/edit?usp=sharing
 */
// fire this event when a user visits the homescreen dashboard in PP
export const EVENT_V3_PP_DASHBOARD_IMPRESSION = 'PP_DASHBOARD_IMPRESSION';
// fire this event when a user visits the RSP dashboard for a merchant enrolled in RSP
export const EVENT_V3_PP_RSP_DASHBOARD_IMPRESSION =
  'PP_RSP_DASHBOARD_IMPRESSION';
// fire this event when a user visits the RSP screen for a merchant not enrolled in RSP
export const EVENT_V3_PP_RSP_WELCOME_IMPRESSION = 'PP_RSP_WELCOME_IMPRESSION';
// fire this event when a clicks "join now" on RSP welcome screen
export const EVENT_V3_PP_RSP_WELCOME_JOIN_NOW_CLICK =
  'PP_RSP_WELCOME_JOIN_NOW_CLICK';
// fire this event when a clicks "learn more" on RSP welcome screen
export const EVENT_V3_PP_RSP_WELCOME_LEARN_MORE_CLICK =
  'PP_RSP_WELCOME_LEARN_MORE_CLICK';
// fire this event when a user clicks "accept terms" in RSP enrollment popup
export const EVENT_V3_PP_RSP_ENROLL_POPUP_TERMS_CLICK =
  'PP_RSP_ENROLL_POPUP_TERMS_CLICK';
// fire this event when a user clicks "join now" in RSP enrollment popup
export const EVENT_V3_PP_RSP_ENROLL_POPUP_JOIN_CLICK =
  'PP_RSP_ENROLL_POPUP_JOIN_CLICK';
// fire this event when a user clicks "cancel" in RSP enrollment popup"
export const EVENT_V3_PP_RSP_ENROLL_POPUP_CANCEL_CLICK =
  'PP_RSP_ENROLL_POPUP_CANCEL_CLICK';
// fire this event when a user successfully enrolls a merchant in RSP. Note this is a server event."
export const EVENT_V3_PP_RSP_ENROLLMENT_SUCCESS_RESPONSE =
  'PP_RSP_ENROLLMENT_SUCCESS_RESPONSE';
// fire this event when a user sees the RSP enrollment pop up within partner portal
export const EVENT_V3_PP_RSP_POPUP_IMPRESSION = 'PP_RSP_POPUP_IMPRESSION';
// fire this event when a user checks "do not show again" on home dashboard rsp popup
export const EVENT_V3_PP_RSP_POPUP_DO_NOT_SHOW_CLICK =
  'PP_RSP_POPUP_DO_NOT_SHOW_CLICK';
// fire this event when a user clicks "enroll now" on home dashboard rsp popup
export const EVENT_V3_PP_RSP_POPUP_ENROLL_CLICK = 'PP_RSP_POPUP_ENROLL_CLICK';
// fire this event when a user clicks "no thanks" on home dashboard rsp popup
export const EVENT_V3_PP_RSP_POPUP_IGNORE_CLICK = 'PP_RSP_POPUP_IGNORE_CLICK';

/**
 * Clickstream event actions related to Restaurant Share Program
 */
export const EVENT_ACTION_PORTAL_RSP_ENROLL_PAGE_IMPRESSION =
  'PORTAL_RSP_ENROLL_PAGE_IMPRESSION';
export const EVENT_ACTION_PORTAL_RSP_ENROLL_ENROLLMENT_SUCCESS =
  'PORTAL_RSP_ENROLL_ENROLLMENT_SUCCESS';
export const EVENT_ACTION_PORTAL_RSP_ENROLL_MODAL_LAUNCH_CLICK =
  'PORTAL_RSP_ENROLL_MODAL_LAUNCH_CLICK';
export const EVENT_ACTION_PORTAL_RSP_ENROLL_MODAL_CLOSE_CLICK =
  'PORTAL_RSP_ENROLL_MODAL_CLOSE_CLICK';
export const EVENT_ACTION_PORTAL_RSP_ENROLL_MODAL_TERMS_CHECKBOX_CLICK =
  'PORTAL_RSP_ENROLL_MODAL_TERMS_CHECKBOX_CLICK';
export const EVENT_ACTION_PORTAL_RSP_REPORT_PAGE_IMPRESSION =
  'PORTAL_RSP_REPORT_PAGE_IMPRESSION';
export const EVENT_ACTION_PORTAL_RSP_POPUP_IMPRESSION =
  'PORTAL_RSP_POPUP_IMPRESSION';
export const EVENT_ACTION_PORTAL_RSP_POPUP_MUTE_CHECKBOX_CLICK =
  'PORTAL_RSP_POPUP_MUTE_CHECKBOX_CLICK';
export const EVENT_ACTION_PORTAL_RSP_POPUP_DISMISS_CLICK =
  'PORTAL_RSP_POPUP_DISMISS_CLICK';
export const EVENT_ACTION_PORTAL_RSP_POPUP_CTA_CLICK =
  'PORTAL_RSP_POPUP_CTA_CLICK';

/**
 * V3 Event actions related to Loyalty+
 * https://docs.google.com/spreadsheets/d/1vYLErDYsfhq-2EOsoyUyA25UnVTm9-2X_xrkLEhGFoA/edit#gid=1079684397&range=B366:G421
 */
export const EVENT_V3_PARTNERPORTAL_LOYALTYPLUS_CANCEL =
  'PARTNERPORTAL_LOYALTYPLUS_CANCEL';
export const EVENT_V3_PARTNERPORTAL_LOYALTYPLUS_ENROLL =
  'PARTNERPORTAL_LOYALTYPLUS_ENROLL';
export const EVENT_V3_PARTNERPORTAL_LOYALTYPLUS_ENROLL_CLICK =
  'PARTNERPORTAL_LOYALTYPLUS_ENROLL_CLICK';

/**
 * V3 Event actions related to side nav
 * https://docs.google.com/spreadsheets/d/1vYLErDYsfhq-2EOsoyUyA25UnVTm9-2X_xrkLEhGFoA/edit#gid=1079684397&range=B408:B414
 */
export const EVENT_V3_PARTNERPORTAL_SIDEBAR_CLICK =
  'PARTNERPORTAL_SIDEBAR_CLICK';

/**
 * Event actions related to side nav
 */
export const EVENT_ACTION_PORTAL_SIDE_NAV_CLICK = 'PORTAL_SIDE_NAV_CLICK';

/**
 * Event actions related to menu
 */
export const EVENT_ACTION_PORTAL_MENU_VIEW_LIVE_MENU_CLICK =
  'PORTAL_MENU_VIEW_LIVE_MENU_CLICK';

export const EVENT_ACTION_PORTAL_MENU_SHARED_MENU_CARD_IMPRESSION =
  'PORTAL_MENU_SHARED_MENU_CARD_IMPRESSION';
export const EVENT_ACTION_PORTAL_MENU_SHARED_MENU_CARD_CLICK =
  'PORTAL_MENU_SHARED_MENU_CARD_CLICK';

export const EVENT_ACTION_PORTAL_MENU_DELETE_MODAL_IMPRESSION =
  'PORTAL_MENU_DELETE_MODAL_IMPRESSION';
export const EVENT_ACTION_PORTAL_MENU_DELETE_MODAL_CANCEL_CLICK =
  'PORTAL_MENU_DELETE_MODAL_CANCEL_CLICK';

export const EVENT_ACTION_PORTAL_MENU_CONFIRM_MODAL_IMPRESSION =
  'PORTAL_MENU_CONFIRM_MODAL_IMPRESSION';
export const EVENT_ACTION_PORTAL_MENU_CONFIRM_MODAL_CANCEL_CLICK =
  'PORTAL_MENU_CONFIRM_MODAL_CANCEL_CLICK';

export const EVENT_ACTION_PORTAL_MENU_CATEGORY_MODAL_IMPRESSION =
  'PORTAL_MENU_CATEGORY_MODAL_IMPRESSION';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_MODAL_SPECIAL_HOURS_CHANGE =
  'PORTAL_MENU_CATEGORY_MODAL_SPECIAL_HOURS_CHANGE';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_MODAL_SAVE_CLICK =
  'PORTAL_MENU_CATEGORY_MODAL_SAVE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_MODAL_CANCEL_CLICK =
  'PORTAL_MENU_CATEGORY_MODAL_CANCEL_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_MODAL_DELETE_CLICK =
  'PORTAL_MENU_CATEGORY_MODAL_DELETE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_MODAL_DELETE_CONFIRM_CLICK =
  'PORTAL_MENU_CATEGORY_MODAL_DELETE_CONFIRM_CLICK';

export const EVENT_ACTION_PORTAL_MENU_MODIFIER_MODAL_IMPRESSION =
  'PORTAL_MENU_MODIFIER_MODAL_IMPRESSION';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_MODAL_SAVE_CLICK =
  'PORTAL_MENU_MODIFIER_MODAL_SAVE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_MODAL_CANCEL_CLICK =
  'PORTAL_MENU_MODIFIER_MODAL_CANCEL_CLICK';

export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_CLICK =
  'PORTAL_MENU_CATEGORY_TAB_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_SEARCH_BLUR =
  'PORTAL_MENU_CATEGORY_TAB_SEARCH_CHANGE';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_NEW_CLICK =
  'PORTAL_MENU_CATEGORY_TAB_NEW_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_CATEGORY_DRAG =
  'PORTAL_MENU_CATEGORY_TAB_CATEGORY_DRAG';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_EXPAND_CLICK =
  'PORTAL_MENU_CATEGORY_TAB_EXPAND_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_COLLAPSE_CLICK =
  'PORTAL_MENU_CATEGORY_TAB_COLLAPSE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_EDIT_CLICK =
  'PORTAL_MENU_CATEGORY_TAB_EDIT_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_DELETE_CLICK =
  'PORTAL_MENU_CATEGORY_TAB_DELETE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_DELETE_CONFIRM_CLICK =
  'PORTAL_MENU_CATEGORY_TAB_DELETE_CONFIRM_CLICK';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_ITEM_DRAG =
  'PORTAL_MENU_CATEGORY_TAB_ITEM_DRAG';
export const EVENT_ACTION_PORTAL_MENU_CATEGORY_TAB_EDIT_ITEM_CLICK =
  'PORTAL_MENU_CATEGORY_TAB_EDIT_ITEM_CLICK';

export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_CLICK =
  'PORTAL_MENU_ITEM_TAB_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_SEARCH_BLUR =
  'PORTAL_MENU_ITEM_TAB_SEARCH_CHANGE';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_NEW_CLICK =
  'PORTAL_MENU_ITEM_TAB_NEW_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_ITEM_CLICK =
  'PORTAL_MENU_ITEM_TAB_ITEM_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_IMAGE_CLICK =
  'PORTAL_MENU_ITEM_TAB_IMAGE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_EDIT_CLICK =
  'PORTAL_MENU_ITEM_TAB_EDIT_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_VISIBILITY_CLICK =
  'PORTAL_MENU_ITEM_TAB_VISIBILITY_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_HIDE_CONFIRM_CLICK =
  'PORTAL_MENU_ITEM_TAB_HIDE_CONFIRM_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_SHOW_CONFIRM_CLICK =
  'PORTAL_MENU_ITEM_TAB_SHOW_CONFIRM_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_COPY_CLICK =
  'PORTAL_MENU_ITEM_TAB_COPY_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_DELETE_CLICK =
  'PORTAL_MENU_ITEM_TAB_DELETE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_TAB_DELETE_CONFIRM_CLICK =
  'PORTAL_MENU_ITEM_TAB_DELETE_CONFIRM_CLICK';

export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_TAB_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_TAB_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_TAB_SEARCH_BLUR =
  'PORTAL_MENU_MODIFIER_GROUP_TAB_SEARCH_BLUR';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_TAB_NEW_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_TAB_NEW_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_TAB_MODIFIER_GROUP_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_TAB_MODIFIER_GROUP_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_TAB_EDIT_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_TAB_EDIT_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_TAB_DELETE_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_TAB_DELETE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_TAB_DELETE_CONFIRM_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_TAB_DELETE_CONFIRM_CLICK';

export const EVENT_ACTION_PORTAL_MENU_OVERVIEW_TAB_CLICK =
  'PORTAL_MENU_OVERVIEW_TAB_CLICK';
export const EVENT_ACTION_PORTAL_MENU_OVERVIEW_TAB_EXPAND_ALL_CLICK =
  'PORTAL_MENU_OVERVIEW_TAB_EXPAND_ALL_CLICK';
export const EVENT_ACTION_PORTAL_MENU_OVERVIEW_TAB_COLLAPSE_ALL_CLICK =
  'PORTAL_MENU_OVERVIEW_TAB_COLLAPSE_ALL_CLICK';

export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_BACK_CLICK =
  'PORTAL_MENU_ITEM_PAGE_BACK_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_SAVE_CLICK =
  'PORTAL_MENU_ITEM_PAGE_SAVE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_DELETE_CLICK =
  'PORTAL_MENU_ITEM_PAGE_DELETE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_DELETE_CONFIRM_CLICK =
  'PORTAL_MENU_ITEM_PAGE_DELETE_CONFIRM_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_POS_RESET_CONFIRM_CLICK =
  'PORTAL_MENU_ITEM_PAGE_POS_RESET_CONFIRM_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_POS_SAVE_CONFIRM_CLICK =
  'PORTAL_MENU_ITEM_PAGE_POS_SAVE_CONFIRM_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_NEW_CATEGORY_CLICK =
  'PORTAL_MENU_ITEM_PAGE_NEW_CATEGORY_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_NEW_MODIFIER_GROUP_CLICK =
  'PORTAL_MENU_ITEM_PAGE_NEW_MODIFIER_GROUP_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_MODIFIER_GROUP_DRAG =
  'PORTAL_MENU_ITEM_PAGE_MODIFIER_GROUP_DRAG';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_MODIFIER_GROUP_EDIT_CLICK =
  'PORTAL_MENU_ITEM_PAGE_MODIFIER_GROUP_EDIT_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_MODIFIER_GROUP_REMOVE_CLICK =
  'PORTAL_MENU_ITEM_PAGE_MODIFIER_GROUP_REMOVE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_ITEM_PAGE_SPECIAL_HOURS_CHANGE =
  'PORTAL_MENU_ITEM_PAGE_SPECIAL_HOURS_CHANGE';

export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_PAGE_BACK_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_PAGE_BACK_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_PAGE_SAVE_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_PAGE_SAVE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_PAGE_DELETE_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_PAGE_DELETE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_PAGE_DELETE_CONFIRM_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_PAGE_DELETE_CONFIRM_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_PAGE_MODIFIER_DRAG =
  'PORTAL_MENU_MODIFIER_GROUP_PAGE_MODIFIER_DRAG';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_PAGE_MODIFIER_EDIT_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_PAGE_MODIFIER_EDIT_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_PAGE_MODIFIER_DELETE_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_PAGE_MODIFIER_DELETE_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_PAGE_MODIFIER_DELETE_CONFIRM_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_PAGE_MODIFIER_DELETE_CONFIRM_CLICK';
export const EVENT_ACTION_PORTAL_MENU_MODIFIER_GROUP_PAGE_NEW_MODIFIER_CLICK =
  'PORTAL_MENU_MODIFIER_GROUP_PAGE_NEW_MODIFIER_CLICK';
export const EVENT_ACTION_PORTAL_DISCOUNTS_CREATE_CLICK =
  'PORTAL_DISCOUNTS_CREATE_CLICK';

/**
 * Event actions related to onboarding checklist
 */
export const EVENT_ACTION_PORTAL_ONBOARDING_CHECKLIST_ITEM_CLICK =
  'PORTAL_ONBOARDING_CHECKLIST_ITEM_CLICK';
export const EVENT_ACTION_PORTAL_ONBOARDING_CHECKLIST_ITEM_CTA_CLICK =
  'PORTAL_ONBOARDING_CHECKLIST_ITEM_CTA_CLICK';
export const EVENT_ACTION_PORTAL_ONBOARDING_CHECKLIST_ITEM_CONTACT_CLICK =
  'PORTAL_ONBOARDING_CHECKLIST_ITEM_CONTACT_CLICK';
export const EVENT_ACTION_PORTAL_ONBOARDING_CHECKLIST_BANNER_CLICK =
  'PORTAL_ONBOARDING_CHECKLIST_BANNER_CLICK';

/**
 * Event actions related to sales report page
 */
export const EVENT_ACTION_PORTAL_SALES_TRANSACTION_ITEM_CLICK =
  'PORTAL_SALES_TRANSACTION_ITEM_CLICK';

/**
 * Event actions related to customer feedback page
 */
export const EVENT_ACTION_PORTAL_FEEDBACK_READ_COMMENTS_CLICK =
  'PORTAL_FEEDBACK_READ_COMMENTS_CLICK';
export const EVENT_ACTION_PORTAL_FEEDBACK_COMMENTS_FILTER_IMAGE_CLICK =
  'PORTAL_FEEDBACK_COMMENTS_FILTER_IMAGE_CLICK';
