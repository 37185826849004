import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';
import qs from 'qs';

import { LAUNCHPAD_HOST_NAME, PROD_PP_URL } from 'common/constants';
import sendAnalytic, { getLoadPageAnalyticLabel } from 'common/analytics';
import { getExternalId, setExternalId } from 'common/utils/session';

import usePageEventOnRouteChange from 'hooks/usePageEventOnRouteChange';
import LoadingScreen from 'common/components/Loading';

import {
  isUserAuthenticated,
  isSuperUser as isSuperUserSelector,
} from './common/auth/selectors';
import { logout as logoutAction } from './common/auth/actions';
import {
  EMPTY_PATH,
  LOGIN_PATH,
  SET_PASSWORD_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  RESEND_EMAIL,
  OVERVIEW_PATH,
  REPORT_DOWNLOAD_PATH,
  SIGNUP_PATH,
} from './core/Portal/paths';

import Bootstrap from './common/auth/components/Bootstrap';
import LayoutForModal from './core/Layout/LayoutForModal';

const Login = lazy(() => import('./core/Login'));
const Portal = lazy(() => import('./core/Portal'));
const CreateMerchantPage = lazy(() => import('./core/CreateMerchantPage'));
const ResendEmail = lazy(() => import('./core/Password/ResendEmail'));
const ViewAgreement = lazy(() => import('./core/ViewAgreement'));
const Signup = lazy(() => import('./core/Signup'));
const ForgotPassword = lazy(() => import('./core/Password/ForgotPassword'));
const ResetPassword = lazy(() => import('./core/Password/ResetPassword'));
const ReportDownload = lazy(() => import('./core/Report/Download'));
const MultistoreRoot = lazy(() => import('./core/Multistore'));
const NewSetPassword = lazy(() => import('./core/Password/SetPasswordV2'));
const SetPassword = lazy(() => import('./core/Password/SetPasswordModal'));

export const UNAUTHENTICATED_PAGES = [
  LOGIN_PATH,
  SET_PASSWORD_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
];

const Routes = ({ history, isAuthenticated, isSuperUser, logout }) => {
  usePageEventOnRouteChange(history);

  return (
    <Bootstrap>
      <ConnectedRouter history={history}>
        <Suspense fallback={<LoadingScreen size={64} />}>
          <Switch>
            <Route path={RESET_PASSWORD_PATH} component={ResetPassword} />
            <Route path={RESEND_EMAIL} component={ResendEmail} />
            <Route path={FORGOT_PASSWORD_PATH} component={ForgotPassword} />
            <Route path={SIGNUP_PATH} component={Signup} />
            <Route
              exact
              path={SET_PASSWORD_PATH}
              render={props => (
                <LayoutForModal>
                  <SetPassword {...props} />
                </LayoutForModal>
              )}
            />
            <Route
              exact
              path={`${SET_PASSWORD_PATH}/v2`}
              component={NewSetPassword}
            />
            <Route
              render={({ location }) => {
                const label = getLoadPageAnalyticLabel({
                  merchantUrl: null,
                  pathname: location.pathname,
                  isMerchant: false,
                });
                if (label) {
                  sendAnalytic('pp', 'Portal', 'RIT_page_load', label);
                }
                if (isAuthenticated) {
                  // Redirect non super user back to prod url
                  if (
                    window.location.host === LAUNCHPAD_HOST_NAME &&
                    !isSuperUser
                  ) {
                    logout();
                    window.location.href = `${PROD_PP_URL}${window.location.pathname}`;
                    return null;
                  }
                  if (
                    location.pathname !== '/' &&
                    UNAUTHENTICATED_PAGES.includes(location.pathname)
                  ) {
                    return <Redirect to={`/?${location.search}`} />;
                  }

                  return (
                    <Switch>
                      {isSuperUser && (
                        <Route
                          path="/create-merchant/:workOrderId"
                          component={CreateMerchantPage}
                        />
                      )}
                      <Route path="/signedTerms" component={ViewAgreement} />
                      <Route path={OVERVIEW_PATH} component={MultistoreRoot} />
                      <Route
                        exact
                        path="/:merchantName/:merchantUrl/"
                        component={Portal}
                      />
                      <Route
                        path="/:merchantName/:merchantUrl/:path"
                        component={Portal}
                      />
                      <Route
                        path={REPORT_DOWNLOAD_PATH}
                        component={ReportDownload}
                      />
                      <Route component={Portal} />
                    </Switch>
                  );
                }

                if (getExternalId() === '') {
                  setExternalId(uuidv4().replace(/-/g, ''));
                }
                return (
                  <Switch>
                    <Redirect exact from={EMPTY_PATH} to="/login" />
                    <Route path={LOGIN_PATH} component={Login} />
                    <Route
                      render={routeProps => (
                        <Redirect
                          to={`${LOGIN_PATH}?${qs.stringify({
                            redirect: `${routeProps.location.pathname}${routeProps.location.search}`,
                          })}`}
                        />
                      )}
                    />
                  </Switch>
                );
              }}
            />
          </Switch>
        </Suspense>
      </ConnectedRouter>
    </Bootstrap>
  );
};

Routes.propTypes = {
  history: PropTypes.shape({}).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isSuperUser: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    isAuthenticated: isUserAuthenticated(state),
    isSuperUser: isSuperUserSelector(state),
  }),
  {
    logout: logoutAction,
  },
)(Routes);
