import React from 'react';
import PropTypes from 'prop-types';

const Flag = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.68833 4.04383L9.42166 2.71049H3.42166V14.0438H4.75499V9.37716H8.48833L8.75499
      10.7105H13.4217V4.04383H9.68833Z"
      fill={color}
    />
  </svg>
);

Flag.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

Flag.defaultProps = {
  width: 17,
  height: 17,
  color: 'black',
};

export default Flag;
