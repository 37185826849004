import createReducer from 'common/utils/createReducer';
import { ACTION_TYPES } from './actions';

export const INITIAL_STATE = {
  loading: false,
  data: null,
  error: null,
};

const loadingOrderMethods = state => ({
  ...state,
  loading: true,
});

const loadOrderMethodsSuccess = (state, payload) => ({
  ...state,
  data: payload.data,
  loading: false,
  error: null,
});

const loadOrderMethodsError = (state, payload) => ({
  ...state,
  loading: false,
  error: payload.error,
});

const reducers = {
  [ACTION_TYPES.LOAD]: loadingOrderMethods,
  [ACTION_TYPES.LOAD_SUCCESS]: loadOrderMethodsSuccess,
  [ACTION_TYPES.LOAD_ERROR]: loadOrderMethodsError,
};

export default createReducer(INITIAL_STATE, reducers);
