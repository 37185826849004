import qs from 'qs';
import { push } from 'connected-react-router';
// eslint-disable-next-line import/no-cycle
import {
  addToMerchantList,
  setMerchant,
  updateMerchantListStorage,
} from 'core/MerchantFilter/actions';
import sendAnalytic from 'common/analytics';
import { EVENT_ACTION_PORTAL_LOGIN_SUCCESS } from 'common/analytics/eventTypes';
import { track } from 'common/analytics/analytics';
import { getExternalId, setExternalId } from 'common/utils/session';
// eslint-disable-next-line import/no-cycle
import { updateUserProfile as updateUserProfileAPI } from 'common/api/users';
// eslint-disable-next-line import/no-cycle
import api from '../api';
// eslint-disable-next-line import/no-cycle
import apiClient from '../api/apiClient';
import statusCodes from '../api/statusCodes';
import {
  setToken,
  getToken,
  getItem,
  setItem,
  removeToken,
  removeItem,
} from '../storage';
import { getUserManagementExternalId } from './selectors';
// eslint-disable-next-line import/no-cycle
import { updateUserLocale } from '../../actions';
import { addNewResources } from '../../i18n';

const loginRequest = () => ({
  type: 'auth/LOGIN_REQUEST',
});

const loginSuccess = ({ user, permissions }) => ({
  type: 'auth/LOGIN_SUCCESS',
  payload: { user, permissions },
});

const loginFailure = error => ({
  type: 'auth/LOGIN_FAILURE',
  payload: error,
  error: true,
});

const authHydrated = () => ({
  type: 'auth/AUTH_HYDRATED',
});

const enhanceMerchantList = async merchantsList => {
  if (merchantsList && merchantsList.length > 0) {
    const { data } = await apiClient({
      method: 'get',
      url: '/merchants/enhancement',
    });
    return merchantsList.map(merchant => {
      const enhancement = data[merchant.merchantId];
      if (!enhancement) {
        return merchant;
      }
      const { loyaltyDetails, r1Details } = enhancement;
      const { merchantEligibleForProgram: isLoyaltyEligible } =
        loyaltyDetails || {};
      const { hasR1 } = r1Details || {};
      return {
        ...merchant,
        isLoyaltyEligible,
        hasR1,
      };
    });
  }
  return merchantsList;
};

const updateMerchant = async ({ dispatch, user }) => {
  const {
    user: { externalId },
    merchants: { merchantsList: rawMerchantsList },
  } = user;
  const merchantsList = await enhanceMerchantList(rawMerchantsList);

  if (getExternalId() !== externalId) {
    setExternalId(externalId);
  }
  const storedMerchantId = getItem('merchantId');
  let currentMerchant = merchantsList.find(
    ({ merchantId }) => storedMerchantId === merchantId,
  );
  if (merchantsList.length > 0) {
    dispatch(addToMerchantList(merchantsList));
    if (!getItem('merchantList')) {
      dispatch(
        updateMerchantListStorage(
          merchantsList.map(({ merchantId }) => merchantId),
        ),
      );
    }
    if (!currentMerchant) {
      [currentMerchant] = merchantsList;
    }
    setItem('merchantId', currentMerchant.merchantId);
    dispatch(setMerchant(currentMerchant.merchantId));
  }
};

const updateLocale = ({ dispatch, user }) => {
  if (
    user &&
    user.user &&
    user.user.userProfile &&
    user.user.userProfile.preferredLocale
  ) {
    const {
      user: {
        userProfile: { preferredLocale: locale },
      },
    } = user;
    if (locale) {
      dispatch(updateUserLocale(user.user.userProfile.preferredLocale));
    }
  }
};

export const login = (creds, redirect) => async dispatch => {
  dispatch(loginRequest());
  try {
    const loginResponse = await api.post('/auth/v1/login', creds);
    const { token, user, permissions } = loginResponse.data;
    const externalId = getExternalId();
    if (externalId) {
      sendAnalytic(
        'pp_login',
        '/login',
        'RIT_login_successful',
        `${externalId}|${user.user.externalId}`,
      );
    }
    track(EVENT_ACTION_PORTAL_LOGIN_SUCCESS);
    setToken(token);
    /** TODO: MFM-1138 remove await once Loyalty+ is fully released */

    await updateMerchant({ dispatch, user });
    updateLocale({ dispatch, user });
    dispatch(loginSuccess({ user, permissions }));
    return dispatch(push(redirect || '/'));
  } catch (err) {
    return dispatch(loginFailure(err));
  }
};

export const logout = params => {
  removeToken();
  removeItem('merchantId');
  removeItem('merchantList');
  removeItem('tabs');
  setExternalId('');
  window.location.href = `/login${params ? `?${qs.stringify(params)}` : ''}`;
};

export const loadContentful = async (resources, ns) => {
  try {
    const query = resources ? `?resources=${resources}` : '';
    const { data } = await api.get(`/resources/v1/contentful${query}`);
    const { resourceSet } = data;
    addNewResources(resourceSet, ns);
    return { resourceSet };
  } catch (err) {
    // Intentionally empty
  }
};

export const hydrateAuth = () => async dispatch => {
  const token = getToken();
  if (token) {
    try {
      const { data } = await api.get('/users/v1/current');
      const { user, resourceSet } = data;
      addNewResources(resourceSet);
      /** TODO: MFM-1138 remove await once Loyalty+ is fully released */
      await updateMerchant({ dispatch, user });
      updateLocale({ dispatch, user });
      dispatch(loginSuccess(data));
      dispatch(authHydrated());
      return { resourceSet };
    } catch (err) {
      if (err.response && err.response.status !== statusCodes.UNAUTHORIZED) {
        return dispatch(loginFailure(err));
      }
    }
  } else {
    const { resourceSet } = await loadContentful();
    dispatch(authHydrated());
    return { resourceSet };
  }
};

const updateUserProfileSuccess = userProfile => ({
  type: 'auth/UPDATE_USER_PROFILE_SUCCESS',
  payload: {
    userProfile,
  },
});

const fetchingUserProfileAction = () => ({
  type: 'auth/UPDATE_USER_PROFILE_SUBMITTING',
});

const updateUserProfileFailure = error => ({
  type: 'auth/UPDATE_USER_PROFILE_FAILURE',
  payload: {
    error,
  },
});

export const updateUserProfile = ({
  firstName,
  lastName,
  locale,
  position,
  phoneNumber,
  phoneCountryCode,
  phoneCallingCode,
}) => async (dispatch, getState) => {
  const userManagementExternalId = getUserManagementExternalId(getState());
  dispatch(fetchingUserProfileAction());
  try {
    await updateUserProfileAPI({
      externalId: userManagementExternalId,
      firstName,
      lastName,
      locale,
      position,
      phoneNumber,
      phoneCountryCode,
      phoneCallingCode,
    });
    dispatch(updateUserLocale(locale));
    dispatch(
      updateUserProfileSuccess({
        firstName,
        lastName,
        locale,
        position,
        phoneNumber,
        phoneCountryCode,
        phoneCallingCode,
      }),
    );
  } catch (error) {
    dispatch(updateUserProfileFailure(error));
  }
};

export const showPrivateDataMask = () => dispatch =>
  dispatch({
    type: 'auth/SHOW_PRIVATE_DATA_MASK',
  });

export const hidePrivateDataMask = () => dispatch =>
  dispatch({
    type: 'auth/HIDE_PRIVATE_DATA_MASK',
  });

export const requestPrivateDataAccess = merchantId => () => {
  const endpoint = `merchants/${merchantId}/sensitive-data-access`;
  return apiClient({
    method: 'post',
    url: endpoint,
    data: {},
  });
};
