// eslint-disable-next-line import/no-cycle
import apiClient from '../apiClient';

export const updateUserProfile = async ({ externalId, ...rest }) => {
  const endpoint = `users/${externalId}/profile`;
  const data = {
    ...rest,
  };
  try {
    const response = await apiClient({
      method: 'patch',
      url: endpoint,
      data,
    });
    return response;
  } catch (e) {
    throw e;
  }
};
