import createReducer from 'common/utils/createReducer';

export const INITIAL_STATE = {
  isOpen: false,
};

const openSideNav = () => ({
  isOpen: true,
});

const closeSideNav = () => ({
  isOpen: false,
});

const reducers = {
  'navigation/OPEN_SIDE_NAV': openSideNav,
  'navigation/CLOSE_SIDE_NAV': closeSideNav,
};

export default createReducer(INITIAL_STATE, reducers);
