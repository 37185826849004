import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import danger from 'common/assets/danger.svg';

import Card from 'common/components/Card';
import GenericTooltip from 'common/components/InfoTooltip/GenericTooltip';
import FeedbackAnalytic, {
  feedbackAnalyticDefaultProps,
  feedbackAnalyticPropTypes,
} from 'common/components/FeedbackAnalytic';
import { mobile } from 'common/style/media';

import Env from '../Env';

const WidgetWrapper = styled.div`
  padding-bottom: 1.25rem;
  height: 100%;
  ${props => props.minHeight && `min-height: ${props.minHeight}px;`}
`;

export const WidgetHeader = styled.h3`
  display: flex;
  font-size: ${props => props.theme.text.fontSize.displaySmall};
  font-weight: 400;
  justify-content: space-between;
  padding: 0.9rem 1.25rem 0.25rem 1.25rem;
  margin-bottom: 0;
`;

const Header = styled.div`
  ${props => props.theme.typeScale.h4}
  ${props => props.theme.typeScale.bold}

  ${mobile`
    ${props =>
      props.settingsCard &&
      `
      ${props.theme.typeScale.body} 
      ${props.theme.typeScale.medium}
      `}
  `}
`;

export const WidgetCard = styled(Card)`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DangerCard = styled(WidgetCard)`
  display: flex;
  flex-direction: row;
  border: 1px solid ${props => props.theme.colours.red};
  background: ${props => props.theme.colours.warningBackground};
`;

const Danger = styled.div`
  background: url('${danger}');
  height: 19px;
  min-width: 22px;
  margin-top: 18px;
  margin-left: 19px;
`;

const AddonWrapper = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const Widget = ({
  children,
  header,
  tooltip,
  tooltipId,
  tooltipIcon,
  minHeight,
  analytic,
  showFeedback,
  feedbackAnalytic,
  settingsCard,
  ...rest
}) => (
  <WidgetWrapper minHeight={minHeight} {...rest}>
    <WidgetCard>
      <WidgetHeader>
        <Header settingsCard={settingsCard}>{header}</Header>
        <AddonWrapper>
          {tooltip && tooltipId && tooltipIcon && (
            <GenericTooltip
              toolTipSection={tooltipIcon}
              tooltipId={tooltipId}
              analytic={analytic}
            >
              <div>{tooltip}</div>
            </GenericTooltip>
          )}
          {showFeedback && (
            <Env env={['dev']}>
              <FeedbackAnalytic feedbackAnalytic={feedbackAnalytic} />
            </Env>
          )}
        </AddonWrapper>
      </WidgetHeader>
      {children}
    </WidgetCard>
  </WidgetWrapper>
);

Widget.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  tooltip: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
  tooltipId: PropTypes.string,
  tooltipIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
  minHeight: PropTypes.number,
  analytic: PropTypes.shape({
    screen: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  }),
  showFeedback: PropTypes.bool,
  feedbackAnalytic: feedbackAnalyticPropTypes,
  settingsCard: PropTypes.bool,
};

Widget.defaultProps = {
  tooltip: null,
  tooltipId: null,
  tooltipIcon: null,
  minHeight: null,
  analytic: {
    screen: '',
    category: '',
    action: '',
  },
  showFeedback: false,
  feedbackAnalytic: feedbackAnalyticDefaultProps,
  settingsCard: false,
};

export const AlertWidget = ({ children, header, minHeight }) => (
  <WidgetWrapper minHeight={minHeight}>
    <DangerCard>
      <Danger />
      <div>
        <WidgetHeader>
          <strong>{header}</strong>
        </WidgetHeader>
        {children}
      </div>
    </DangerCard>
  </WidgetWrapper>
);

AlertWidget.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  header: PropTypes.string.isRequired,
  minHeight: PropTypes.number,
};

AlertWidget.defaultProps = {
  minHeight: null,
};
