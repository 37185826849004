import { publishBrandChanges, resetBrandToDefault } from 'common/api/gateway';
import { loadUserBrandGroupList } from '../brandGroupList/actions';

export const EDIT_THEME = 'brandGroup/EDIT_THEME';
export const REVERT_THEME_CHANGES = 'brandGroup/REVERT_BRAND_GROUP_CHANGES';

export const PUBLISH_REQUEST = 'brandGroup/PUBLISH_REQUEST';
export const PUBLISH_SUCCESS = 'brandGroup/PUBLISH_SUCCESS';
export const PUBLISH_FAILURE = 'brandGroup/PUBLISH_FAILURE';

export const RESET_TO_DEFAULT_REQUEST = 'brandGroup/RESET_TO_DEFAULT_REQUEST';
export const RESET_TO_DEFAULT_SUCCESS = 'brandGroup/RESET_TO_DEFAULT_SUCCESS';
export const RESET_TO_DEFAULT_FAILURE = 'brandGroup/RESET_TO_DEFAULT_FAILURE';

export const NAVIGATE_AWAY = 'brandGroup/NAVIGATE_AWAY';

const editTheme = changes => ({
  type: EDIT_THEME,
  payload: changes,
});

export const revertChanges = () => ({
  type: REVERT_THEME_CHANGES,
});

const resetRequest = () => ({
  type: RESET_TO_DEFAULT_REQUEST,
});

const resetSuccess = () => ({
  type: RESET_TO_DEFAULT_SUCCESS,
});

const resetFailure = err => ({
  type: RESET_TO_DEFAULT_FAILURE,
  payload: err,
});

const publishRequest = () => ({
  type: PUBLISH_REQUEST,
});

const publishSuccess = () => ({
  type: PUBLISH_SUCCESS,
});

const publishFailure = err => ({
  type: PUBLISH_FAILURE,
  payload: err,
});

export const navigateAway = () => ({
  type: NAVIGATE_AWAY,
});

export const editBrandGroupTheme = ({ changes }) => async dispatch => {
  dispatch(editTheme(changes));
};

export const publishChanges = ({
  brandId,
  theme,
  handleError,
}) => async dispatch => {
  dispatch(publishRequest());
  try {
    await publishBrandChanges({
      brandId,
      theme: {
        ...theme,
      },
    });
    dispatch(publishSuccess());
    return dispatch(loadUserBrandGroupList());
  } catch (error) {
    if (handleError) handleError(error);
    return dispatch(publishFailure(error));
  }
};

export const resetThemeToDefault = ({
  brandId,
  handleError,
}) => async dispatch => {
  dispatch(resetRequest());
  try {
    await resetBrandToDefault({ brandId });
    dispatch(resetSuccess());
    return dispatch(loadUserBrandGroupList());
  } catch (error) {
    if (handleError) handleError(error);
    return dispatch(resetFailure(error));
  }
};
